import React from "react";
import moment from 'moment';
import { Select, TimePicker } from "antd";
const { Option } = Select;

const { RangePicker } = TimePicker;
export const Ranger = () => {
  return(
    <RangePicker name="sunHours" format='hh:mm A' />
  );
}
