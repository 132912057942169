import React, { Component } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Link } from "react-router-dom";
import authentication from "../../services/authentication";
import axios from 'axios';
import { Fab, Box, Typography, Paper, List, ListItem, ListItemAvatar, Avatar, ListItemText, Grid, ListItemSecondaryAction, } from "@material-ui/core";

import { Home as HomeIcon } from "@material-ui/icons";
import { withRouter } from 'react-router-dom';
// import { ReactComponent as NotFoundIllustration } from "../../illustrations/not-found.svg";
import{ Button, ButtonIcon, Input,  } from 'react-rainbow-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV, faPlus, faTrashAlt, faTrash } from '@fortawesome/free-solid-svg-icons';
import firebase, { functions } from "../../firebase";


import {
  Favorite as FavoriteIcon,
  Delete as DeleteIcon
} from "@material-ui/icons";

const inputStyles = {
    width: 60,
    marginRight: 2,
};

const initialState = {
  "ilike":"bigbutts"
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '70%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  faveslist: {
    width: '70%',
    maxWidth: 560,
    backgroundColor: theme.palette.background.paper,
  },
}));

const lFaves = { maxWidth: 555, marginLeft: 30, marginTop: 15, paddingLeft: 15, paddingRight: 15, paddingBottom: 15 };
const btnStyle = { maxHeight: 35, height: 35, width: 170, marginBottom: 15, marginTop: 15 };

class Teststuff extends Component {

    constructor(props) {
      super(props);
      this.state = {
      };
    };

/*
    return axios.get('https://your-api-url/articles', {headers:
      { authorization: `Bearer ${token}` }})
      .then(res => res.data);
    }
*/
    handleBtnClick = async (id) => {
      //alert("this is the id: " +id);
      let tiki = await authentication
      .getUserTokenId()
      .then((token)=>{
        return token;
      })
      .catch((e)=>{
        alert(e);
      })

      alert("this should be the user token: " + tiki.token );


      let foo = await axios.get('https://nomtxt.com/api/v1/prive/articles', {headers:
        { authorization: `Bearer ${tiki.token}` }})
        .then((res) => {
          alert(res.data);
        });


/*
        let endpoint = "https://nomtxt.com/api/v1/prive/upstairs";
        let payload = {mynameis: "luka"};
        let options = {headers:{ authorization: `Bearer ${tiki.token}` }};

        let bar = axios.post(endpoint, payload, options)
        .then(function (value) {
          console.log("QAPLA' : " + JSON.stringify(value));
          //res.sendStatus(value);
          alert("Qapla': " + JSON.stringify(value))
        })
        .catch(function (value) {
          //console.log("ERROR in GET RESTO ITEMS: " + value);
          alert("VONLU: " + value)
          //deferred.reject(error);
        });
*/


    }





  render() {
    const anId = 8;
    return (
      <React.Fragment>

        <Paper style={lFaves}>
        <Typography variant="h5">tests</Typography>
          <p>[inshallah] it's all coming together</p>
          <p>You'll get a text back from NomTxt confirming what we have stored as your favorite. If you don't cancel
          your order will be sent to your favorite restaurant and they'll get working on it right away.
          <br/></p>
          <p>Right now, your restaurant is configured for Pickup Only. As soon as we can offer delivery service integration, you'll be the first to know.
          </p>

          <Button variant="brand" style={btnStyle} onClick={() => { this.handleBtnClick(`${anId}`) }}>clicker  .<FontAwesomeIcon icon={faPlus} className="rainbow-m-left_medium" />
          </Button>

        </Paper>

      </React.Fragment>
    );
  }

  componentDidMount(){
    //this.tryThis();  /{"data":{"fn":1.3,"sn":1.75,"sulu":3.05}}
    //this.tryThat();
  }


}

export default withRouter(Teststuff);
