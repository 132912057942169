import React, { Component } from "react";

import PropTypes from "prop-types";

import { Link } from "react-router-dom";

import {
  AppBar,
  Toolbar,
  Typography,
  Box,
  ButtonGroup,
  Button,
  IconButton,
  Divider,
  Menu,
  MenuItem,
} from "@material-ui/core";
import { Avatar, ButtonGroup as RainbowButtonGroup, MenuDivider, ButtonIcon, ButtonMenu, MenuItem as RainbowMenuItem } from 'react-rainbow-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faInfoCircle, faHeart, faHandHoldingHeart, faCommentDollar, faDoorOpen, faSignOutAlt, faEdit, faEllipsisV, faCog, faCogs, faCheck, faUtensils, faSms, faCloudMeatball } from '@fortawesome/free-solid-svg-icons';

import UserAvatar from "../UserAvatar";

class Bar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      menu: {
        anchorEl: null,
      },
    };
  }

  openMenu = (event) => {
    const anchorEl = event.currentTarget;

    this.setState({
      menu: {
        anchorEl,
      },
    });
  };

  closeMenu = () => {
    this.setState({
      menu: {
        anchorEl: null,
      },
    });
  };

  pushUserDataToState = (userData) => {
    this.setState({userData: userData})
  }

  getLink = (path) => {
    if(!this.props.user.uid){
      return "/";
    } else{
      return path;
    }
  }

  getRestoAdminLink = (path) => {
    if(!this.props.user.uid && this.props.userData.userType === 'resto-admin'){
      return "/";
    } else{
      return path;
    }
  }


  render() {
    // Properties
    const { performingAction, user, userData, roles } = this.props;

    const userType = userData ? userData.userType : 'none'
    // Events
    const {
      onAboutClick,
      onSettingsClick,
      onSignOutClick,
      onSignUpClick,
      onSignInClick,
    } = this.props;

    const { menu } = this.state;




    return (
      <AppBar color="primary" position="static">
        <Toolbar>
          <Box display="flex" flexGrow={1}>
            <Link to="/">
              <img src="/images/logo-trans-blurblet-long-notag.svg" alt="NomTxt logo" height="55" />
            </Link>
          </Box>

{/*/////////////////////Resto Admin User Menu/////////////////////////*/}

          {user && (
            <>
            {(userData.userType === 'resto-admin' || userData.userType === 'su') && (
<>
                <Box display="none">
                  <Button
                    color="inherit"
                    component={Link}
                    to={"/orders/"+userData.restoAdmin}
                    variant="outlined"
                  >
                    Resto Admin
                  </Button>
                </Box>
                <Box display="flex">
                <ButtonMenu
                    id="button-menu"
                    menuAlignment="right"
                    menuSize="small"
                    buttonVariant="brand"
                    buttonSize="x-small"
                    label="Admin"
                    icon={<FontAwesomeIcon icon={faPlus} />}
                >
                    <RainbowMenuItem label="SETUP"
                      variant="header" disabled />
                      <Link to={this.getRestoAdminLink(`/resto/profile`)}>
                        <RainbowMenuItem label="Resto Profile"
                          icon={<FontAwesomeIcon icon={faUtensils} />}
                          iconPosition="left"
                        />
                      </Link>


                      <Link to={this.getRestoAdminLink(`/resto/setup/payments/square/2`)}>
                      <RainbowMenuItem label="Payment Provider"
                        icon={<FontAwesomeIcon icon={faCommentDollar} />}
                        iconPosition="left"
                      />
                      </Link>

                      <Link to={this.getRestoAdminLink(`/resto/setup/messaging`)}>
                      <RainbowMenuItem label="Incoming Msgs"
                        icon={<FontAwesomeIcon icon={faSms} />}
                        iconPosition="left"
                      />
                      </Link>

                      <Link to={this.getRestoAdminLink(`/resto/enable`)}>
                      <RainbowMenuItem label="Enable NomTxt"
                        icon={<FontAwesomeIcon icon={faCheck} />}
                        iconPosition="left"
                      />
                      </Link>

                      <Link to={this.getRestoAdminLink(`/resto/billing`)}>
                      <RainbowMenuItem label="Billing Preferences"
                        icon={<FontAwesomeIcon icon={faCloudMeatball} />}
                        iconPosition="left"
                      />
                      </Link>

                      <Link to={{pathname: this.getRestoAdminLink(`/resto/status`), userData: this.props.userData }}>
                      <RainbowMenuItem label="Setup Status"
                        icon={<FontAwesomeIcon icon={faCogs} />}
                        iconPosition="left"
                      />
                      </Link>

                      <MenuDivider />

                      <Link to={{pathname: this.getRestoAdminLink(`/settings`), userData: this.props.userData }}>
                      <RainbowMenuItem label="User Settings"
                        icon={<FontAwesomeIcon icon={faCog} />}
                        iconPosition="left"
                      />
                      </Link>

                      <RainbowMenuItem label="Sign Out"
                        icon={<FontAwesomeIcon icon={faSignOutAlt} />}
                        iconPosition="left"
                        onClick={onSignOutClick}
                      />

                      {!user && (
                      <ButtonGroup>
                        <RainbowMenuItem label="Sign In"
                          icon={<FontAwesomeIcon icon={faDoorOpen} />}
                          iconPosition="right"
                          disabled={performingAction}
                          onClick={onSignUpClick}
                        />
                        <RainbowMenuItem label="Sign Up"
                          icon={<FontAwesomeIcon icon={faPlus} />}
                          iconPosition="right"
                          disabled={performingAction}
                          onClick={onSignInClick}
                        />
                      </ButtonGroup>
                    )}

                </ButtonMenu>

              </Box>
</>


            )}
             </>
          )}


{/*/////////////////////Customer User Menu/////////////////////////*/}
          {user && (
            <>
            {(userData.userType === 'regular' || userData.userType === 'su' ) && (
<>
                <Box display="flex">
                <ButtonMenu
                    id="button-menu"
                    menuAlignment="right"
                    menuSize="small"
                    buttonVariant="brand"
                    buttonSize="x-small"
                    label="Settings"
                    icon={<FontAwesomeIcon icon={faPlus} />}
                >
                      <Link to="/about">
                        <RainbowMenuItem label="About"
                          icon={<FontAwesomeIcon icon={faInfoCircle} />}
                          iconPosition="left"
                        />
                      </Link>

                      <Link to={this.getLink(`/user/onboard/1`)}>
                      <RainbowMenuItem label="Setup"
                        icon={<FontAwesomeIcon icon={faCogs} />}
                        iconPosition="left"
                      />
                      </Link>


                      <Link to={this.getLink(`/faves?u=${this.props.user.uid}`)}>
                      <RainbowMenuItem label="Favorites"
                        icon={<FontAwesomeIcon icon={faHeart} />}
                        iconPosition="left"
                      />
                      </Link>

                      <Link to={this.getLink(`/settings`)}>
                      <RainbowMenuItem label="Profile Settings"
                        icon={<FontAwesomeIcon icon={faCog} />}
                        iconPosition="left"
                      />
                      </Link>

                      <MenuDivider />


                      <RainbowMenuItem label="Sign Out"
                        icon={<FontAwesomeIcon icon={faSignOutAlt} />}
                        iconPosition="left"
                        onClick={onSignOutClick}
                      />

                      {!user && (
                      <ButtonGroup>
                        <RainbowMenuItem label="Sign In"
                          icon={<FontAwesomeIcon icon={faDoorOpen} />}
                          iconPosition="right"
                          disabled={performingAction}
                          onClick={onSignUpClick}
                        />
                        <RainbowMenuItem label="Sign Up"
                          icon={<FontAwesomeIcon icon={faPlus} />}
                          iconPosition="right"
                          disabled={performingAction}
                          onClick={onSignInClick}
                        />
                      </ButtonGroup>
                    )}



                </ButtonMenu>

              </Box>
</>


            )}
             </>
          )}

          {!user && (
            <>
            <Box display="flex">
            <ButtonMenu
                id="button-menu"
                menuAlignment="right"
                menuSize="small"
                buttonVariant="brand"
                buttonSize="x-small"
                label="Sign In/Up"
                icon={<FontAwesomeIcon icon={faPlus} />}
            >

            <RainbowMenuItem label="Sign Up"
              icon={<FontAwesomeIcon icon={faPlus } />}
              iconPosition="right"
              disabled={performingAction}
              onClick={onSignUpClick}
            />
            <RainbowMenuItem label="Sign In"
              icon={<FontAwesomeIcon icon={faDoorOpen} />}
              iconPosition="right"
              disabled={performingAction}
              onClick={onSignInClick}
            />

          </ButtonMenu>

        </Box>
          </>
        )}


        </Toolbar>
      </AppBar>
    );
  }
}

Bar.defaultProps = {
  performingAction: false,
};

Bar.propTypes = {
  // Properties
  performingAction: PropTypes.bool.isRequired,
  user: PropTypes.object,
  userData: PropTypes.object,

  // Events
  onAboutClick: PropTypes.func.isRequired,
  onSettingsClick: PropTypes.func.isRequired,
  onSignOutClick: PropTypes.func.isRequired,
};

export default Bar;
