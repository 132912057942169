import React, { Component } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Link } from "react-router-dom";
import nomtxtApi from "../../services/nomtxt";
import authentication from "../../services/authentication";
import RestoLocations from "../RestoLocations/RestoLocations"
/*
import axios from "axios";

import { Home as HomeIcon } from "@material-ui/icons";
// import { ReactComponent as NotFoundIllustration } from "../../illustrations/not-found.svg";
*/
import{ Button as RButton, ButtonIcon, Input, Accordion, AccordionSection   } from 'react-rainbow-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV, faPlus, faTrashAlt, faTrash, faArrowRight } from '@fortawesome/free-solid-svg-icons';

import { Paper, Box, Button, Typography, Grid, Card, CardContent, CardActionArea, ListItem, ListItemText } from "@material-ui/core";
import spacetime from 'spacetime'
import RestoProgress from "./RestoProgress.js"
import { withRouter } from 'react-router-dom';
import "./SquareAuth.css";

const containerPaperStyles = { width: 500, maxWidth: 750, marginLeft: 20, marginTop: 10 };
const accordianHeaderStyle = { fontWeight: 800 }
const initialState = {
  "youregonnacarrythatweight":"alongtime"
};

const lFaves = { maxWidth: 555, marginTop: 20 };
const btnSuccessStyle = { maxHeight: 35, height: 35, width: 255, marginBottom: 15, marginTop: 15 };
const btnDestructiveStyle = { maxHeight: 35, height: 35, width: 170, marginBottom: 15, marginTop: 15 };


class SquareAuth extends Component {

    constructor(props) {
      super(props);
      this.state = {
        userFaves: [],
        locations: [],
        btnDisabled: false
      };
    };

    getTokenStatus = () => {
      authentication
      .getRestoSquareTokenInfo(this.props.userData.restoId)
      .then((value) => {
        if(this.isTokenExpired(value.token_expires_at) || !value){
          const redirLink = "/resto/setup/payments/square/1";
          this.props.history.push(redirLink);
          alert("redirecting");
        }
        //alert(JSON.stringify(value));
        this.setState({tokenExpiresAt: value.token_expires_at});
        this.setState({restoDocId: value.restoId});
        this.setState({merchantId: value.merchant_id});
      })
      .catch((reason) => {
        console.log("ERROR getting pointer =" + reason);
        const redirLink = "/resto/setup/payments/square/1";
        this.props.history.push(redirLink);
      });

      if(this.checkProfileStatus !== "active"){
        this.setProfileStatus(this.props.userData.restoId, "active")
      }

    }

    getLocationList = () => {
      if(this.state.tokenExpiresAt){
        nomtxtApi
        .getRestoLocations(this.props.userData.restoId)
        .then((value) => {
          this.setState({locations: value.data});
          //alert(JSON.stringify(value));
        })
        .catch((e) => {
          const redirLink = "/resto/setup/payments/square/1";
          this.props.history.push(redirLink);

        })
      } else{
          const redirLink = "/resto/setup/payments/square/1";
          this.props.history.push(redirLink);

      }
    }

    checkProfileStatus = () => {
      if(this.state.tokenExpiresAt && this.props.userData.restoId ){
        return("active");
      }
    }

    setProfileStatus = (restoId, status) => {
      authentication
        .updateRestoStatus(restoId, status)
    }

    handleDeleteBtnClick = () => {
      authentication
        .deleteRestoSquareToken(this.props.userData.restoId)
        .then(() => {
          this.setProfileStatus(this.props.userData.restoId, "user-revoked");
          const redirLink = "/resto/setup/payments/square/1";
          this.props.history.push(redirLink);

        })
        .catch(() => {
            //mayber they clicked twice or some shit. send them to  create token page
          this.setProfileStatus(this.props.userData.restoId, "user-revoked-error");
          const redirLink = "/resto/setup/payments/square/1";
          this.props.history.push(redirLink);
        })
    }

    isTokenExpired = (date) => {
      const st = new spacetime();
      const ex = new spacetime(date);
      if( st.isAfter(date) ){
        return true;

      }
      return false;

    }

  render() {
    const expiry = this.state.tokenExpiresAt;
    let friendlyExpiry = this.state.tokenExpiresAt ? spacetime(expiry) : '' ;
    //friendlyExpiry = friendlyExpiry.unixFmt('yyyy MM .dd h:mm a')
    friendlyExpiry = friendlyExpiry !== '' ? friendlyExpiry.format('{date} {month} {year}') : ''
    const merchant = this.state.merchantId;
    const locations = this.getLocationList;
    return (
      <React.Fragment>

      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
       >
       <Grid item xs={5}>
       <Paper style={lFaves}>
       <RestoProgress currentIndex="1" user={this.props.user} />

        <Typography variant="h4">Square Authorization</Typography>
        <Card variant="outlined">
          <CardContent>
            <Typography variant="h6">{merchant}</Typography>
            <Typography variant="body1">Expires: {friendlyExpiry}</Typography>
          </CardContent>
          <CardActionArea>
          <Accordion id="accordion-1">
            <AccordionSection label="Revoke Access" style={accordianHeaderStyle}>
              <RButton variant="destructive" style={btnDestructiveStyle} onClick={this.handleDeleteBtnClick} >Delete Tokens . <FontAwesomeIcon icon={faTrash} className="rainbow-m-left_medium" />
              </RButton>
            </AccordionSection>
          </Accordion>

          </CardActionArea>
        </Card>
        <Card variant="outlined">
          <CardContent>
            <Typography variant="h6">Locations</Typography>
              <RestoLocations restoId={this.props.userData.restoId} />

          </CardContent>
          <CardContent>
          <Typography variant="h6">Menu Items</Typography>
            Make sure you have items in your square catalog. NomTxt will let users pick from a list to set up their favorites.
            <a href="https://squareup.com" target="_blank">Review Items Now</a>
          </CardContent>
        </Card>
        <Card>
          <CardContent>
            <Button to={`/resto/setup/messaging`} component={Link} disabled={this.state.btnDisabled} variant="contained" color="primary">Configure Messaging Options</Button>


          </CardContent>

        </Card>
        </Paper>
        </Grid>
      </Grid>


      </React.Fragment>
    );
  }

  componentDidMount(){
    this.getTokenStatus();
    //this.getLocationList();
  }


}

export default withRouter(SquareAuth);
