import React, { Component } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Link } from "react-router-dom";
import nomtxtApi from "../../services/nomtxt";
import authentication from "../../services/authentication";
import styled, { css } from 'styled-components';
import firebase, { functions } from "../../firebase";
import axios from 'axios';

/*
import axios from "axios";

import { Home as HomeIcon } from "@material-ui/icons";
// import { ReactComponent as NotFoundIllustration } from "../../illustrations/not-found.svg";
*/
import{  Input, Accordion, AccordionSection, CheckboxGroup   } from 'react-rainbow-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV, faPlus, faTrashAlt, faTrash, faArrowRight } from '@fortawesome/free-solid-svg-icons';

import { Fab, Button, Box, Typography, Paper, Card, CardContent, CardActionArea, ListItem, ListItemText, Grid } from "@material-ui/core";
import spacetime from 'spacetime'

import { withRouter } from 'react-router-dom';



const containerPaperStyles = { width: 500, maxWidth: 750, marginLeft: 20, marginTop: 10 };
const accordianHeaderStyle = { fontWeight: 800 }
const checkboxStyles = { fontWeight: 400  }
const initialState = {
  "youregonnacarrythatweight":"alongtime"
};

const lFaves = { maxWidth: 555 };
const btnSuccessStyle = { maxHeight: 35, height: 35, width: 255, marginBottom: 15, marginTop: 15 };
const btnDestructiveStyle = { maxHeight: 35, height: 35, width: 170, marginBottom: 15, marginTop: 15 };
const containerStyles = {
    maxWidth: 300,
};



class Onboard2 extends Component {

    constructor(props) {
      super(props);
      this.state = {
        locations: [{
          locationId: 123,
          locationName: "~-Please Select Resto-~"
        }],
        selectedLocationId: '',
        btnDisabled: true

      };
     };

     getLocationList = async (restoId) => {

       let tiki = await authentication
       .getUserTokenId()
       .then((token)=>{
         return token;
       })
       .catch((e)=>{
         //alert(e);
         console.log(e)
       })

       //let url = "http://localhost:5000" + this.getNomTxtApiVersion() + `/resto/locations/list/${restoId}`;
       //let url = "http://localhost:5000/api/v1" + `/resto/locations/list/${restoId}`
       let url = nomtxtApi.getApiUrl() + `/resto/locations/list/${restoId}`;
       let lista = await axios.get(url, {headers:
         { authorization: `Bearer ${tiki.token}` }})
         .then((res) => {
           //alert(res.data);

           let localista = res.data.locations.map(function(loca){
             return({
               locationId: loca.id,
               locationName: loca.name,
               addy: `${loca.address.address_line_1}  ${loca.address.locality} `,
               businessName: loca.business_name,
               status: loca.status
             })
           })
           this.setState({locations: localista});
           if(localista.length === 1){
             this.setState({selectedLocationId: localista.locationId});
           }
           //alert(localista)
         })
         .catch((e)=>{
           console.log(e);
         })
         this.setState({btnDisabled: false});

     }

     handleChange = async (event) => {
       //alert("in locationlist now target val: " + event.target.value);
       let locationId = event.target.value;
       this.setState({btnDisabled: false});
       this.setState({selectedLocationId: locationId})
     }




  render() {

    const { locations } = this.state;
    let locationsList = locations.length > 0 &&
      locations.map((item, i) => {
        let k = 'loca_'+i;
        return (
          <option key={k} value={item.locationId}>{item.locationName} {item.addy}</option>
        )
      }, this);

    return (
      <React.Fragment>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        style={{ minHeight: '100vh', marginTop: 15 }}
       >
         <Grid item xs={3}>
          <Card>
            <CardContent>
            <Typography variant="body1">If there's more than one location for this restaurant pick one of them here.</Typography>
            <Typography variant="body2">Sometimes this takes a moment.</Typography>
              <div>
              <select
                id="locationsListSelectElement"
                /* onChange={e =>this.props.onChange(e.target.name, e.target.value)}*/
                onChange={this.handleChange}
                ref="locationsListSelectElement"
                value={this.state.selectedLocationId ? this.state.selectedLocationId : ''}
              >
                {locationsList}
              </select>
              </div>
              </CardContent>
            <CardActionArea>
              <Button to={`/setup/faves/?r=${this.state.restoId}&loc=${this.state.selectedLocationId}&u=${this.props.user.uid}`} component={Link} disabled={this.state.btnDisabled}>Continue</Button>


            </CardActionArea>
          </Card>
          </Grid>
        </Grid>

      </React.Fragment>
    );
  }

  componentDidMount(){
    let qsParams = new URLSearchParams(this.props.location.search);
    let redirLink = "/user/onboard/1"
    //let qsa = this.props.location.search.substr(1).split('&');
    let restoId = '';
    if(qsParams.get("r")){
      restoId = qsParams.get("r");
      this.setState({restoId: restoId});
    }
    else{
      this.props.history.push({pathname: redirLink, props:this.props});
      //const history = useHistory();
      //history.push("/user/onboard/1")
    }

    this.getLocationList(restoId);


  }


}

export default withRouter(Onboard2);
