import React, { Component } from "react";

import PropTypes from "prop-types";

import { withRouter } from "react-router-dom";
import { Box, Typography } from "@material-ui/core";

import { auth } from "../../firebase";

import authentication from "../../services/authentication";

import EmptyState from "../EmptyState";



import { ReactComponent as CabinIllustration } from "../../illustrations/cabin.svg";
import { ReactComponent as InsertBlockIllustration } from "../../illustrations/insert-block.svg";
import { ReactComponent as NomTextLogoIllustration } from "../../illustrations/logo.svg"
import { ReactComponent as NomSplash } from "../../illustrations/pink-splash.svg"
import { ReactComponent as NomSplashTxt } from "../../illustrations/pink-splash-tagline.svg"




class HomePage extends Component {
  signInWithEmailLink = () => {
    const { user } = this.props;

    if (user) {
      return;
    }

    const emailLink = window.location.href;

    if (!emailLink) {
      return;
    }

    if (auth.isSignInWithEmailLink(emailLink)) {
      let emailAddress = localStorage.getItem("emailAddress");

      if (!emailAddress) {
        this.props.history.push("/");

        return;
      }

      authentication
        .signInWithEmailLink(emailAddress, emailLink)
        .then((value) => {
          const user = value.user;
          const displayName = user.displayName;
          const emailAddress = user.email;
          const phoneNumber = user.phoneNumber;

          this.props.openSnackbar(
            `Signed in as ${displayName || emailAddress}`
          );
        })
        .catch((reason) => {
          const code = reason.code;
          const message = reason.message;

          switch (code) {
            case "auth/expired-action-code":
            case "auth/invalid-email":
            case "auth/user-disabled":
              this.props.openSnackbar(message);
              break;

            default:
              this.props.openSnackbar(message);
              return;
          }
        })
        .finally(() => {
          this.props.history.push("/settings/");
        });
    }
  };

  render() {
    const { user } = this.props;

    if (user) {
      return (
        <>
        <Box >
          <NomSplashTxt style={{  }} />
        </Box>
        </>
      );
    }

    return (
      <Box >
        <NomSplash style={{  }} />
      </Box>
    );
  }

  componentDidMount() {
    this.signInWithEmailLink();
  }
}

HomePage.propTypes = {
  user: PropTypes.object,
};

export default withRouter(HomePage);
