import React, { Component } from "react";
import { makeStyles } from '@material-ui/core/styles';
import './userStyles.css'
import { Link } from "react-router-dom";
import TipForm from "../Forms/TipForm"
import { Fab, Box, Typography, Paper, List, ListItem, ListItemAvatar, Avatar, ListItemText, Grid, ListItemSecondaryAction, Card, CardContent, CardActionArea, FormControl, InputLabel, OutlinedInput, InputAdornment } from "@material-ui/core";

import { Home as HomeIcon } from "@material-ui/icons";
import { withRouter } from 'react-router-dom';
// import { ReactComponent as NotFoundIllustration } from "../../illustrations/not-found.svg";
import{ Button, ButtonIcon,    } from 'react-rainbow-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV, faPlus, faTrashAlt, faTrash, faStar } from '@fortawesome/free-solid-svg-icons';
import authentication from "../../services/authentication";
import nomtxtApi from "../../services/nomtxt";

import {
  Favorite as FavoriteIcon,
  Delete as DeleteIcon
} from "@material-ui/icons";

const inputStyles = {
    width: 100,
    marginRight: 15,
    textAlign: 'right',

};

const initialState = {
  "ilike":"bigbutts"
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '70%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  faveslist: {
    width: '70%',
    maxWidth: 560,
    backgroundColor: theme.palette.background.paper,
  },
  solidBtns: {
    backgroundColor: '#00E5FF'
  }
}));

const lFaves = { maxWidth: 555, marginLeft: 30, marginTop: 15, paddingLeft: 15, paddingRight: 15, paddingBottom: 15 };
const cardStyles = { marginTop: 15, paddingBottom: 25 }
const buttonStyles = { width: 150, backgroundColor: '#00E5FF' }
const tipCardStyle = {marginTop: 30}
class UserFaves extends Component {

    constructor(props) {
      super(props);
      this.state = {
        empty: true,
        userId: ""
      };
    };

    getUserFaves = async (userId) => {
      //alert(JSON.stringify(this.props));
      //use logged in users id
      //TODO: add admin function to review per resto
      let faveslist = await authentication
      .getUserRestoFavesList(userId)
      .then((result) => {
        if(!result.length > 0 ){
        }
         //alert("heres the user faves" + JSON.stringify(result));
         this.setState({userFaves: result});
         this.setState({empty: false});
      })
      .catch((e) => {
        if(e === "No faves found for user"){
        }

      })

    }



    deleteBtnClick = async (faveId) => {
      authentication
        .deleteUserFave(faveId, this.state.userId)
        .then(() => {
          //this.props.history.push(redirLink);
          let data = this.state.userFaves;
          //let newdata = data.filter( value => (value.v !== e.currentTarget.id) );
          let newdata = data.filter( i => ( !i.includes(faveId)) );
          this.setState({userFaves: newdata });

        })
        .catch(() => {
            //mayber they clicked twice or some shit. send them to  create token page
          //const redirLink = "/resto/setup/payments/square/1";
          //this.props.history.push(redirLink);
        })
        //alert("customerid " + this.state.cardset[0].sqCustId);
        //alert("card id = " + cardId);
    }


  render() {
    const faves = this.state.userFaves;
    const empty = this.state.empty;
    const apiUrl = nomtxtApi.getApiUrl();
    const apiVersion = nomtxtApi.getApiVersion();
    const vcardEndpoint = "/resto/vcard"; //:restoName/:restoTel




    return (
      <React.Fragment>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
       >
       <Grid item xs={5}>
        <Paper style={lFaves}>
        <Typography variant="h5">Your Saved Favorites</Typography>
        <Typography variant="body1">
          Download the contact info besides the restaurants name or copy paste their order text number.
          You'll need to order from the phone stored in your profile here.</Typography>
        <Typography variant="body1">  Don't forget to save a nickname, so you can use your favorite AI assitant to order via "hey Siri!", or "hey Cortana!"
        </Typography>
        <List>
        { !empty && (
            faves && faves.map(
              function(fave){
                return(
                  <Card key={`card_${fave.faveId}`} style={cardStyles}>
                    <CardContent>
                      <ListItem key={`li_${fave.faveId}`}>
                        <ListItemText>
                            <Grid container spacing={2}
                              direction="row"
                              justify="space-between"
                              alignItems="center"
                            >
                              <Grid item xs={8} key={`grid_header_name_${fave.restoId}`}>
                                <Typography variant="h6">{ fave.restoName } </Typography>
                                <Typography variant="h6"><a href={`${apiUrl}${apiVersion}/${vcardEndpoint}/${fave.restoName}/${fave.restotel}`}>{fave.restotel}</a> </Typography>
                              </Grid>
                              <Grid item xs={4} key={`grid_header_vcard_${fave.restoId}`}>
                                <a href={`${apiUrl}${apiVersion}/${vcardEndpoint}/${fave.restoName}/${fave.restotel}`}>Save Contact</a>
                              </Grid>
                            </Grid>

                          <>
                            <ul>
                              { fave.orderData.map(function(order){
                                return(
                                  <li key={`li_${JSON.parse(order).i}`}>{JSON.parse(order).q} {JSON.parse(order).n}</li>
                                )
                              })

                              }
                            </ul>
                          </>
                        </ListItemText>
                      </ListItem>
                    </CardContent>
                    <CardActionArea>
                      <Link to={`/user/payments/square/?r=${fave.restoId}&l=${fave.locationId}`}>
                      <Button color="secondary" variant="brand" style={buttonStyles}  >
                        Payments
                      </Button>
                      </Link>
                      <Link to={`/user/payments/storedCards/?r=${fave.restoId}&l=${fave.locationId}`}>
                      <Button color="secondary" variant="brand" style={buttonStyles}  >
                        Saved Cards
                      </Button>
                      </Link>
                      <Button color="destructive" onClick={() => { this.deleteBtnClick(`${fave.faveId}`) }} >
                        Reset
                      </Button>
                    </CardActionArea>

                  </Card>
                )
            })
          )}
          { empty &&
            (
              <Typography variant="body1">You haven't saved any favorites yet.
                <Link to="/user/onboard/1">Please start here</Link>.

              </Typography>

            )
          }
        </List>
          <TipForm userId={this.props.user.uid} />
        </Paper>
        </Grid>
      </Grid>
      </React.Fragment>
    );
  }

  componentDidMount(){

    let qsParams = new URLSearchParams(this.props.location.search);
    let redirLink = "/user/onboard/1"
    //let qsa = this.props.location.search.substr(1).split('&');
    let userId = "";
    if(qsParams.get("u")){
      userId = qsParams.get("u");
      this.setState({userId: userId});
    }

    this.getUserFaves(userId);
  }


}

export default withRouter(UserFaves);
