import firebase, { analytics, auth, firestore, storage } from "../firebase";
import nomtxtApi from "./nomtxt";
import spacetime from 'spacetime'
import axios from 'axios'


import moment from "moment";
const FieldValue = firebase.firestore.FieldValue;
const authentication = {};

authentication.signUp = (fields) => {
  return new Promise((resolve, reject) => {
    if (!fields) {
      reject(new Error("No fields"));

      return;
    }

    const firstname = fields.firstname;
    const lastname = fields.lastname;
    const username = fields.username;
    const emailAddress = fields.emailAddress;
    const password = fields.password;
    const restoName = fields.restoName ? fields.restoName : null;

    if (!firstname || !lastname || !username || !emailAddress || !password) {
      reject(
        new Error(
          "No first name, last name, username, e-mail address, or password"
        )
      );

      return;
    }

    if (auth.currentUser) {
      reject(new Error("No current user"));

      return;
    }

    auth
      .createUserWithEmailAndPassword(emailAddress, password)
      .then((value) => {
        const user = value.user;

        if (!user) {
          reject(new Error("No user"));

          return;
        }

        const uid = user.uid;

        if (!uid) {
          reject(new Error("No UID"));

          return;
        }

        //TODO: maybe this is a place to return
        const userDocumentReference = firestore.collection("users").doc(uid);

        userDocumentReference
          .set({
            firstname: firstname,
            lastname: lastname,
            username: username,
            email: emailAddress
          })
          .then((value) => {
            analytics.logEvent("sign_up", {
              method: "password",
            });

            resolve(value);
          })
          .catch((reason) => {
            reject(reason);
          });
      })
      .catch((reason) => {
        reject(reason);
      });
  });
};

authentication.signUpWithEmailAddressAndPasswordRegular = (emailAddress, password, phoneNumber) => {
  return new Promise((resolve, reject) => {
    if (!emailAddress || !password) {
      reject(new Error("No e-mail address or password"));

      return;
    }

    if (auth.currentUser) {
      reject(new Error("No current user"));

      return;
    }

    if(phoneNumber.substring(0,1) !== "1"){
      phoneNumber = "1" + phoneNumber;
    }

    auth
      .createUserWithEmailAndPassword(emailAddress, password)
      .then((value) => {
        const user = value.user;

        if (!user) {
          reject(new Error("No user"));

          return;
        }

        const uid = user.uid;

        if (!uid) {
          reject(new Error("No UID"));

          return;
        }

        const userDocumentReference = firestore.collection("users").doc(uid);

        userDocumentReference
          .set({phoneNumber: phoneNumber}, { merge: true })
          .then((value) => {
            analytics.logEvent("sign_up", {
              method: "password",
            });

            resolve(user);
          })
          .catch((reason) => {
            reject(reason);
          });
      })
      .catch((reason) => {
        reject(reason);
      });
  });
};

authentication.signUpWithEmailAddressAndPassword = (emailAddress, password, phoneNumber, restoName) => {
  return new Promise((resolve, reject) => {
    if (!emailAddress || !password) {
      reject(new Error("No e-mail address or password"));

      return;
    }

    if (auth.currentUser) {
      reject(new Error("No current user"));

      return;
    }

    if(phoneNumber.substring(0,1) !== "1"){
      phoneNumber = "1" + phoneNumber;
    }

    auth
      .createUserWithEmailAndPassword(emailAddress, password)
      .then((value) => {
        const user = value.user;

        if (!user) {
          reject(new Error("No user"));

          return;
        }

        const uid = user.uid;

        if (!uid) {
          reject(new Error("No UID"));

          return;
        }
        const restoId = Date.now()+'WSS';
        const userDocumentReference = firestore.collection("users").doc(uid);
        let payload;
        if(restoName){
          payload = {
            phoneNumber: phoneNumber,
            email: emailAddress,
            userType: "resto-admin",
            restoId: restoId,
            userStatus: "new"
          }
        }
        else{
          payload = {
            phoneNumber: phoneNumber,
            email: emailAddress,
            userType: "regular",
            userStatus: "new"
          }
        }

        const restoDocumentReference = firestore.collection("restos");
        const userId = [];
        userId.push(uid);
        const data = {
          resto: restoName,
          restoId: restoId,
          adminIds: userId,
          status: "new"
        };


        userDocumentReference
          //.set({phoneNumber: phoneNumber}, { merge: true })
          .set(payload, { merge: true })
          .then((value) => {
            analytics.logEvent("sign_up", {
              method: "password",
            });

            restoDocumentReference.doc(restoId).set(data, { merge: true })
            .then((value) => {
              analytics.logEvent("sign_up_resto", {
                method: "password",
              });

            })
            .catch(e => {
              console.log(e.message);
            })

            resolve(user);
          })
          .catch((reason) => {
            console.log(reason);
            reject(reason);
          });
      })
      .catch((reason) => {
        console.log(reason);
        reject(reason);
      });


  });
};

authentication.signIn = (emailAddress, password) => {
  return new Promise((resolve, reject) => {
    if (!emailAddress || !password) {
      reject(new Error("No e-mail address or password"));

      return;
    }

    if (auth.currentUser) {
      reject(new Error("Already Signed in"));

      return;
    }

    auth
      .signInWithEmailAndPassword(emailAddress, password)
      .then((value) => {
        const user = value.user;

        if (!user) {
          reject(new Error("No user"));

          return;
        }

        const uid = user.uid;

        if (!uid) {
          reject(new Error("No UID"));

          return;
        }

        const userDocumentReference = firestore.collection("users").doc(uid);

        userDocumentReference
          .get({ source: "server" })
          .then((value) => {
            if (value.exists) {
              analytics.logEvent("login", {
                method: "password",
              });

              resolve(user);
            } else {
              userDocumentReference
                .set({}, { merge: true })
                .then((value) => {
                  analytics.logEvent("login", {
                    method: "password",
                  });

                  resolve(user);
                })
                .catch((reason) => {
                  reject(reason);
                });
            }
          })
          .catch((reason) => {
            reject(reason);
          });
      })
      .catch((reason) => {
        reject(reason);
      });
  });
};

authentication.sendSignInLinkToEmail = (emailAddress) => {
  return new Promise((resolve, reject) => {
    if (!emailAddress) {
      reject(new Error("No e-mail address"));

      return;
    }

    if (auth.currentUser) {
      reject(new Error("No current user"));

      return;
    }

    const actionCodeSettings = {
      url: process.env.REACT_APP_HOMEPAGE,
      handleCodeInApp: true,
    };

    auth
      .sendSignInLinkToEmail(emailAddress, actionCodeSettings)
      .then((value) => {
        analytics.logEvent("send_sign_in_link_to_email");

        localStorage.setItem("emailAddress", emailAddress);

        resolve(value);
      })
      .catch((reason) => {
        reject(reason);
      });
  });
};

authentication.signInWithEmailLink = (emailAddress, emailLink) => {
  return new Promise((resolve, reject) => {
    if (!emailAddress || !emailLink) {
      reject(new Error("No e-mail address or e-mail link"));

      return;
    }

    if (auth.currentUser) {
      reject(new Error("No current user"));

      return;
    }

    auth
      .signInWithEmailLink(emailAddress, emailLink)
      .then((value) => {
        analytics.logEvent("login", {
          method: "email-link",
        });

        localStorage.removeItem("emailAddress");

        resolve(value);
      })
      .catch((reason) => {
        reject(reason);
      });
  });
};

authentication.signInWithAuthProvider = (provider) => {
  return new Promise((resolve, reject) => {
    if (!provider) {
      reject(new Error("No provider"));

      return;
    }

    const authProvider = new firebase.auth.OAuthProvider(provider.id);
    const scopes = provider.scopes;

    if (scopes) {
      scopes.forEach((scope) => {
        authProvider.addScope(scope);
      });
    }

    if (auth.currentUser) {
      reject(new Error("No current user"));

      return;
    }

    auth
      .signInWithPopup(authProvider)
      .then((value) => {
        const user = value.user;

        if (!user) {
          reject(new Error("No user"));

          return;
        }

        const uid = user.uid;

        if (!uid) {
          reject(new Error("No UID"));

          return;
        }

        const userDocumentReference = firestore.collection("users").doc(uid);

        userDocumentReference
          .get({ source: "server" })
          .then((value) => {
            if (value.exists) {
              analytics.logEvent("login", {
                method: provider.id,
              });

              resolve(user);
            } else {
              userDocumentReference
                .set({}, { merge: true })
                .then((value) => {
                  analytics.logEvent("login", {
                    method: provider.id,
                  });

                  resolve(user);
                })
                .catch((reason) => {
                  reject(reason);
                });
            }
          })
          .catch((reason) => {
            reject(reason);
          });
      })
      .catch((reason) => {
        reject(reason);
      });
  });
};

authentication.linkAuthProvider = (provider) => {
  return new Promise((resolve, reject) => {
    if (!provider) {
      reject(new Error("No provider"));

      return;
    }

    const authProvider = new firebase.auth.OAuthProvider(provider.id);
    const scopes = provider.scopes;

    if (scopes) {
      scopes.forEach((scope) => {
        authProvider.addScope(scope);
      });
    }

    const currentUser = auth.currentUser;

    if (!currentUser) {
      reject(new Error("No current user"));

      return;
    }

    currentUser
      .linkWithPopup(authProvider)
      .then((value) => {
        analytics.logEvent("link_auth_provider", {
          providerId: authProvider.id,
        });

        resolve(value);
      })
      .catch((reason) => {
        reject(reason);
      });
  });
};

authentication.unlinkAuthProvider = (providerId) => {
  return new Promise((resolve, reject) => {
    if (!providerId) {
      reject(new Error("No provider ID"));

      return;
    }

    const currentUser = auth.currentUser;

    if (!currentUser) {
      reject(new Error("No current user"));

      return;
    }

    currentUser
      .unlink(providerId)
      .then((value) => {
        analytics.logEvent("unlink_auth_provider", {
          providerId: providerId,
        });

        resolve(value);
      })
      .catch((reason) => {
        reject(reason);
      });
  });
};

authentication.authProviderData = (providerId) => {
  if (!providerId) {
    return;
  }

  const currentUser = auth.currentUser;

  if (!currentUser) {
    return;
  }

  const providerData = currentUser.providerData;

  if (!providerData) {
    return;
  }

  return providerData.find((authProvider) => authProvider.id === providerId);
};

authentication.signOut = () => {
  return new Promise((resolve, reject) => {
    const currentUser = auth.currentUser;

    if (!currentUser) {
      reject(new Error("No current user"));

      return;
    }

    auth
      .signOut()
      .then((value) => {
        analytics.logEvent("sign_out");

        resolve(value);
      })
      .catch((reason) => {
        reject(reason);
      });
  });
};

authentication.resetPassword = (emailAddress) => {
  return new Promise((resolve, reject) => {
    if (!emailAddress) {
      reject(new Error("No e-mail address"));

      return;
    }

    if (auth.currentUser) {
      reject(new Error("No current user"));

      return;
    }

    auth
      .sendPasswordResetEmail(emailAddress)
      .then((value) => {
        analytics.logEvent("reset_password");

        resolve(value);
      })
      .catch((reason) => {
        reject(reason);
      });
  });
};

authentication.changeAvatar = (avatar) => {
  return new Promise((resolve, reject) => {
    if (!avatar) {
      reject(new Error("No avatar"));

      return;
    }

    const avatarFileTypes = [
      "image/gif",
      "image/jpeg",
      "image/png",
      "image/webp",
      "image/svg+xml",
    ];

    if (!avatarFileTypes.includes(avatar.type)) {
      reject(new Error("Invalid file type"));

      return;
    }

    if (avatar.size > 20 * 1024 * 1024) {
      reject(new Error("Invalid size"));

      return;
    }

    const currentUser = auth.currentUser;

    if (!currentUser) {
      reject(new Error("No current user"));

      return;
    }

    const uid = currentUser.uid;

    if (!uid) {
      reject(new Error("No UID"));

      return;
    }

    const avatarReference = storage
      .ref()
      .child("images")
      .child("avatars")
      .child(uid);

    avatarReference
      .put(avatar)
      .then((uploadTaskSnapshot) => {
        avatarReference
          .getDownloadURL()
          .then((value) => {
            currentUser
              .updateProfile({
                photoURL: value,
              })
              .then((value) => {
                analytics.logEvent("change_avatar");

                resolve(value);
              })
              .catch((reason) => {
                reject(reason);
              });
          })
          .catch((reason) => {
            reject(reason);
          });
      })
      .catch((reason) => {
        reject(reason);
      });
  });
};

authentication.removeAvatar = () => {
  return new Promise((resolve, reject) => {
    const currentUser = auth.currentUser;

    if (!currentUser) {
      reject(new Error("No current user"));

      return;
    }

    const uid = currentUser.uid;

    if (!uid) {
      reject(new Error("No UID"));

      return;
    }

    currentUser
      .updateProfile({
        photoURL: null,
      })
      .then((value) => {
        const avatarReference = storage
          .ref()
          .child("images")
          .child("avatars")
          .child(uid);

        avatarReference
          .delete()
          .then((value) => {
            analytics.logEvent("remove_avatar");

            resolve(value);
          })
          .catch((reason) => {
            reject(reason);
          });
      })
      .catch((reason) => {
        reject(reason);
      });
  });
};

authentication.changeFirstName = (firstname) => {
  return new Promise((resolve, reject) => {
    if (!firstname) {
      reject(new Error("No first name"));

      return;
    }

    const currentUser = auth.currentUser;

    if (!currentUser) {
      reject(new Error("No current user"));

      return;
    }

    const uid = currentUser.uid;

    if (!uid) {
      reject(new Error("No UID"));

      return;
    }

    const userDocumentReference = firestore.collection("users").doc(uid);

    userDocumentReference
      .update({
        firstname: firstname,
      })
      .then((value) => {
        analytics.logEvent("change_first_name");

        resolve(value);
      })
      .catch((reason) => {
        reject(reason);
      });
  });
};


authentication.changeRestoName = (restoName, restoId) => {
  return new Promise((resolve, reject) => {
    if (!restoName || ! restoId) {
      reject(new Error("No restaurant name or Id"));

      return;
    }

    const currentUser = auth.currentUser;

    if (!currentUser) {
      reject(new Error("No current user"));

      return;
    }

    const uid = currentUser.uid;

    if (!uid) {
      reject(new Error("No UID"));

      return;
    }

    const restoDocumentReference = firestore.collection("restos").doc(restoId);

    restoDocumentReference
      .update({
        resto: restoName,
      })
      .then((value) => {
        analytics.logEvent("change_resto_name");

        resolve(value);
      })
      .catch((reason) => {
        reject(reason);
      });
  });
};


authentication.changeLastName = (lastname) => {
  return new Promise((resolve, reject) => {
    if (!lastname) {
      reject(new Error("No last name"));

      return;
    }

    const currentUser = auth.currentUser;

    if (!currentUser) {
      reject(new Error("No current user"));

      return;
    }

    const uid = currentUser.uid;

    if (!uid) {
      reject(new Error("No UID"));

      return;
    }

    const userDocumentReference = firestore.collection("users").doc(uid);

    userDocumentReference
      .update({
        lastname: lastname,
      })
      .then((value) => {
        analytics.logEvent("change_last_name");

        resolve(value);
      })
      .catch((reason) => {
        reject(reason);
      });
  });
};

authentication.changePhoneNumber = (phoneNumber) => {
  return new Promise((resolve, reject) => {
    if (!phoneNumber) {
      reject(new Error("No phone number"));

      return;
    }

    const currentUser = auth.currentUser;

    if (!currentUser) {
      reject(new Error("No current user"));

      return;
    }

    const uid = currentUser.uid;

    if (!uid) {
      reject(new Error("No UID"));

      return;
    }

    const userDocumentReference = firestore.collection("users").doc(uid);

    userDocumentReference
      .update({
        phoneNumber: phoneNumber,
      })
      .then((value) => {
        analytics.logEvent("change_phone_number");

        resolve(value);
      })
      .catch((reason) => {
        reject(reason);
      });
  });
};

authentication.changeUsername = (username) => {
  return new Promise((resolve, reject) => {
    if (!username) {
      reject(new Error("No username"));

      return;
    }

    const currentUser = auth.currentUser;

    if (!currentUser) {
      reject(new Error("No current user"));

      return;
    }

    const uid = currentUser.uid;

    if (!uid) {
      reject(new Error("No UID"));

      return;
    }

    const userDocumentReference = firestore.collection("users").doc(uid);

    userDocumentReference
      .update({
        username: username,
      })
      .then((value) => {
        analytics.logEvent("change_username");

        resolve(value);
      })
      .catch((reason) => {
        reject(reason);
      });
  });
};

authentication.changeEmailAddress = (emailAddress) => {
  return new Promise((resolve, reject) => {
    if (!emailAddress) {
      reject(new Error("No e-mail address"));

      return;
    }

    const currentUser = auth.currentUser;

    if (!currentUser) {
      reject(new Error("No current user"));

      return;
    }

    const uid = currentUser.uid;

    if (!uid) {
      reject(new Error("No UID"));

      return;
    }

    currentUser
      .updateEmail(emailAddress)
      .then((value) => {
        analytics.logEvent("change_email_address");

        resolve(value);
      })
      .catch((reason) => {
        reject(reason);
      });
  });
};

authentication.changePassword = (password) => {
  return new Promise((resolve, reject) => {
    if (!password) {
      reject(new Error("No password"));

      return;
    }

    const currentUser = auth.currentUser;

    if (!currentUser) {
      reject(new Error("No current user"));

      return;
    }

    const uid = currentUser.uid;

    if (!uid) {
      reject(new Error("No UID"));

      return;
    }

    currentUser
      .updatePassword(password)
      .then((value) => {
        const userDocumentReference = firestore.collection("users").doc(uid);

        userDocumentReference
          .update({
            lastPasswordChange: firebase.firestore.FieldValue.serverTimestamp(),
          })
          .then((value) => {
            analytics.logEvent("change_password");

            resolve(value);
          })
          .catch((reason) => {
            reject(reason);
          });
      })
      .catch((reason) => {
        reject(reason);
      });
  });
};

authentication.verifyEmailAddress = () => {
  return new Promise((resolve, reject) => {
    const currentUser = auth.currentUser;

    if (!currentUser) {
      reject(new Error("No current user"));

      return;
    }

    currentUser
      .sendEmailVerification()
      .then((value) => {
        analytics.logEvent("verify_email_address");

        resolve(value);
      })
      .catch((reason) => {
        reject(reason);
      });
  });
};

authentication.deleteAccount = () => {
  return new Promise((resolve, reject) => {
    const currentUser = auth.currentUser;

    if (!currentUser) {
      reject(new Error("No current user"));

      return;
    }

    currentUser
      .delete()
      .then((value) => {
        analytics.logEvent("delete_account");

        resolve(value);
      })
      .catch((reason) => {
        reject(reason);
      });
  });
};

authentication.getRoles = () => {
  return new Promise((resolve, reject) => {
    const currentUser = auth.currentUser;

    if (!currentUser) {
      reject(new Error("No current user"));

      return;
    }

    currentUser
      .getIdTokenResult()
      .then((idTokenResult) => {
        resolve(idTokenResult.claims.roles);
      })
      .catch((reason) => {
        reject(reason);
      });
  });
};

authentication.getUserTokenId = () => {
  return new Promise((resolve, reject) => {
    const currentUser = auth.currentUser;

    if (!currentUser) {
      reject(new Error("No current user"));

      return;
    }

    currentUser
      .getIdTokenResult()
      .then((idTokenResult) => {
        resolve(idTokenResult);
      })
      .catch((reason) => {
        reject(reason);
      });
  });
}

authentication.isAdmin = () => {
  return new Promise((resolve, reject) => {
    authentication
      .getRoles()
      .then((value) => {
        resolve(value.includes("admin"));
      })
      .catch((reason) => {
        reject(reason);
      });
  });
};

authentication.isPremium = () => {
  return new Promise((resolve, reject) => {
    authentication
      .getRoles()
      .then((value) => {
        resolve(value.includes("premium"));
      })
      .catch((reason) => {
        reject(reason);
      });
  });
};

authentication.getName = (fields) => {
  if (!fields) {
    return null;
  }

  const firstname = fields.firstname;
  const username = fields.username;
  const displayName = fields.displayName;
  const lastname = fields.lastname;

  if (firstname) {
    return firstname;
  }

  if (username) {
    return username;
  }

  if (displayName) {
    return displayName;
  }

  if (lastname) {
    return lastname;
  }

  return null;
};

authentication.getFullName = (fields) => {
  if (!fields) {
    return null;
  }

  const firstname = fields.firstname;
  const lastname = fields.lastname;
  const displayName = fields.displayName;

  if (firstname && lastname) {
    return `${firstname} ${lastname}`;
  }

  if (displayName) {
    return displayName;
  }

  return null;
};

authentication.getNameInitials = (fields) => {
  if (!fields) {
    return null;
  }

  const firstname = fields.firstname;
  const lastname = fields.lastname;
  const username = fields.username;
  const displayName = fields.displayName;

  if (firstname && lastname) {
    return firstname.charAt(0) + lastname.charAt(0);
  }

  if (firstname) {
    return firstname.charAt(0);
  }

  if (username) {
    return username.charAt(0);
  }

  if (lastname) {
    return lastname.charAt(0);
  }

  if (displayName) {
    return displayName.charAt(0);
  }

  return null;
};

authentication.getProfileCompletion = (fields) => {
  if (!fields) {
    return null;
  }

  fields = [
    fields.photoURL,
    fields.firstname,
    fields.lastname,
    fields.username,
    fields.email,
    fields.email && fields.emailVerified,
  ];

  if (!fields) {
    return null;
  }

  let profileCompletion = 0;

  fields.forEach((field) => {
    if (field) {
      profileCompletion += 100 / fields.length;
    }
  });

  return Math.floor(profileCompletion);
};


authentication.getNomtxtPromo = (userId) => {
  return new Promise( async (resolve, reject) => {
    if(!userId){
      return "userId is required";
    }

    const userRef = firestore.collection("users").doc(userId);
    const userDoc = await userRef.get();
    let promo;
    if(userDoc.empty){
      console.log('user not found');
      resolve({data: {msg: "promo code is valid but there was an error: p001"}});
      return;
    } else{
      promo = {
        promoCode: userDoc.data().promoCode,
        promoPeriod: userDoc.data().promoPeriod,
        promoCount: userDoc.data().promoCount,
        promoAppliedDate: userDoc.data().promoAppliedDate,
        promoEndDate: userDoc.data().promoEndDate
      };
      resolve({data: {msg: 'A promo code has already been saved. Valid until: ' + new spacetime( userDoc.data().promoEndDate).format('nice'), promo: promo}})
    }
    return;

  })
}

authentication.setNomtxtPromo = (promoCode, userId, restoId) =>{
  return new Promise( async(resolve,reject) => {
    if(!promoCode || !userId){
      return "promoCode and userId are required";
    }
    const promoRef = firestore.collection("promos").doc(promoCode);
    const doc = await promoRef.get();
    let data;
    if (doc.empty) {
      console.log('promo code not found.');
      data = false;
    } else {
      data = doc.data();
    }

    if(!data){
      // promo code is Invalid
      resolve({data: {msg:"promo code is not valid"}});
      return;
    }

    const userRef = firestore.collection("users").doc(userId);
    const userDoc = await userRef.get();
    let user;
    if(userDoc.empty){
      console.log('user not found');
      resolve({data: {msg: "promo code is valid but there was an error: p001"}});
      return;
    } else{
      user = userDoc.data();
    }

    if(user.userType !== 'resto-admin'){
      console.log('user not resto-admin');
      resolve({data: {msg: "promo code is valid but there was an error: p002"}});
      return;
    }

    if(user.restoId !== restoId){
      console.log('user not admin for this resto');
      resolve({data: {msg: "promo code is valid but there was an error: p003"}});
      return;
    }

    if(user.promoCount && user.promoCount >= 1){
      console.log('not first time promo use');
      resolve({data: {msg: "Promotions can only be applied once: p004"}});
      return;
    }

    const nowdate = new spacetime();
    let promoEndDate = nowdate.add(parseInt(data.promoPeriod), 'day');


    // otherwise, let's try to save the promo codes
    userRef.update({
      promoCode: promoCode,
      promoCount: user.promoCount ? user.promoCount++ : 1,
      promoAppliedDate: firebase.firestore.FieldValue.serverTimestamp(),
      promoEndDate: promoEndDate.format('iso'),
      promoPeriod: data.promoPeriod
    })
    .then(() => {
      console.log('updated the user');
      resolve({data: {msg: "promocode applied", promo: data }});
      return;
    })
    .catch((e) => {
      console.log('error updating the user');
      resolve({data: {msg: "promocode valid but unable to save to user profile p005" }});
      return;
    })

  })
}

authentication.getRestoInfo = (restoId) => {
  return new Promise((resolve, reject) => {
    if (!restoId) {
      return null;
    }
    //const restoDocumentReference = firestore.collection("restos").doc(restoId);

    console.log("RESTO ID: "+restoId);
    var docRef = firestore.collection("restos").doc(restoId);

    docRef.get().then(function(doc) {
        if (doc.exists) {
            //console.log("Document data:", doc.data());
            resolve(doc.data());
        } else {
            // doc.data() will be undefined in this case
            //console.log("No such document!");
            reject("doc not found");
        }
    }).catch(function(error) {
        reject(error);
    });

  });
};

authentication.getUserAllFields = (phoneNumber) => {
  return new Promise((resolve, reject) => {
    if (!phoneNumber) {
      return null;
    }

    const userdata = {};

    const usersRef = firestore.collection("users");
     usersRef.where('phoneNumber', '==', phoneNumber).limit(1)
    const snapshot =  usersRef.where('phoneNumber', '==', phoneNumber).limit(1)
    .get();
    if (snapshot.empty) {
      console.log('No matching documents.');
      return;
    } else {
      return snapshot.data();
    }

  });
}

authentication.getUserData = (uid) => {
  return new Promise(async (resolve, reject) => {
    if (!uid) {
      return null;
    }

    const userdata = {};

    const usersRef = firestore.collection("users").doc(uid)
    const doc =  await usersRef.get();
    if (doc.empty) {
      console.log('No matching documents.');
      //alert('No users found');
      return;
    } else {
      resolve(doc.data());
    }

  });
}

authentication.storeUserCardPointer = (storedCard, userId) => {
  return new Promise(async (resolve, reject) => {
    if (!userId) {
      reject("UID can not be null");
    }
    const cardId = storedCard.card_nonce;
    const userCardsRef = firestore.collection("usercards").doc(cardId);
    let res = userCardsRef.set({
      userId: userId,
      cardId: cardId,
      storedCard: storedCard
    });


  })
}

authentication.getUserStoredCardsPointers = (uid) => {
  return new Promise(async (resolve, reject) => {
    if (!uid) {
      reject("UID can not be null");
    }

    let cardPointers = {};

    const query =
      await firestore
      .collection("usercards")
      .where("userId", "==", uid).get()
      .then(snapshot => {

        if (snapshot.empty) {
          console.log('No matching stored card pointers.');
        }
        let i= 0;
        let rel = []
        snapshot.docs.forEach(document => {
          i++;
         if (document.exists) {
           /*
           let rel = {"last_4": document.data().last_4,
             "exp_month": document.data().exp_month,
             "exp_year": document.data().exp_year,
             "card_brand": document.data().card_brand,
             "id": document.data().card_id
          }*/
          rel.push({"last_4": document.data().last_4,
              "exp_month": document.data().exp_month,
              "exp_year": document.data().exp_year,
              "card_brand": document.data().card_brand,
              "card_id": document.data().card_id,
              "sqCustId": document.data().sqCustId,
              "restoId": document.data().restoId,
              "id": document.id
           });

           analytics.logEvent("getUserStoredCardsPointers");
           resolve(rel);
         } else {
           console.log('No matching documents.');
         }
        })
        //resolve(cardPointers);
      })
      .catch((reason) => {
        reject(reason);
      });

  });
}


authentication.removeUserCard = (card_id) => {
  return new Promise((resolve, reject) => {
    if (!card_id) {
      reject("card and user can not be null");
      return;
    }

    const res = firestore.collection('usercards').doc(card_id).delete()
    .then(snapshot => {
      analytics.logEvent("removeUserCard");
    })
    .catch(reason => {
      reject(reason);
    });

    resolve();

  });
}

authentication.getRestoList = () => {
  return new Promise(async (resolve, reject) => {

    let restoList = {};

    const query =
      firestore
      .collection("restos")
      .where("status", "==", "active").get()
      .then(snapshot => {

        if (snapshot.empty) {
          console.log('No matching documents.');
        }
        let i= 0;
        snapshot.docs.forEach(document => {
          i++;
         if (document.exists) {
           //console.log('Document data:',document.data());
           //console.log('Document data type:',typeof(document.data());
           //dont return all card data for this. just the pointer
           let rel = {
             "resto": document.data().resto,
             "restoId": document.data().restoId,
             "listsid": document.data().listsid,
             "merchantId": document.data().merchantId,
             "payments": document.data().payments,
             "phoneNumber": document.data().phoneNumber,
             "token": document.data().token,
             "locahhosttoken": document.data().localhostTokens,
             "locations": document.data().locations
          }
           restoList[document.data().restoId] = rel;
           analytics.logEvent("getRestoList");
         } else {
           console.log('No matching documents.');
         }
        })
        resolve(restoList);
      })
      .catch((reason) => {
        reject(reason);
      });

  });
}

authentication.getUserRestoFavesList = async (userId) => {
  return new Promise(async (resolve, reject) => {
    if (!userId) {
      reject("userid can not be null");
      return;
    }

    const favesRef = firestore.collection('faves');
    const snapshot = await favesRef.where('userId', '==', userId).get();
    try{
      if (snapshot.empty) {
        console.log('No matching documents.');
        //reject("No faves found for user");
        return ("No faves found for user");
      }

      let favesArr = [];
      snapshot.forEach(async doc => {
        console.log(doc.id, '=>', doc.data());
        //let rn = await authentication.getRestoName(doc.data().restoId);
        //alert(rn);
        favesArr.push({
          faveId: doc.id,
          restoId: doc.data().restoId,
          restoName: doc.data().restoName,
          restotel: doc.data().restotel,
          locationId: doc.data().locationId,
          orderData: doc.data().order,
          userId: doc.data().userId
        })
      });

      resolve(favesArr);
    }
    catch(e){
      resolve("No faves found for user");
    }

  })
}

authentication.deleteUserFave = async (faveId, userId) => {
  console.log("deleteUserFaves")
  return new Promise((resolve, reject) => {
    if (!faveId) {
      reject(new Error("Square customerid and card id are required is required"));
      return;
    };
    if (!auth.currentUser.uid || (auth.currentUser.uid != userId)) {
      reject(new Error("Square customerid and card id are required is required"));
      return;
    };

    const userFaveRef = firestore.collection('faves').doc(faveId).delete()
    .catch((reason) => {
      reject(reason);
    })

    resolve();

  })
}

authentication.putUserFaves = async (req) => {
  //console.log("auth.putUserFaves" + req.body);
  const restoId = req.restoId;
  const userId = req.userId;
  const selectedItems = req.selectedItems;
  const submittedState = req.submitState;
  const locationId = req.locationId;
  const restotel = req.restotel;
  const restoName = req.restoName;

  let msgs = {"status": "success"};

  let faves = {
    userId: userId,
    restoName: restoName,
    restotel: restotel,
    restoId: restoId,
    locationId: locationId,
    order: selectedItems
  }
  //alert("faves = " + JSON.stringify(faves));
  let faveRef = firestore.collection('faves');
  await faveRef.add(faves)
  .then(function(data) {
    console.log('Updated User Faves successfully. ')

  })
  .catch(e=> { msgs = {"status": "ERROR"} })

}


authentication.getRestoName = (restoId) => {
  return new Promise(async (resolve, reject) => {
    if (!restoId) {
      reject("restoId can not be null");
      return;
    }
    //alert("get resto name " + restoId);
    const restoRef = firestore.collection("restos").doc(restoId);
    const doc = restoRef.get()
    .then((doc)=>{
      //alert(JSON.stringify(doc.data().resto));
      resolve(doc.data().resto);
    })
    .catch(e=> {
      let msgs = {"status": "ERROR"};
      reject(msgs);
    })
  });
}

authentication.getRestoPhone = (restoId) => {
  return new Promise(async (resolve, reject) => {
    if (!restoId) {
      reject("restoId can not be null");
      return;
    }
    //alert("get resto name " + restoId);
    const restoRef = firestore.collection("restos").doc(restoId);
    const doc = restoRef.get()
    .then((doc)=>{
      //alert(JSON.stringify(doc.data().resto));
      resolve(doc.data().phoneNumber);
    })
    .catch(e=> {
      let msgs = {"status": "ERROR"};
      reject(msgs);
    })
  });
}

authentication.getUserTip = () => {
  return new Promise(async (resolve, reject) => {
    if (!auth.currentTarget.uid) {
      reject("no user logged in");
      return;
    }

    //alert("get resto name " + restoId);
    const userRef = firestore.collection("users").doc(auth.currentUser.uid);
    const doc = userRef.get()
    .then((doc)=>{
      //alert(JSON.stringify(doc.data().resto));
      if(doc.data().tip){
        resolve(doc.data().tip);
      }
      else{
        resolve(0);
      }

    })
    .catch(e=> {
      let msgs = {"status": "ERROR"};
      reject(msgs);
    })
  });
}


authentication.getLocationsByResto = (restoId) => {
  return new Promise(async (resolve, reject) => {

    if (!restoId) {
      reject("restoId can not be null");
      return;
    }

    let locationList = {};

    const query =
      firestore
      .collection('locations').doc(restoId);
      const doc = await query.get();
      if (!doc.exists) {
        //console.log('No such document!');
        reject("locations not found");
      }
      else {
        //console.log('Document data:', doc.data());
        resolve(doc.data());
      }
  });
}


authentication.getRestoDetails = (restoId) => {
  return new Promise(async (resolve, reject) => {

    let restoList = {};
    const query =
      firestore
      .collection('restos').doc(restoId);
      const doc = await query.get();
      if (!doc.exists) {
        console.log('No such document!');
      }
      else {
        //console.log('Document data:', doc.data());
        const details = {
          restoId: restoId,
          payments: doc.data().payments,
          phoneNumber: doc.data().phoneNumber,
          restoName: doc.data().resto,
          status: doc.data().status,
          locations: doc.data().locations,
          listsid: doc.data().listsid,
          merchantId: doc.data().merchantId,
          csPhone: doc.data().csPhone,
          csEmail: doc.data().csEmail,
          authUrl: doc.data().authUrl,
          terms: doc.data().terms,
          privacy: doc.data().privacy,
          termsaccepted: doc.data().termsaccepted,
          nomtxtEnabled: doc.data().nomtxtEnabled,
          nomtxtEnabledDate: doc.data().nomtxtEnabledDate,
          prepTime: doc.data().prepTime

        }
        // alert(details);
        resolve(details);

      }

  });
}

authentication.setRestoTermsEnable = (restoId, data, checkDisabled) => {
  return new Promise(async (resolve, reject) => {
    if(data.terms === 'yes' || data.terms === 1){
      data.termsval = 1;
    }
    if(data.privacy === 'yes' || data.privacy === 1){
      data.privacyval = 1;
    }
    if(data.enableNomTxt === 'yes' || data.enableNomTxt === 1){
      data.enableNomTxt = 1;
      data.status = 'active';
    }
    else{
      data.status = 'ready';
    }
    let updateval;
    if(checkDisabled){
      updateval = {
        nomtxtEnabled: data.enableNomTxt,
        nomtxtEnabledDate: firebase.firestore.FieldValue.serverTimestamp(),
        status: data.status
      }

    }
    else{
      updateval = {
        terms: data.termsval,
        privacy: data.privacyval,
        termsaccepted: firebase.firestore.FieldValue.serverTimestamp(),
        nomtxtEnabled: data.enableNomTxt,
        nomtxtEnabledDate: firebase.firestore.FieldValue.serverTimestamp(),
        status: data.status
      };

    }
    const query =
      await firestore.collection('restos').doc(restoId).update(updateval)
      .then(resolve())
      .catch((e)=>{
        console.log('error while updating resto terms & privacy'+ e.message);
        reject("error updating resto terms & privacy" + e.message)
      })

  })
}

authentication.getFaveTipAmt = (userId) => {
  return new Promise(async (resolve, reject) => {
    const query =
      firestore.collection('users').doc(userId);
      const doc = await query.get();
      if (!doc.exists) {
        console.log('No such document!');
      }
      else {
        const tipDollars = {
          tipDollars: doc.data().tipDollars ? doc.data().tipDollars : 0
        };
        resolve(tipDollars);
      }

  }) //promise
}

authentication.setUserTip = (tipDollars, userId) => {
  return new Promise(async (resolve, reject) => {
    const query =
      await firestore.collection('users').doc(userId).update({
        tip: tipDollars * 100,
        tipDollars: tipDollars
      })
      .then(resolve())
      .catch(e=>console.log('error while updating tip for the user'))

  })

}

authentication.getRestoSquareTokenInfo = (restoId) => {
  return new Promise(async (resolve, reject) => {
    let details;
    const query =
      firestore
      .collection('restos').doc(restoId);
      const doc = await query.get();
      if (!doc.exists) {
        console.log('No such document!');
      }
      else {
        //console.log('Document data:', doc.data());
        if(nomtxtApi.getEnv() === 'production'){
          details = {
            restoId: restoId,
            token_expires_at: doc.data().token ? doc.data().token.token_expires_at : false,
            merchant_id: doc.data().token ? doc.data().token.merchant_id : false
          };
        }
        else{
          details = {
            restoId: restoId,
            token_expires_at: doc.data().localhostTokens ? doc.data().localhostTokens.token_expires_at : false,
            merchant_id: doc.data().localhostTokens ? doc.data().localhostTokens.merchant_id : false
          };
        }

        // alert(details);
        resolve(details);

      }

  });
}


authentication.getSquareCustomerId = (userId, restoId) => {
  return new Promise(async (resolve, reject) => {

    const custQuery = await firestore.collection('squareCustomer').where('userId', '==', userId).where('restoId', '==', restoId)
    custQuery.get().then(function(querySnapshot) {
      querySnapshot.forEach(function(doc){
        let squareCustomerId = doc.data().squareCustomerId;
        resolve(squareCustomerId);
      })
    })
    .catch((e)=>{
      console.log('error deleting the users card from the db')
      reject(e);
    })

  })
}


authentication.deleteUserCard = (cardId) => {
  return new Promise(async (resolve, reject) => {

    if (!cardId) {
      reject("card id cannot be null");
      return;
    }
    console.log("THIS IS THE DELETE CARD FUNCTION REPORTING" + cardId);

    var userCardQuery = firestore.collection('usercards').where('card_id', '==', cardId);
    userCardQuery.get().then(function(querySnapshot) {
      querySnapshot.forEach(function(doc){
        console.log('found card_id: ' + cardId + ' so i will delete it');
        doc.ref.delete();
        resolve();
      })
    })
    .catch((e)=>{
      console.log('error deleting the users card from the db')
      reject(e);
    })

  });
}

authentication.deleteRestoSquareToken = (restoId) => {
  return new Promise(async (resolve, reject) => {

    //const FieldValue = firestore.FieldValue;
    const restoRef = firestore.collection('restos').doc(restoId);
    const res = await restoRef.update({
    //  "token.access_token": firebase.firestore.FieldValue.delete(),
    //  "token.refresh_token":firebase.firestore.FieldValue.delete(),
    //  "token_expires_at": firebase.firestore.FieldValue.delete()
    "token": firebase.firestore.FieldValue.delete()
    })
    .catch((reason) => {
      reject(reason);
    });
    // alert(details);
    resolve(res);

  });
}

/*
  const removeEmpty = obj => {
    Object.values(obj).forEach(value => obj[value] == null && delete obj[value]);
  };
*/


authentication.setRestoDetails = (resto) => {
  return new Promise(async (resolve, reject) => {

    if(!resto){
      reject("can't update, payload missing");
    }

    //alert("inside setRestoDetails" + JSON.stringify(resto));
    //resto = {"restoName":"The Three Broomsticks","ccPhone":"12125551212","ccEmail":"a@aol.com","payments":"square"}


    const getFormattedHours = (value) => {
      let dateset = value.toString().split(',');
  //  alert("dateset = " + dateset[0]);
      let timeStart = nomtxtApi.getHoursFromDateString(dateset[0])
      //let timeStart = spacetime(dateset[0]);
  //  alert("ts = " + timeStart);
      let timeEnd = nomtxtApi.getHoursFromDateString(dateset[1])
      //let timeEnd = spacetime(dateset[1]);
      let hoursDay = { "open": timeStart, "close": timeEnd }
      //let hoursDay = []
      //alert(JSON.stringify(hoursDay));
      return hoursDay;
    }


    let restoHours = [];
    //alert("this is the val that needs checking" + JSON.stringify(resto.sunHours));
    if(resto.sunHours && resto.sunHours !== undefined ){
      let obj = getFormattedHours(resto.sunHours);
      restoHours.push({
        day: "sun",
        hours: obj
      });
      await authentication.setRestoHours(resto.restoId, {day: "sun", hours: obj});
    }

    if(resto.monHours && resto.monHours !== undefined ){
      let obj = await getFormattedHours(resto.monHours);
      //alert("setRestoDetails obj = " + JSON.stringify(obj));
      restoHours.push({
        day: "mon",
        hours: obj
      });
      authentication.setRestoHours(resto.restoId, {day: "mon", hours: obj});

    }

    if(resto.tueHours && resto.tueHours !== undefined ){
      let obj = await getFormattedHours(resto.tueHours);
      restoHours.push({
        day: "tue",
        hours: obj
      });
      authentication.setRestoHours(resto.restoId, {day: "tue", hours: obj});

    }

    if(resto.wedHours && resto.wedHours !== undefined ){
      let obj = await getFormattedHours(resto.wedHours);
      restoHours.push({
        day: "wed",
        hours: obj
      })
      authentication.setRestoHours(resto.restoId, {day: "wed", hours: obj});

    }

    if(resto.thuHours && resto.thuHours !== undefined){
      let obj = await getFormattedHours(resto.thuHours);
      restoHours.push({
        day: "thu",
        hours: obj
      })
      authentication.setRestoHours(resto.restoId, {day: "thu", hours: obj});

    }

    if(resto.friHours && resto.friHours !== undefined){
      let obj = await getFormattedHours(resto.thuHours);
      restoHours.push({
        day: "fri",
        hours: obj
      })
      authentication.setRestoHours(resto.restoId, {day: "fri", hours: obj});

    }

    if(resto.satHours && resto.satHours !== undefined){
      let obj = await getFormattedHours(resto.satHours);
      restoHours.push({
        day: "sat",
        hours: obj
      })
      authentication.setRestoHours(resto.restoId, {day: "sat", hours: obj});

    }

//udpate array right before db update = [{"restoHours.thu":{"open":"03:00","close":"20:00"}}]
// and restoHours = [{"day":"thu","hours":{"open":"03:00","close":"20:00"}}]

    //alert("udpate array right before db update = " + JSON.stringify(thing) +" and restoHours = " + JSON.stringify(restoHours));


    //alert("right before update: " + JSON.stringify(resto));
    const restoDocumentReference = firestore.collection("restos").doc(resto.restoId);
    restoDocumentReference
      .update({
        resto: resto.restoName,
        csPhone: resto.csPhone,
        csEmail: resto.csEmail,
        payments: "square",
        prepTime: resto.prepTime,
        restoId: resto.restoId,
        profile: resto.profile,
      })
      .then((value) => {
        analytics.logEvent("setRestoDetails");

        resolve(value);
      })
      .catch((reason) => {
        reject(reason);
      });

  });
}


authentication.setRestoHours = (restoId, hoursDay) => {
  return new Promise(async (resolve, reject) => {

    //alert("hoursDay = " + JSON.stringify(hoursDay));

    if(!restoId){
      //alert("restoId missing can't update hours")
      reject("can't update, restoId missing");
    }

    const restoDocumentReference = firestore.collection("restohours").doc(restoId);

    let key = hoursDay.day;

    restoDocumentReference
      .set({
        [key]: hoursDay.hours
      }, {merge: true})
      .then((value) => {
        analytics.logEvent("setRestoHours");

        resolve(value);
      })
      .catch((reason) => {
        reject(reason);
      });

  });
}

authentication.getRestoHours = (restoId) => {
  return new Promise(async (resolve, reject) => {
    const restoHoursRef = firestore.collection("restohours").doc(restoId);
    let doc = await restoHoursRef.get()
    if (!doc.exists) {
      reject('No such document!');
      return;
    }
    else {
      //console.log('Document data:', doc.data());
      // alert('Document data:', doc.data());
      let d = doc.data();
      resolve(d);
    }
    return;

  });
}

authentication.setRestoLocations = (restoId, locations) => {
  return new Promise(async (resolve, reject) => {

    //alert("hoursDay = " + JSON.stringify(hoursDay));

    if(!restoId){
      reject("can't update, restoId missing");
    }

    if(!locations){
      reject("can't update, locations missing");
    }

    const list = locations.join();

    const locationsDocRef = firestore.collection("locations").doc(restoId);

    locationsDocRef
      .set({
        locations: list
      }, {merge: true})
      .then((value) => {
        analytics.logEvent("saveEnabledLocations");

        resolve(value);
      })
      .catch((reason) => {
        reject(reason);
      });

  });
}


authentication.getRestoHours = (restoId) => {
  return new Promise(async (resolve, reject) => {

    let restoList = {};
    const query =
      firestore
      .collection('restohours').doc(restoId);
      const doc = await query.get();
      if (!doc.exists) {
        console.log('No such document!');
        return(resolve({}));
      }
      else {
        //console.log('Document data:', doc.data());
        // alert('Document data:', doc.data());
        let d = doc.data();
        let i = doc.id;
        let payload =   ({
            id: i,
            data: d
          });
          resolve(payload);
        }
        // alert(details);
      });

  }


authentication.updateRestoStatus = (restoId, status) => {
  return new Promise(async (resolve, reject) => {

    if(!restoId){
      reject("can't update, restoId missing");
    }
    if(!status){
      reject("can't update, status Value missing");
    }

    //alert("resto = "+ JSON.stringify(resto) + " payload = " + JSON.stringify(payload))

    const restoDocumentReference = firestore.collection("restos").doc(restoId);

    restoDocumentReference
      .update({
        status: status
      })
      .then((value) => {
        analytics.logEvent("updateRestoStatus");

        resolve(value);
      })
      .catch((reason) => {
        reject(reason);
      });

  });
}

authentication.smsVerified = (isVerified, userId) =>{
  const userRef = firestore.collection('users').doc(userId);
  const result = userRef.update({ smsVerified: isVerified })
  return;
}

authentication.getUserData = (userId) => {
  return new Promise(async (resolve, reject) => {

    if(!userId){
      return null;
    }

    const userRef = firestore.collection('users').doc(userId);
    const userDoc = await userRef.get();
    if(!userDoc.exists){
      reject('no user found')
    }
    else{
      resolve(userDoc.data());
    }
    return;

  })
}

authentication.getSecurityRating = (user, userData) => {
  if (!user || !user.metadata) {
    return null;
  }

  let creationTime = user.metadata.creationTime;

  if (!creationTime) {
    return null;
  }

  creationTime = moment(creationTime);

  let securityRating = 0;

  if (userData && userData.lastPasswordChange) {
    let lastPasswordChange = userData.lastPasswordChange;

    if (lastPasswordChange) {
      lastPasswordChange = moment(lastPasswordChange.toDate());

      if (creationTime.diff(lastPasswordChange, "days") >= 365.242199) {
        securityRating = 50;
      } else {
        securityRating = 100;
      }
    }
  } else {
    if (moment().diff(creationTime, "days") >= 365.242199) {
      securityRating = 50;
    } else {
      securityRating = 100;
    }
  }

  return securityRating;
};

export default authentication;
