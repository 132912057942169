import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useForm } from 'react-hook-form';
import authentication from "../../services/authentication";
import { Fab, Box, Typography, Paper, List, ListItem, Grid, Card, TextField, Button } from "@material-ui/core";

const btnStyle = {
    width: 300,
    height: 50,
    marginTop: 20,
    marginBottom: 50,
    paddingTop: 20,
    paddingBottom: 20,
    fontWeight: 600,
    backgroundColor: '#fac30c',
    marginLeft: 100,
    marginRight: 150

};

export default function HookFormAsync(props) {
  //alert(JSON.stringify(props));
  const userData = props.userData;
  const user = props.user;
  //const restoId = userData.restoId;
  const { register, handleSubmit, reset } = useForm();
  const onSumbit = data => {
    //alert(JSON.stringify(data));
    authentication
    .setUserTip(data.tipAmt, props.userId)
    .then(()=>{
      setTipAmt({tip: data.tipAmt});
    })
    .catch((e) => {
      //alert("ERROR in submit RestoConfig" + e.message);
    });
  };

  const [count, setCount] = useState(0);
  const [tipAmt, setTipAmt] = useState('');
  // const [payments, setPayments] = useState([{square: "square"}]);
  //alert(userData);
  useEffect( () => {
    //console.log(JSON.stringify("USERDATA "+props.userData));
    //alert(JSON.stringify(userData));

      authentication
        .getFaveTipAmt(props.userId)
        .then((value) => {
          setTipAmt({ tip: value.tipDollars  });
          //alert(JSON.stringify(value));
          reset({
            tipAmt: value.tipDollars
          });
        })
        .catch((reason) => {
          console.log("ERROR getting pointer =" + reason);
        })

    // you can do async server request and fill up form
    /*
    setTimeout(() => {
      reset({
        restoName: "gallonhat",
        restoId: restoDetails.restoName,
      });
    }, 0);
    */
  }, [reset]);

  return (
    <form onSubmit={handleSubmit(onSumbit)}>
      <Typography variant="h6">Add Tip</Typography>
      <Typography variant="body2">Saved tip: $ {tipAmt.tip}</Typography>
      <input name="tipAmt" ref={register} className="tipInput" />

      <Button type="submit" variant="contained" color="primary" style={btnStyle}>Add Tip</Button>

    </form>
  );
}
