import React, { Component } from "react";
import firebase, { functions } from "../../firebase";
import { makeStyles } from '@material-ui/core/styles';
import { Link } from "react-router-dom";
import nomtxtApi from "../../services/nomtxt";
import authentication from "../../services/authentication";
import styled, { css } from 'styled-components';
import axios from 'axios';
/*
import axios from "axios";

import { Home as HomeIcon } from "@material-ui/icons";
// import { ReactComponent as NotFoundIllustration } from "../../illustrations/not-found.svg";
*/
import{ Input, Accordion, AccordionSection, CheckboxGroup   } from 'react-rainbow-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV, faPlus, faTrashAlt, faTrash, faArrowRight } from '@fortawesome/free-solid-svg-icons';

import { Fab, Button, Box, Typography, Paper, Card, CardContent, CardActionArea, ListItem, ListItemText, Grid } from "@material-ui/core";
import spacetime from 'spacetime'

import { withRouter } from 'react-router-dom';

const containerPaperStyles = { width: 500, maxWidth: 750, marginTop: 10, };
const accordianHeaderStyle = { fontWeight: 800 }
const checkboxStyles = { fontWeight: 400  }
const initialState = {
  "youregonnacarrythatweight":"alongtime"
};

const lFaves = { maxWidth: 555 };
const btnSuccessStyle = { maxHeight: 35, height: 35, width: 255, marginBottom: 15, marginTop: 15 };
const btnDestructiveStyle = { maxHeight: 35, height: 35, width: 170, marginBottom: 15, marginTop: 15 };
const containerStyles = {
    maxWidth: 300,
};

class Onboard1 extends Component {

    constructor(props) {
      super(props);
      this.state = {
        restos: [{
          restoId: 321,
          restoName: "~-Loading-~"
        }],
        locations: [{
          locationId: 123,
          locationName: "~-Please Select Resto-~"
        }],
        btnDisabled: true,
        selectedLocationId: ''
      };
    };

    restoListData = () => {
       authentication
         .getRestoList()
         .then((value) => {
           //console.log("resto list =" + JSON.stringify(value));
           //let cardso = Object.values(value)
           //alert(JSON.stringify(value));
           let rawval = Object.values(value);


           let restolist = rawval.map(function(resto){
             return({
               restoId: resto.restoId,
               restoName: resto.resto
             })
           })
           //alert(JSON.stringify(restolist));
           this.setState({ restos: restolist });
           return restolist;
         })
         .catch((reason) => {
           //console.log("ERROR getting pointer =" + reason);
         })
     };


     handleRestoChange = async (event) => {
       //alert("in restoListData now target val: " + event.target.value);
       let restoId = event.target.value;





         this.setState({btnDisabled: false});
         this.setState({selectedRestoId: restoId})
     }



     getLink = (path) => {
       if(!this.props.user.uid){
         return "/";
       } else{
         return path;
       }
     }



  render(){
    const { restos } = this.state;
    let restosList = restos.length > 0
  		&& restos.map((item, i) => {
  		return (
  			<option key={i} value={item.restoId}>{item.restoName}</option>
  		)
  	}, this);

      const {btnDisabled} = this.state;
      const {selectedRestoId} = this.state;

    return(
      <>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        style={{ minHeight: '100vh', marginTop: 15 }}
       >
         <Grid item xs={3}>
         <Card>
           <CardContent>
          <Typography variant="h6">Select a Restaurant. </Typography>

            <div>
        			<select
                onChange={this.handleRestoChange}
              >
        				{restosList}
        			</select>
            </div>
              </CardContent>
              <CardActionArea>
                <Button to={`/user/onboard/two/?r=${selectedRestoId}`} component={Link} disabled={btnDisabled}>Continue</Button>


              </CardActionArea>
            </Card>

        </Grid>
      </Grid>
      </>
    );
  }


  componentDidMount(){
    this.restoListData();
  }


}

export default withRouter(Onboard1);
