import React, { useState, useEffect } from "react";

import { useParams, Link } from "react-router-dom";

import { Grid, Fab, Box, Typography } from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";

import { Refresh as RefreshIcon, Home as HomeIcon } from "@material-ui/icons";

import { firestore } from "../../firebase";

import EmptyState from "../EmptyState";

import Loader from "../Loader";


import { ReactComponent as ErrorIllustration } from "../../illustrations/error.svg";
import { ReactComponent as NoDataIllustration } from "../../illustrations/no-data.svg";

const useStyles = makeStyles({
  grid: {
    margin: 0,
    width: "100%",
  },
});

function UserPage() {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [userData, setUserData] = useState(true);
  const [error, setError] = useState(null);
  const { userId } = useParams();
  const classes = useStyles();


/*
  useEffect(() => {
    return firestore
      .collection("users")
      .doc(userId)
      .onSnapshot(
        (snapshot) => {
          setLoading(false);
          setUser(snapshot.data());
          console.log("USER DATA IS: " + JSON.stringify(snapshot.data()));

        },
        (error) => {
          setLoading(false);
          setError(error);
        }
      );
  }, [userId]);

*/
   let userRecord = {};
  const getUserRecord = () => {
    return userRecord;
  }

  const setUserRecord = (ur) => {
    userRecord = ur;
  }

  function replacer(key, value) {
    return value.replace(/[^\w\s]/gi, '');
  }

  useEffect(() => {
    let user = {};
    let userArray = [];
    let users = firestore
      .collection("users")
      .doc(userId)
      .onSnapshot(
        (snapshot) => {
          setLoading(false);
          setUser(snapshot.data());
          user["user"] = snapshot.data();
          //console.log("USER DATA IS: " + JSON.parse(snapshot.data()) );
          //console.log("resto details: " + JSON.stringify(snapshot.data().restos));
          //let foo = snapshot.ref.collection("restos").get();
          //let foo = snapshot.get(snapshot.id).collection("restos").get();
          //console.log("FOO IS: " + JSON.stringify(foo));
          //console.log(snapshot.id);
          //foo.forEach(doc => {
          //  console.log(doc.id, '=>', doc.data());
          //});
          //snapshot.forEach(doc => {
          //  console.log(doc.id, '=>', doc.data());
          //})
        },
        (error) => {
          setLoading(false);
          setError(error);
        }
      );


      return;
  }, [userId]);

  if (error) {
    return (
      <EmptyState
        image={<ErrorIllustration />}
        title="Couldn’t retrieve user"
        description="Something went wrong when trying to retrieve the requested user"
        button={
          <Fab
            variant="extended"
            color="primary"
            onClick={() => window.location.reload()}
          >
            <Box clone mr={1}>
              <RefreshIcon />
            </Box>
            Retry
          </Fab>
        }
      />
    );
  }

  if (loading) {
    return <Loader />;
  }

  if (!user) {
    return (
      <EmptyState
        image={<NoDataIllustration />}
        title="User doesn’t exist"
        description="The requested user doesn’t exist"
        button={
          <Fab variant="extended" color="primary" component={Link} to="/">
            <Box clone mr={1}>
              <HomeIcon />
            </Box>
            Home
          </Fab>
        }
      />
    );
  }

  return (
    <Grid className={classes.grid} container justify="center" spacing={5}>
      <Grid item xs={6}>
        <Typography variant="h6"></Typography>
      </Grid>
    </Grid>
  );
}

export default UserPage;
