import React, { Component } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Link } from "react-router-dom";
import authentication from "../../services/authentication";
import nomtxtApi from "../../services/nomtxt";

import {Typography, Paper, Grid, Button as LinkButton  } from "@material-ui/core";

import { Home as HomeIcon } from "@material-ui/icons";
import { withRouter } from 'react-router-dom';
// import { ReactComponent as NotFoundIllustration } from "../../illustrations/not-found.svg";
import{ Button, ButtonIcon, Input, ProgressIndicator, ProgressStep } from 'react-rainbow-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV, faPlus, faTrashAlt, faTrash } from '@fortawesome/free-solid-svg-icons';
import firebase, { functions } from "../../firebase";
import styled from 'styled-components';
import RestoProgress from "./RestoProgress.js"
import RestoTermsCheck from "../Forms/RestoTermsCheck.js";



import {
  Favorite as FavoriteIcon,
  Delete as DeleteIcon
} from "@material-ui/icons";

// Load the core build.
var _ = require('lodash/core');

const inputStyles = {
    width: 60,
    marginRight: 2,
};

const initialState = {
  "ilike":"bigbutts",
  "currentStep": 'restoProfile'
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '70%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  faveslist: {
    width: '70%',
    maxWidth: 560,
    backgroundColor: theme.palette.background.paper,
  },
}));

const lFaves = { width: 550, maxWidth: 555, marginLeft: 30, marginTop: 15, paddingLeft: 15, paddingRight: 15, paddingBottom: 15 };
const progressStyles = { paddingBottom: 20 }
const progressText = { paddingTop: 40, paddingBottom: 20 }
const linkBtnStyle = { marginTop: 20 }
const stepNames = ['step-1', 'step-2', 'step-3', 'step-4', 'step-5'];

const steps = ['first', 'second', 'third', 'fourth', 'fifth'];


class RestoEnabled extends Component {

    constructor(props) {
      super(props);
      this.state = {
        currentStepIndex: 1,
        hasPaymentsError: false,
      };
      this.handleNextClick = this.handleNextClick.bind(this);
      this.handleBackClick = this.handleBackClick.bind(this);
    };

    handleNextClick() {
        const { currentStepIndex } = this.state;
        if (currentStepIndex < stepNames.length - 1) {
            const nextStepIndex = currentStepIndex + 1;
            this.setState({ currentStepIndex: nextStepIndex });
        }
    }

    handleBackClick() {
        const { currentStepIndex } = this.state;
        if (currentStepIndex > 0) {
            const previewStepIndex = currentStepIndex - 1;
            this.setState({ currentStepIndex: previewStepIndex });
        }
    }

    isNextDisabled() {
        const { currentStepIndex } = this.state;
        if (currentStepIndex < stepNames.length - 1 && currentStepIndex >= 0) {
            return false;
        }
        return true;
    }

    isBackDisabled() {
        const { currentStepIndex } = this.state;
        if (currentStepIndex > 0 && currentStepIndex < stepNames.length) {
            return false;
        }
        return true;
    }




  render() {
    const { currentStepIndex } = this.state;
    const { values } = this.state;

    return (
      <React.Fragment>
      <Grid
        container
        spacing={2}
        direction="column"
        alignItems="center"
       >
       <Grid item s={12}>
          <Paper style={lFaves}>

          <div style={progressStyles} className="rainbow-m-bottom_large rainbow-m-top_xx-large rainbow-p-bottom_large">
            <RestoProgress currentIndex="3" user={this.props.user} />
          </div>

          <Typography variant="h5">Setup Status</Typography>
            <p>Lookit you! You're ready to go!</p>  <br/>

            <Typography variant="body1">
              Toggle below to enable NomTxt!
            </Typography>

            <RestoTermsCheck userData={this.props.userData}/>

            <Grid
              container
              spacing={2}
              direction="column"
              alignItems="center"
             >
            <LinkButton to={`/resto/billing`} component={Link} disabled={this.state.btnDisabled} variant="contained" color="primary" style={linkBtnStyle}>Configure Billing Options</LinkButton>
            </Grid>


          </Paper>
        </Grid>
        </Grid>


      </React.Fragment>
    );
  }

  componentDidMount(){
    //csPhone and csEmail (resto) for profile also resto hours set (restohours)
    //this.findRestoStatus();

    // payments: valid token for squareup (resto)

    //msg phone - have phoneNumber stored in (resto)

    // then set resto.status = active on "enabled"

    //market link to some media material to spread the word.

    // make click to order customized business cards to hand out .

    // make click to print out QR code poster

  }


}

export default withRouter(RestoEnabled);
