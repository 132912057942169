import React, { Component, Form } from "react";

import PropTypes from "prop-types";

import { BrowserRouter, Switch, Redirect, Route } from "react-router-dom";

import HomePage from "../HomePage";
import AdminPage from "../AdminPage";
import UserPage from "../UserPage";
import NotFoundPage from "../NotFoundPage";
//import OrdersList from "../OrdersList/OrdersList";
import SettingsPage from "../SettingsPage";
import RestoList from "../RestoList";
//import UserFavesList from "../UserFavesList";
import UserFaves from "../UserPage/UserFaves"
import AboutPage from "../AboutPage"
import SquareFormTab from "../SquareFormTab/SquareFormTab"
import SqPayFormTest from "../SqPayForm/SqPayFormTest"
//import RestoConfig from "../RestoConfig";
//import RestoAdmin from "../DataDrivenForms/RestoAdmin"
import HookFormAsyncAnt from "../Forms/HookFormAsyncAnt"
import RestoConfig from "../Forms/RestoConfig"
import SquareAuth from "../RestoConfig/SquareAuth"
import Setup from "../RestoConfig/Setup"
import Messaging from "../RestoConfig/Messaging"
import Onboard1 from "../UserPage/Onboard1"
import Onboard2 from "../UserPage/Onboard2"
import UserCardPage from "../UserCard/UserCardPage.js"
import Teststuff from "../AboutPage/Tests.js"
import RestoEnabled from "../RestoConfig/RestoEnabled.js"
import RestoStatus from "../RestoConfig/RestoStatus.js"
import RestoBilling from "../RestoConfig/RestoBilling.js"
import SmsVerify from "../Forms/SmsVerify.js"




class Router extends Component {
  render() {
    // Properties
    const { user, roles, bar, userData } = this.props;
    // Functions
    const { openSnackbar } = this.props;

    return (
      <BrowserRouter basename={process.env.REACT_APP_BASENAME}>
        {bar}

        <Switch>
          <Route path="/" exact>
            <HomePage user={user} openSnackbar={openSnackbar} />
          </Route>

          <Route path="/admin/:restoId" exact>
            <HomePage user={user} openSnackbar={openSnackbar} />
          </Route>

          <Route path="/admin:restoId/:setup" exact>
            <HomePage user={user} openSnackbar={openSnackbar} />
          </Route>

          <Route path="/admin">
            {user && roles.includes("admin") ? (
              <AdminPage />
            ) : (
              <Redirect to="/" />
            )}
          </Route>

          <Route path="/user/:userId" exact>
            {user ? <UserPage user={user} userData={userData} /> : <Redirect to="/" />}
          </Route>

          <Route path="/about">
            {user ? <AboutPage user={user} userData={userData} component={AboutPage} /> : <Redirect to="/" />}
          </Route>

          <Route path="/teststuff">
            {user ? <Teststuff user={user} userData={userData} component={Teststuff} /> : <Redirect to="/" />}
          </Route>

          <Route path="/settings/" exact>
            {user ? <SettingsPage user={user} userData={userData} component={SettingsPage} /> : <Redirect to="/" /> }
          </Route>

          <Route path="/settings/verify/sms" exact>
            {user ? <SmsVerify user={user} userData={userData} component={SmsVerify} /> : <Redirect to="/" /> }
          </Route>

          <Route path="/user/onboard/1" exact>
            {user ? <Onboard1 user={user} userData={userData} component={Onboard1}  /> : <Redirect to="/" />}
          </Route>

          <Route path="/user/onboard/two" exact>
            {user ? <Onboard2 user={user} userData={userData} component={Onboard2}  /> : <Redirect to="/" />}
          </Route>

          <Route path="/user/payments/storedCards" exact>
            {user ? <UserCardPage user={user} userData={userData} component={UserCardPage}  /> : <Redirect to="/" />}
          </Route>

          <Route path="/user/payments/square" exact>
            {user ? <SquareFormTab user={user} userData={userData} component={SquareFormTab}  /> : <Redirect to="/" />}
          </Route>

          <Route path="/user/payments/test" exact>
            {user ? <SqPayFormTest user={user} userData={userData} component={SqPayFormTest}  /> : <Redirect to="/" />}
          </Route>

          <Route path="/setup/faves">
            {user ? <RestoList user={user} userData={userData} component={RestoList}  /> : <Redirect to="/" />}
          </Route>

          <Route path="/faves" exact>
            {user ? <UserFaves user={user} userData={userData} component={UserFaves} /> : <Redirect to="/" />}
          </Route>

          <Route path="/resto/setup" exact>
            { (userData && userData.userType=="resto-admin") ? <RestoConfig user={user} userData={userData} component={RestoConfig} /> : <Redirect to="/" /> }
          </Route>

          <Route path="/resto/setup/payments/square/2" >
            { (userData && userData.userType=="resto-admin") ? <SquareAuth user={user} userData={userData} component={SquareAuth}/> : <Redirect to="/" /> }
          </Route>

          <Route path="/resto/setup/payments/square/1" >
            { (userData && userData.userType=="resto-admin") ? <Setup user={user} userData={userData} component={Setup}/> : <Redirect to="/" /> }
          </Route>

          <Route path="/resto/profile">
            { (userData && userData.userType=="resto-admin") ? <RestoConfig user={user} userData={userData} component={RestoConfig} /> : <Redirect to="/" /> }
          </Route>

          <Route path="/resto/enable">
            { (userData && userData.userType=="resto-admin") ? <RestoEnabled user={user} userData={userData} component={RestoEnabled} /> : <Redirect to="/" /> }
          </Route>

          <Route path="/resto/billing">
            { (userData && userData.userType=="resto-admin") ? <RestoBilling user={user} userData={userData} component={RestoBilling} /> : <Redirect to="/" /> }
          </Route>

          <Route path="/resto/setup/messaging">
            { (userData && userData.userType=="resto-admin") ? <Messaging user={user} userData={userData} /> : <Redirect to="/" /> }
          </Route>

          <Route path="/resto/status">
            { (userData && userData.userType=="resto-admin") ? <RestoStatus user={user} userData={userData} component={RestoStatus} /> : <Redirect to="/" /> }
          </Route>

          <Route path="/cat">
            { (userData && userData.userType=="resto-admin") ? <HookFormAsyncAnt user={user} userData={userData} /> : <Redirect to="/" /> }
          </Route>




          <Route path="/setup" component={RestoList}/>


          <Route>
            <NotFoundPage />
          </Route>
        </Switch>
      </BrowserRouter>
    );
  }
}

Router.propTypes = {
  // Properties
  user: PropTypes.object,
  userData: PropTypes.object,
  roles: PropTypes.array.isRequired,
  bar: PropTypes.element,

  // Functions
  openSnackbar: PropTypes.func.isRequired,
};

export default Router;
