import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useForm } from 'react-hook-form';
import authentication from "../../services/authentication";
import { Fab, Box, Typography, Paper, Button, Grid, Card, TextField } from "@material-ui/core";
const promoResult = { display: 'none' }
const promoResultVisible = { display: 'block' }
const promoResultsHidden = { display: 'none' }

const btnStyle = {
    width: 300,
    height: 50,
    marginTop: 20,
    marginBottom: 50,
    paddingTop: 20,
    paddingBottom: 20,
    fontWeight: 600,
    backgroundColor: '#fac30c',
    marginLeft: 100,
    marginRight: 150

};

const initialState = {
  promoCodeDisabled:false
};


export default function PromoCode(props) {
  //const restoId = userData.restoId;
  const { register, handleSubmit, reset } = useForm();
  const onSumbit = data => {
    //alert(JSON.stringify(data));
    authentication
    .setNomtxtPromo(data.promoCode, props.userId, props.restoId)
    .then((val)=>{
      alert(JSON.stringify(val));
      setPromoResult("Promo code applied. Enjoy NomTxt free for " + '' + " days.")
    })
    .catch((e) => {
      //alert("ERROR in submit RestoConfig" + e.message);
      setPromoResult("Promo code not applied.");
      console.log(e.message);
    });
  };

  const [count, setCount] = useState(0);
  const [promoCode, setPromoCode] = useState('');
  const [promoCount, setPromoCount] = useState('');
  const [promoDate, setPromoDate] = useState('');
  const [promoCodeDisabled, setPromoCodeDisabled] = useState(false);
  const [promoCodeResultsDisplay, setPromoCodeResultsDisplay] = useState({display: 'none'})
  const [promoResult, setPromoResult] = useState('');
  // const [payments, setPayments] = useState([{square: "square"}]);
  //alert(userData);
  useEffect( () => {
    //console.log(JSON.stringify("USERDATA "+props.userData));
    //alert(JSON.stringify(userData));

      authentication
        .getNomtxtPromo(props.userId)
        .then((value) => {
          //alert("useEffect - > "+ JSON.stringify(value));
          setPromoCode(value.data.promo.promoCode);
          if(!value.data.promo){
            setPromoResult(value.data.msg);
            setPromoCodeDisabled(true);
            promoCodeResultsDisplay({display: 'block'})
          } else {
            setPromoCodeDisabled(false);
            setPromoResult(value.data.promo.result)
            promoCodeResultsDisplay({display: 'none'})

          }
          setPromoDate(value.data.promo.promoAppliedDate)
          let count = value.data.promo.promoCount ? value.data.promo.promoCount + 1 : 1;
          setPromoCount(count)
          //alert(JSON.stringify(value));
          reset({
            promoCode: value.data.promo.promoCode
          });
        })
        .catch((reason) => {
          console.log("ERROR getting pointer =" + reason);
        })

        if(count > 1){
          setPromoResult("Promo codes can only be applied once. Promo has already been applied to this account. Additional promotion period is not available.  ")
          promoCodeResultsDisplay({display: 'block'})
          setPromoCodeDisabled(true);
        }

    // you can do async server request and fill up form
    /*
    setTimeout(() => {
      reset({
        restoName: "gallonhat",
        restoId: restoDetails.restoName,
      });
    }, 0);
    */
  }, [reset]);

  return (
    <form onSubmit={handleSubmit(onSumbit)}>
      <Typography variant="h6">Promo Code</Typography>
      <input name="promoCode" ref={register} className="tipInput" disabled={promoCodeDisabled} />
      <div style={promoCodeResultsDisplay}>
        <Typography>{promoResult}</Typography>
      </div>
      <Button type="submit" variant="contained" color="primary" style={btnStyle}>Apply Promo</Button>
    </form>
  );
}
