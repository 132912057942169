import React, { Component } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Link, withRouter } from "react-router-dom";
import nomtxtApi from "../../services/nomtxt";
import authentication from "../../services/authentication";
/*
import axios from "axios";

import { Home as HomeIcon } from "@material-ui/icons";
import { withRouter } from 'react-router-dom';
// import { ReactComponent as NotFoundIllustration } from "../../illustrations/not-found.svg";
*/

import { Fab, Box, Typography, Paper, List, ListItem, ListItemAvatar, Avatar, ListItemText, Grid, GridList, Card, CardContent, CardActionArea } from "@material-ui/core";
import{ Button, ButtonIcon, Input,  } from 'react-rainbow-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faCheck, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import RestoProgress from "./RestoProgress.js"
import "./restoconfig.css";

const btnSuccessStyle = { maxHeight: 35, height: 35, width: 255, marginBottom: 15, marginTop: 15 };



const containerPaperStyles = { width: 500, maxWidth: 750, marginLeft: 20, marginTop: 10 };

const initialState = {
};

const lFaves = { maxWidth: 555 };


class Setup extends Component {

    constructor(props) {
      super(props);
      this.state = {
        restoDetails: {},
        restotel: '',
        restoName: '',
        userRestos: ''
      };

    };



    getRestoDetails = async () => {
      let resto =  await authentication
        .getRestoDetails(this.props.userData.restoId)
        .then((value) => {
          //alert(JSON.stringify(value));
          this.setState({restoDetails: value});
          this.setState({restotel: value.phoneNumber});
          this.setState({authUrl: value.authUrl});
        })
        .catch((reason) => {
          console.log("ERROR getting pointer =" + reason);
        });

    }


    createSquareAuthUrl = async () => {


      let tokeneke = await nomtxtApi.saltToken();

      //history.push('/resto/setup/payments/square/2', { fwdState: this.state })
      //window.location = this.state.authUrl;
      const nomtxtMethod = process.env.REACT_APP_NODE_ENV === 'production' ? "/delegate/request_token/" : "/delegate/sandbox/request_token/";

      const endpoint = nomtxtApi.getApiUrl() + nomtxtMethod + this.props.userData.restoId + "/?a=" + tokeneke + "&u=" + this.props.user.uid;
      this.setState({authUrl: endpoint});
    }




     proceedButtonClick(){
      const { history } = this.props;
      //alert(this.state.authUrl);
      //alert(nomtxtApi.getEnv);

      let endpoint = this.state.authUrl;

      window.location.replace(endpoint);

      //alert("ok");

    }



  render() {
    console.log(JSON.stringify("user restos ARE:" + JSON.stringify(this.state.userRestos)) + " or "+ this.props.userData.restoId);


    return (
      <React.Fragment>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
       >
       <Grid item xs={5}>

      <Paper style={containerPaperStyles}>
      <RestoProgress currentIndex="1" user={this.props.user} />
        <Typography variant="h4">Payment Providers</Typography>
        <Typography variant="body2">Authorize NomTxt to integrate with your Square Payments system.
          </Typography>

        <Typography variant="body1" className="classy">The next steps will guide you through the process to Authorize
          NomTxt to be able to create orders, payments and customers with Square on your behalf.

        </Typography>

        <Button variant="brand" style={btnSuccessStyle} onClick={this.proceedButtonClick.bind(this)} >
            Get Started
            <FontAwesomeIcon icon={faArrowRight} className="rainbow-m-left_medium" />
        </Button>

        <Typography variant="body1" className="classy">NomTxt Fees are minimal. There is a low monthly fee and ultra low one half percent
        app fee charged per pick-up transaction. We hope to add delivery orders soon!
        </Typography>
        <Typography variant="body1" className="classy">
           Please return to this page at any time to review, renew, or cancel this Authorization.
        </Typography>
      </Paper>
      </Grid>
      </Grid>
      </React.Fragment>
    );
  }

  componentDidMount(){
    this.createSquareAuthUrl();
    this.getRestoDetails();
    //this.setNewMessagingPhone();



  }


}

export default withRouter(Setup);
