import React, { Component } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Link } from "react-router-dom";
import nomtxtApi from "../../services/nomtxt";
import authentication from "../../services/authentication";
import styled, { css } from 'styled-components';

/*
import axios from "axios";

import { Home as HomeIcon } from "@material-ui/icons";
// import { ReactComponent as NotFoundIllustration } from "../../illustrations/not-found.svg";
*/
import{ Button, ButtonIcon, Input, Accordion, AccordionSection, CheckboxGroup   } from 'react-rainbow-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV, faPlus, faTrashAlt, faTrash, faArrowRight } from '@fortawesome/free-solid-svg-icons';

import { Fab, Box, Typography, Paper, Card, CardContent, CardActionArea, ListItem, ListItemText } from "@material-ui/core";
import spacetime from 'spacetime'

import { withRouter } from 'react-router-dom';



const containerPaperStyles = { width: 500, maxWidth: 750, marginLeft: 20, marginTop: 10 };
const accordianHeaderStyle = { fontWeight: 800 }
const checkboxStyles = { fontWeight: 400  }
const initialState = {
  "youregonnacarrythatweight":"alongtime"
};

const lFaves = { maxWidth: 555 };
const btnSuccessStyle = { maxHeight: 35, height: 35, width: 255, marginBottom: 15, marginTop: 15 };
const btnDestructiveStyle = { maxHeight: 35, height: 35, width: 170, marginBottom: 15, marginTop: 15 };

//const


class RestoLocations extends Component {

    constructor(props) {
      super(props);
      this.state = {
        locations: [],
        values: [],
        options: []
      };
      this.handleOnChange = this.handleOnChange.bind(this);

    };

/*
    options = [
        { value: 'checkboxOne', label: 'Checkbox One', disabled: false },
        { value: 'checkboxTwo', label: 'Checkbox Two', disabled: false },
        { value: 'checkboxThree', label: 'Checkbox Three', disabled: false },
    ];
*/
    options = [];

    getLocationList = () => {

      nomtxtApi
      .getRestoLocations(this.props.restoId)
      .then((value) => {
        this.setState({locations: value.data});
        let vals = value.data.locations.map(function (locations) {
            return (
              { value: locations.id, label: locations.name, disabled: false }
            )
          });
        this.setState({options: vals})
        //alert(JSON.stringify(vals));
      })
    }


    handleOnChange(values) {
       this.setState({ values });
       //alert(JSON.stringify(values));
       this.setEnabledLocations({values});
   }

   setEnabledLocations(values) {
     authentication
      .setRestoLocations(this.props.restoId, values.values);
   }


  render() {
    const locations = this.state.locations;
     const { values, options } = this.state;
    //(locations.locations) && alert(JSON.stringify(Object.values(locations.locations)));
    return (
      <React.Fragment>
        <Card>
          <CardContent>
            <Typography>Select the locations to enable for NomTxt.  </Typography>
            <Typography variant="body2">If your hours vary by location please send a support request.  </Typography>


          <div className="rainbow-p-vertical_large rainbow-p-left_xx-large">
          <CheckboxGroup
              className="location-checkboxes"
              label="Checkbox Group Label"
              required
              options={options}
              value={values}
              onChange={this.handleOnChange}
          />
          </div>

          </CardContent>
        </Card>


      </React.Fragment>
    );
  }

  componentDidMount(){
    this.getLocationList();
  }


}

export default withRouter(RestoLocations);
