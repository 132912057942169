import React, { Component } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Link } from "react-router-dom";

import { Fab, Box, Typography, Paper, List, ListItem, ListItemAvatar, Avatar, ListItemText, Grid, ListItemSecondaryAction, } from "@material-ui/core";

import { Home as HomeIcon } from "@material-ui/icons";
import { withRouter } from 'react-router-dom';
// import { ReactComponent as NotFoundIllustration } from "../../illustrations/not-found.svg";
import{ Button, ButtonIcon, Input,  } from 'react-rainbow-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV, faPlus, faTrashAlt, faTrash } from '@fortawesome/free-solid-svg-icons';
import firebase, { functions } from "../../firebase";


import {
  Favorite as FavoriteIcon,
  Delete as DeleteIcon
} from "@material-ui/icons";

const inputStyles = {
    width: 60,
    marginRight: 2,
};

const initialState = {
  "ilike":"bigbutts"
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '70%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  faveslist: {
    width: '70%',
    maxWidth: 560,
    backgroundColor: theme.palette.background.paper,
  },
}));

const lFaves = { maxWidth: 555, marginLeft: 30, marginTop: 15, paddingLeft: 15, paddingRight: 15, paddingBottom: 15 };

class AboutPage extends Component {

    constructor(props) {
      super(props);
      this.state = {
      };
    };


  render() {
    return (
      <React.Fragment>
      <Grid
        container
        spacing={2}
        direction="column"
        alignItems="center"
       >
       <Grid item s={12}>

        <Paper style={lFaves}>
        <Typography variant="h5">About NomText</Typography>
          <p>NomTxt is an easy new way to get that dish you always get. We asked around
          and it turned out a lot of people want to get the same dish every time they
          order from which ever restaurant. So this app lets you do that with one text.
          </p>  <br/>
          <p>Once you have configured your settings, you won't need to return. Unless you want
          to set up a favorite with a different restaurant, or want to change your favorite with this one.
          There's nothing to download, just store the special ordering number in your
          phone contacts. Make sure to use a label that's easy to remember for Siri, Alexa, Cortana, Computer, Josh, Jane or whoever the next AI mega star ends up being.
          <br/>   </p>
          <p>To order text any wake word to your restaurant. Like this:
          <br/>
          :: I'm hungry
          <br/>
          or
          <br/>
          :: Hey
          <br/>
          or
          <br/>
          :: require Coffee
          <br/></p>
          <p>You'll get a text back from NomTxt confirming what we have stored as your favorite. If you don't cancel
          your order will be sent to your favorite restaurant and they'll get working on it right away.
          <br/></p>
          <p>Right now, your restaurant is configured for Pickup Only. As soon as we can offer delivery service integration, you'll be the first to know.
          </p>


        </Paper>
        </Grid>
        </Grid>
      </React.Fragment>
    );
  }

  componentDidMount(){
  }


}

export default withRouter(AboutPage);
