import React, {Component} from "react";

import PropTypes from "prop-types";
import { makeStyles } from '@material-ui/core/styles';
import { Typography, List, Input, ListItem, ListItemAvatar, Avatar, ListItemText, IconButton, ListItemSecondaryAction, Paper, Card, Grid, CardContent, CardActionArea } from "@material-ui/core";
import authentication from "../../services/authentication";
import nomtxtApi from "../../services/nomtxt";
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import NativeSelect from '@material-ui/core/NativeSelect';
import { Table, Column, Badge, TableWithBrowserPagination, GlobalHeader, ButtonGroup, ButtonIcon, Accordion, AccordionSection, CheckboxGroup, Button } from 'react-rainbow-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV, faPlus, faTrashAlt, faTrash } from '@fortawesome/free-solid-svg-icons';
//import { DataGrid } from '@material-ui/data-grid';
import { withRouter,  } from 'react-router-dom';
import { useParams } from 'react-router'
import numeral from 'numeral'

import {
  Favorite as FavoriteIcon,
  Delete as DeleteIcon
} from "@material-ui/icons";

const StatusBadge = ({ value }) => <Badge label={value} variant="lightest" />;
const containerStyles = { height: 812 };
const containerTableStyles = { height: 756 };
const headerIdStyle = { }
const deleteBtnStyle = { }
const inputStyles = {
    width: 40,
    marginRight: 2,
};
const imgStyle = { width: 100, height: 100 }
const btnDesctructiveStyle = { maxHeight: 35, height: 25, width: 75 };
const btnSuccessStyle = { maxHeight: 35, height: 25, width: 255 };

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 20,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));
/*
USER = {"user":{"lastname":"Eileen","username":"pingo","phoneNumber":"14754414133","firstname":"Alice","userType":"regular"}
,"resto":{"id":"wFSSMCVOklLgtpy3y120","data":{"favorite":"Large Gagh and a small Raktajino","price":7.55,"balance":469.79999999999995}
,"card":{"card_id":"nomnompretend","data":{"billing_address":{"postal_code":"12345","country":"US"}
  ,"exp_year":"2021","exp_month":"12","card_brand":"VISA","cardholder_name":"Alice Eileen","last_4":"1234","card_id":"nomnompretend"}}}}

*/
const initialState = {
  "ilike":"bigbutts"
};

class RestoList extends Component{

  constructor(props) {
    super(props);
    this.state = {
      inputField: '',
      restos: [],
      id: '1',
      name: '+-Select-+',
      selectValue: 'HvhrYPNyZRjdwj7FLfCF', //restaurant selected
      //selectedValue: 'wFSSMCVOklLgtpy3y120',
      menu_items: [],
      itemImages: [],
      varCheckVals: [],
      activeSection: '',
      activeNames: ['accordion-1', 'accordion-2'],
      values: [],
      sumTotal: 0,
      restoName: '',
      userId: ""
    };

    this.holdval = 0;

  };

  setHoldVal(val){
    this.holdval = val;
  }

  getHoldVal(){
    return this.holdval;
  }

  addToHoldVal(val){
    this.holdval += val;
  }


    columns = [
      { field: 'id', headerName: 'ID', width: 70 },
      { field: 'menu_item_name', headerName: 'Menu Item', width: 130 },
      { field: 'menu_item_desc', headerName: 'Description', width: 160 },

    ];

    foo =   [
        {id:"OBPJWZSFY6OL4Z4AVVN26JIQ",menu_item_name:"Raktajino",menu_item_desc:"Breve cappuccino with sculptural flourishes "},{id:"STYP7AWXD3RWU4VPXUY64I45",menu_item_name:"Tide Pod Latte",menu_item_desc:"Satisfy the urge to eat Tide Pods with a Tide Pod Latte. Does not contain Tide Pods"},
      ];

    getRestoName = (restoId) => {
      authentication
      .getRestoName(restoId)
      .then((n) => {
        this.setState({restoName: n});
      })
    }

    getRestoPhone = (restoId) => {
      authentication
      .getRestoPhone(restoId)
      .then((tel)=>{
        this.setState({restotel: tel})
      })
    }

    getUserTip = () => {
      authentication
      .getUserTip()
      .then((t)=>{
        this.setState({tipAmt: t})
      })
    }

    getRestoItemsList = async (restoId) => {
      //get seelcted resto from previous page selections

         const restoItems = await nomtxtApi
           .getRestoItems(restoId)
           .then((value) => {
             console.log("resto items =" + JSON.stringify(value));
             //alert("resto items =" + JSON.stringify(value));
             //let cardso = Object.values(value)
             //this.setState({ resto_catalog: value });
             let rowdata = this.state.menu_items;
             let imagedata = this.state.itemImages;
             let variation_data;
               value.data.objects.map( function (item) {
                 if(item.type === "ITEM"){
                   let imgItem = value.data.objects.filter(i => ( i.type === "IMAGE" && i.id === item.image_id ) );
                   rowdata.push(
                     { id: item.id,
                       menu_item_name: item.item_data.name,
                       menu_item_desc: item.item_data.description,
                       variation_data: item.item_data.variations,
                       imageId: item.image_id,
                       imageUrl: imgItem[0].image_data.url
                     }
                   );
                }

                if(item.type === "IMAGE"){
                  imagedata.push({
                    id: item.id,
                    url: item.image_data.url
                  })
                }

               });

             //console.log("ROWDATA in SETup: " + JSON.stringify(rowdata));
             this.setState({menu_items: rowdata });
             this.setState({itemImages: imagedata })

           })
           .catch((reason) => {
             console.log("ERROR getting pointer =" + reason);
           })
           this.onChange('selectValue',restoId);
   };


    onChange(field, value) {
        // parent class change handler is always called with field name and value
        this.setState({[field]: value});
    };

    onDeleteElement(){
      //alert("onDelete");
    }

    CustomAction = (props) => {
      const { row, onDeleteElement } = props;
/*

[{"id":"OBPJWZSFY6OL4Z4AVVN26JIQ","menu_item_name":"Raktajino","menu_item_desc":"Breve cappuccino with sculptural flourishes ",
"variation_data":[{"type":"ITEM_VARIATION","id":"TB223CZ3DNPI7CLRKTNJ77IT","updated_at":"2020-10-12T21:02:46.971Z","version":1602536566971,"is_deleted":false,"present_at_all_locations":true,
"item_variation_data":
   {"item_id":"OBPJWZSFY6OL4Z4AVVN26JIQ","name":"Whole Milk, Small","sku":"","ordinal":0,"pricing_type":"FIXED_PRICING",
   "price_money":{"amount":345,"currency":"USD"},
   "item_option_values":[{"item_option_id":"XT7TP3DXF4JIZQYNHQILFBCE","item_option_value_id":"MFMJ4X2QH7IW4U4CKDPJBBR7"},{"item_option_id":"S3MJNEVX3KFFWW3RD7RFPTD7","item_option_value_id":"7MB6EAWETTHD2MX3YS3TRJ57"}]}},
{"type":"ITEM_VARIATION","id":"OY3FLP2SQFA54H6RZW65BR24","updated_at":"2020-10-12T21:02:46.971Z","version":1602536566971,"is_deleted":false,"present_at_all_locations":true,
"item_variation_data":{"item_id":"OBPJWZSFY6OL4Z4AVVN26JIQ","name":"Whole Milk, Medium","sku":"","ordinal":1,"pricing_type":"FIXED_PRICING","price_money":{"amount":445,"currency":"USD"},"item_option_values":[{"item_option_id":"XT7TP3DXF4JIZQYNHQILFBCE","item_option_value_id":"MFMJ4X2QH7IW4U4CKDPJBBR7"},{"item_option_id":"S3MJNEVX3KFFWW3RD7RFPTD7","item_option_value_id":"F2BJFMLOEM3QV7OE3JGOQWRX"}]}},{"type":"ITEM_VARIATION","id":"CXNQTW5HSLJYMYVKQI7XHPZX","updated_at":"2020-10-

<div className="rainbow-p-vertical_large rainbow-p-left_xx-large">
       <CheckboxGroup
           id="checkbox-group-1"
           label="Checkbox Group Label"
           options={options}
           value={values}
           onChange={this.handleOnChange}
       />
   </div>

   const options = [
    { value: 'checkboxOne', label: 'Checkbox One', disabled: false },
    { value: 'checkboxTwo', label: 'Checkbox Two', disabled: false },
    { value: 'checkboxThree', label: 'Checkbox Three', disabled: false },
];
*/
      const vdata = Object.values(row.variation_data);
      let varid;
      let variation_id;
      let varopts;
      let chkopt = [];
      vdata.map( function (variation) {
        //varid = <Typography variation="body1">{variation.id}</Typography>;
        variation_id = variation.id;
        varopts = variation.item_variation_data;
        let money = varopts.price_money.currency == 'USD' ? '$ ' +  nomtxtApi.convertCentsToDollars(varopts.price_money.amount) + ' USD': 'invalid currency'
        let ell = {
          v: variation_id,
          l:varopts.name,
          mm:money.toString(),
          mc:varopts.price_money.amount.toString(),
          i:row.id,
          n:row.menu_item_name,
          q:"1"
        };

        chkopt.push(
          {
            value: JSON.stringify(ell),
            label: varopts.name + ' ' + money, key: variation_id
          });

      });



      return (
                    <div className="rainbow-p-vertical_large rainbow-p-left_xx-large">
                           <CheckboxGroup
                               id="checkbox-group-1"
                               label="Item Variations"
                               options={chkopt}
                               value={this.state.values}
                               onChange={this.handleOnVariationCheckChange.bind(this)}
                           />
                    </div>
      );
  }



   handleDeleteElement(e){
     // alert(Object.getOwnPropertyNames(event));
     //alert(e.currentTarget.id);
     let data = this.state.values;
     //let newdata = data.filter( value => (value.v !== e.currentTarget.id) );
     let newdata = data.filter( value => ( !value.includes(e.currentTarget.id)) );
     this.setState({values: newdata })
     //this.updateTotal({values: newdata });
   }

   handleClearButtonClick(){
     this.setState({values: []})
     this.setState({sumTotal: 0})
   }


  handleOnVariationCheckChange(values) {
      //console.log(values);
      this.setState({ values });
      // this.updateTotal({values});
      //this.addToTotal();
  }

  sumSelected(){
    // loop thru selected items and create a sum
    // of item price * qty
    let summa = 0;
    let faves = [];
    if(this.state.values.length == 1){
      faves.push(this.state.values);
    }
    else{
      faves = this.state.values;
    }
    let thisprice;
    let linetot;
    let newtot = 0;
    faves.map(function(item) {

      let itemqty = JSON.parse(item).q > 0 ? JSON.parse(item).q : 1;
      //if(itemqty <= 0) { return "ERROR"; }
      let itemprice = JSON.parse(item).mc;
      if(itemprice < 0) { return "ERROR"; }
      thisprice = parseInt(itemprice);
      linetot = ( nomtxtApi.convertCentsToDollars(thisprice) * itemqty);
      newtot = parseFloat(newtot) + parseFloat(linetot);
    });
      //this.setState({sumTotal: newtot});
      //this.addToHoldVal(newtot);
      newtot = numeral(newtot).format('0,0.00');
      return newtot;


  }

  handleCustomActionOnChange(event) {
    //this.addToTotal();
  }

  handleIncrementerChange = (e) => {
    let variantId = e.currentTarget.id.substring(4);
    // find the one to be incremented and pull it out to work with

    let data = [];
    let newdata;

    if(this.state.values.length == 1){
      data.push(this.state.values);
    }
    else{
      data = this.state.values;
    }
    let newselecteddata = [];
    data.map(function(item) {
      //alert(item);
      let itemqty = JSON.parse(item).q;
      //alert(JSON.parse(item).v + " and " + variantId)
      if(JSON.parse(item).v == variantId){
        itemqty = e.currentTarget.value;
      };
      let newitem = {
        v: JSON.parse(item).v,
        l: JSON.parse(item).l,
        mm: JSON.parse(item).mm,
        mc: JSON.parse(item).mc,
        i: JSON.parse(item).i,
        n: JSON.parse(item).n,
        q: itemqty
      };

      newselecteddata.push(JSON.stringify(newitem));
    });
    //newselecteddata = JSON.stringify(newselecteddata);
    this.setState({values: newselecteddata});
    //alert(this.state.values);

  }

  // gather data to send to review/confirm page
  proceedButtonClick(){
    //alert("clicked");
    let payload = {
      restoId: this.state.selectValue,
      //userId: this.props.user.uid,
      userId: this.state.userId,
      selectedItems: this.state.values,
      submitState: this.state,
      locationId: this.state.locationId,
      restoName: this.state.restoName,
      restotel: this.state.restotel
    };
    //alert(JSON.stringify(payload));
    authentication.putUserFaves(payload)
    .then((value) => {
      this.setState({ savedStatus: "true" });
      const redirLink = "/faves?u=" + this.props.user.uid;
      this.props.history.push(redirLink, { data: {payload} });
    })
    .catch((error) => {
      console.log("ERROR getting pointer =" + error);
    })

/*
    nomtxtApi.putUserFaves(payload)
    .then((value) => {
      //console.log("resto list =" + JSON.stringify(value));
      //let cardso = Object.values(value)
      this.setState({ savedStatus: "true" });
      const redirLink = "/faves/" + this.props.user.uid;
      this.props.history.push(redirLink, { data: payload });
    })
    .catch((error) => {
      console.log("ERROR getting pointer =" + error);
    })
*/


  }

  gimmeSum = () => {
    if(this.state.values.length > 0){
      let orderTotal = this.sumSelected();
      return (<><Typography variant="h5">Total: {orderTotal} </Typography></>);
    }
    else{
      return (<><Typography variant="h5">Total: $0  </Typography></>);
    }

  }

  render(){

    const { userData, user } = this.props;
    const restos = Object.values(this.state.restos);
    const items = Object.values(this.state.menu_items);
    const columns  = this.columns
    //const carddata = this.state.cards;
    //console.log("menu_items in RENDER as string: " + JSON.stringify(this.state.menu_items));
    var msg = 'You selected '+this.state.selectValue;
    const { variationCheckValues } = this.state.varCheckVals;
    const { activeNames, restoName, itemImages } = this.state;
    let total = this.state.sumTotal;
    let sum = 0;
    let summa = this.state.summa;
    let faveSet = this.state.values;

    const getItemImage = (imgId) => {
      //console.log(JSON.stringify(itemImages));
      let imgData = itemImages.filter( i => ( i.id === imgId) );
      if(imgData.url){
        return(
          <img src={imgData.url}  />
        );
      }
      else{
        return(
          <img src="http://localhost:3000/images/logo-trans-blurblet-long-notag.svg"  />
        );
      }

    }

    const AvatarThing = ({ value }) => <img src={value} style={imgStyle} />;

    return(
      <React.Fragment>
      <Typography variant="h3">{restoName}</Typography>


        <Grid container spacing={2}>
          <Grid item xs={8}>
        <div className="rainbow-p-bottom_xx-large">

          <div style={containerStyles}>
              <TableWithBrowserPagination
                  pageSize={10}
                  data={items}
                  keyField="id"
                  style={containerTableStyles}
              >
                  <Column header="ID" field="id" defaultWidth={4} />
                  <Column header="Image" field="imageUrl" defaultWidth={100}
                  component={AvatarThing}
                  />
                  <Column header="Menu Item" field="menu_item_name" defaultWidth={200} />
                  <Column header="Description" field="menu_item_desc" defaultWidth={350}  />
                  <Column header="Variations" field="variation_data"
                    defaultWidth={350}
                    component={({ row }) => (
                        <this.CustomAction row={row} onBlur={this.handleCustomActionOnChange.bind(this)} onChange={this.handleCustomActionOnChange.bind(this)}/>
                    )}
                />
              </TableWithBrowserPagination>
          </div>
      </div>
        </Grid>
        <Grid item xs={4}>
        <div>
          <Paper>

              <Typography variant="h4">Your Favorite</Typography>
              <Card>
              <CardContent>
              <List>
                { this.state.values && this.state.values.map(
                  function (val) {
                      return (
                        <ListItem key={`${JSON.parse(val).v}`}>
                            <ListItemAvatar>
                              <Avatar>
                                <FavoriteIcon />
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText>
                            <Grid container spacing={2}>
                              <Grid item xs={8} key={`${JSON.parse(val).i}`}>
                                <Typography variant="h6">{`${JSON.parse(val).n}  ${JSON.parse(val).mm ? JSON.parse(val).mm : null}`}</Typography>
                                <Typography variant="body1">{` ${JSON.parse(val).l ? JSON.parse(val).l : null} `}</Typography>

                              </Grid>
                              <Grid item xs={2}>
                                  <>
                                  {
                                    (JSON.parse(val).q > 1) ?
                                  <Input
                                    label="Qty"
                                    key={`${JSON.parse(val).i}_${JSON.parse(val).v}`}
                                    name={`${JSON.parse(val).i}_${JSON.parse(val).v}`}
                                    data-id={`qty_${JSON.parse(val).v}`}
                                    id={`qty_${JSON.parse(val).v}`}
                                    type="number"
                                    className="itemQty"
                                    style={inputStyles}
                                    placeholder={`${JSON.parse(val).q}`}
                                    min="1"
                                    onChange={this.handleIncrementerChange.bind(this)}
                                  >
                                  </Input>
                                  :
                                  <Input
                                    label="Qty"
                                    key={`${JSON.parse(val).i}_${JSON.parse(val).v}`}
                                    name={`${JSON.parse(val).i}_${JSON.parse(val).v}`}
                                    data-id={`qty_${JSON.parse(val).v}`}
                                    id={`qty_${JSON.parse(val).v}`}
                                    type="number"
                                    className="itemQty"
                                    style={inputStyles}
                                    placeholder="1"
                                    min="1"
                                    onChange={this.handleIncrementerChange.bind(this)}
                                  >
                                  </Input>
                                }
                                  </>

                              </Grid>
                            </Grid>
                          </ListItemText>

                          <ListItemSecondaryAction>
                            <ButtonIcon
                               variant="border-filled"
                               name={`delete_${JSON.parse(val).i}`}
                               data-id={`${JSON.parse(val).v}`}
                               id={`${JSON.parse(val).v}`}
                               edge="end" aria-label="delete"
                               icon={<FontAwesomeIcon icon={faTrash} />}
                               onClick={this.handleDeleteElement.bind(this)}
                           />
                          </ListItemSecondaryAction>
                        </ListItem>
                      );
                    }, this)
            }
            </List>
            </CardContent>
            <CardContent>
              {
                this.gimmeSum()
              }
              <Button
                label="Continue"
                onClick={ this.proceedButtonClick.bind(this) }
                variant="success"
                className="btnSuccess"
                style={btnSuccessStyle}
              />
              <Button label="Clear" variant="destructive" className="btnDesctructive" style={btnDesctructiveStyle} onClick={this.handleClearButtonClick.bind(this)} />
            </CardContent>
            </Card>
          </Paper>
        </div>
        </Grid>
      </Grid>


      </React.Fragment>
    );



  }

  componentDidMount() {
    //this.restoListData();
    let qsParams = new URLSearchParams(this.props.location.search);
    let redirLink = "/user/onboard/1"
    //let qsa = this.props.location.search.substr(1).split('&');
    let restoId = '';
    let locationId = '';
    let userId = "";
    if(qsParams.get("r")){
      restoId = qsParams.get("r");
      this.setState({restoId: restoId});
    }
    if(qsParams.get("loc")){
      locationId = qsParams.get("loc");
      //alert("loc = " + locationId);
      this.setState({locationId: locationId});
    }
    if(qsParams.get("u")){
      userId = qsParams.get("u");
      this.setState({userId: userId});
    }

    this.getRestoItemsList(restoId);

    this.getRestoName(restoId);
    this.getRestoPhone(restoId);

  }


}


RestoList.propTypes = {
  //userCard: PropTypes.object.isRequired,

};

export default withRouter(RestoList);
