import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useForm, Controller } from 'react-hook-form';
import authentication from "../../services/authentication";
import { Fab, Box, Typography, Paper, List, ListItem, Grid, Card, TextField } from "@material-ui/core";
import { Ranger } from "./AntFieldControllers";
import moment from 'moment';
import { TimePicker } from 'antd';
import 'antd/dist/antd.css'



export default function HookFormAsync(props) {
  //alert(JSON.stringify(props));
  const userData = props.userData;
  const user = props.user;
  //const restoId = userData.restoId;
  const { register, handleSubmit, reset, control, errors } = useForm();
  const onSumbit = data => {
    alert(JSON.stringify(data));
  };

  const [count, setCount] = useState(0);
  const [restoId, setRestoId] = useState('');
  const [restoName, setRestoName] = useState('');
  const [ccPhone, setCcPhone] = useState('');
  const [ccEmail, setCcEmail] = useState('');
  const [payments, setPayments] = useState([{square: "square"}]);
  const [paymentSelected, setPaymentsSelected] = useState();
  const [restoDetails, setRestoDetails] = useState({});
  //alert(userData);
  useEffect( () => {
    //console.log(JSON.stringify("USERDATA "+props.userData));
    //alert(JSON.stringify(userData));

    let userResto = userData.restoId;
    setRestoId({ userResto });

      authentication
        .getRestoDetails(userData.restoId)
        .then((value) => {
          setRestoDetails({ value });
          //alert(JSON.stringify(value));
          reset({
            restoName: value.restoName,
            ccPhone: value.restoId
          });
        })
        .catch((reason) => {
          console.log("ERROR getting pointer =" + reason);
        })

    // you can do async server request and fill up form
    /*
    setTimeout(() => {
      reset({
        restoName: "gallonhat",
        restoId: restoDetails.restoName,
      });
    }, 0);
    */
  }, [reset]);

  const { RangePicker } = TimePicker;
  const format = "HH:mm";

  return (
    <form onSubmit={handleSubmit(onSumbit)}>
      <h1>Async Set Form Values</h1>
      <Typography variant="body2">Restaurant Name</Typography>
      <input name="restoName" ref={register} />

      <Typography variant="body2">Customer Service Phone</Typography>
      <input name="ccPhone" ref={register} />

      <Typography variant="body2">Customer Service Email</Typography>
      <input name="ccEmail" ref={register} />

      <Typography variant="body2">Payments</Typography>
      <input name="payments" ref={register} />

      <Typography variant="body2">Sunday Hours</Typography>
      <Controller
					as={Ranger()}
					name='sunHours'
					control={control}
					rules={{ required: true }}
				/>
				{errors.FirstName && (
					<span className='error'>This field is required</span>
				)}


      <input type="submit" />
    </form>
  );
}
