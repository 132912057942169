import React, { Component } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Link } from "react-router-dom";
import nomtxtApi from "../../services/nomtxt";
import authentication from "../../services/authentication";
import { withRouter } from 'react-router-dom';
/*
import axios from "axios";

import { Home as HomeIcon } from "@material-ui/icons";

// import { ReactComponent as NotFoundIllustration } from "../../illustrations/not-found.svg";
*/
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faSearch } from '@fortawesome/free-solid-svg-icons';

import { Button as LinkButton, Card, CardContent, CardActionArea, Paper, Typography, Fab, Box,  List, ListItem, ListItemAvatar, Avatar, ListItemText, Grid, GridList, GridListTile  } from "@material-ui/core";
import { Button, ButtonIcon, Input } from 'react-rainbow-components';
import RestoProgress from "./RestoProgress.js"


const initialState = {
  trytel: '(###)###-####',
  blnDisabled: true
};

const inputStyles = {
    width: 150,
    marginRight: 2,
    paddingBottom: 10
};

const cardStyles = { maxWidth: 755, marginLeft:30, marginTop: 15, width: 600, marginLeft: 'auto', marginRight: 'auto' };
const resultGridStyle = { paddingLeft: 20  }
const spacer = { marginLeft: 'auto', marginRight: 'auto'  }

class Messaging extends Component {

    constructor(props) {
      super(props);
      this.state = {
        userFaves: [],
        restoName: '',
        restoPhone: '',
        restoStatus: '',
        trytel: '(###)###-####',
        areaCode: '',
        blnDisabled: true,
        successMsg: ''
      };
    };

    getRestoDetails = () => {

       authentication
         .getRestoDetails(this.props.userData.restoId)
         .then((value) => {
           //alert(JSON.stringify(value));
           //console.log("resto list =" + JSON.stringify(value));
           //let cardso = Object.values(value)
           let restoPhone = value.phoneNumber;
           let restoName = value.restoName;
           let restoStatus = value.status;
           this.setState({ restoPhone: value.phoneNumber });
           this.setState({ restoName: restoName });
           this.setState({ restoStatus: restoStatus });
           this.setState({resto: value});
         })
         .catch((reason) => {
           console.log("ERROR getting pointer =" + reason);
         })

     };

     searchNewMessagingPhone = async (areaCode) => {
        //const areaCode = this.state.areaCode;
        let trytel;
        let phone = await nomtxtApi
          .searchPhoneByAreaCode(areaCode)
          .then((val) => {
            //alert(JSON.stringify(val));
            if (!val.data[0]){
              trytel = {
                friendly: 'None available in that area code. Please try another'
              };
              this.setState({trytel: trytel});
              return;
            }
            else{
               trytel=  {
                friendly: val.data[0].friendlyName,
                region: val.data[0].region,
                restotel: val.data[0].phoneNumber,
                sid: val.data[0].sid
              };
              this.setState({trytel: trytel});
            }
            console.log(JSON.stringify(trytel));
          })
          .catch((reason) => {
               console.log("ERROR getting pointer =" + reason);
          });

     }


     setNewRestoPhone = () => {

     }

     handleKeepClick = () => {
       nomtxtApi
       .registerSelectedMsgTel({
         tel: this.state.trytel.restotel,
         restoId: this.props.userData.restoId,
         userId: this.props.user.uid,
         restoName: this.state.restoName
       })
       .then((val) => {
         this.setState({btnDisabled: false})
         this.setState({successMsg: "Excellent Choice!"})
       })

     }

     handleInputChange = (e) => {
       this.setState({"areaCode": e.currentTarget.value });

     }

     handleBtnClick = () => {
       //alert(this.state.areaCode);

       this.searchNewMessagingPhone(this.state.areaCode);

     }


  render() {
    //alert("userData:" + JSON.stringify(this.props.userData));
    const rName = this.state.restoName;
    //alert("this.state.restoName");
    const digits = this.state && this.state.trytel.friendly ? this.state.trytel.friendly : `(###) ###-####`;
    const apiUrl = nomtxtApi.getApiUrl();
    const apiVersion = nomtxtApi.getApiVersion();
    const vcardEndpoint = "/resto/vcard"; //:restoName/:restoTel

    const isConfigured = this.state.restoPhone ? true : false;

    return (
      <>
      {
        (isConfigured ) ? (
          <>
          <Grid
            container
            spacing={2}
            direction="column"
            alignItems="center"
           >
           <Grid item xs={12}>
            <Card style={cardStyles} key="searchAreaCodeCard">

            <RestoProgress currentIndex="2" user={this.props.user} />
            {this.state && this.state.restoName ?
            <Typography variant="h4">{this.state.restoName} - {this.state.restoPhone}</Typography>
            :
            <Typography variant="h4">nope</Typography>
            }

              <CardContent>
                <Typography variant="body1">This restaurant's phone has already been configured. To set up multiple numbers for different locations,
                or to change the number, please email support. </Typography>
              </CardContent>
              <CardActionArea>
                <LinkButton variant="contained" color="secondary"><a href={`${apiUrl}${vcardEndpoint}/${this.state.restoName}/${this.state.restoPhone}`}>Save Contact Card</a></LinkButton>
                <Grid
                  container
                  spacing={2}
                  direction="column"
                  alignItems="center"
                 >
                <div style={spacer}>
                  <LinkButton to={`/resto/enable`} component={Link} disabled={this.state.btnDisabled} variant="contained" color="primary">Continue</LinkButton>
                </div>
                </Grid>
                <br/>
              </CardActionArea>

            </Card>
            </Grid>
            </Grid>
          </>
        )
        :

      <React.Fragment>
      <Grid
        container
        spacing={2}
        direction="column"
        alignItems="center"
       >
       <Grid item xs={12}>
        <Card style={cardStyles} key="searchAreaCodeCard">


        <RestoProgress currentIndex="2" user={this.props.user} />
        {this.state && this.state.restoName ?
        <Typography variant="h4">{this.state.restoName}</Typography>
        :
        <Typography variant="h4">nope</Typography>
        }
          <CardContent>
                <Typography variant="body1">Messaging Setup</Typography>
                <Typography variant="body1">
                  Let's set up a messaging number for your restaurant! This will be the
                  number shared with customers for when they want to text your orders in.
                  Their orders come into NomTxt and we handle it from there. Once the order is paid
                  we send it off to your Square orders panel.
                </Typography>
                <GridList  >
                   <GridListTile key="Subheader" style={{ height: 125 }}>

                      <Input
                         label="Search by Area Code"
                         placeholder="Search"
                         type="search"
                         className="rainbow-p-around_medium"
                         style={inputStyles}
                         /* onChange={() => { this.handleInputChange(`${cardi.id}`) }} */
                         onChange={this.handleInputChange.bind(this)}
                         value={this.state.areaCode}
                         maxLength={3}
                         /*icon={<FontAwesomeIcon icon={faPhone}/>}*/
                         iconPosition="right"
                     />
                     </GridListTile>

                  <GridListTile style={{ height: 125 }}>
                    <Typography variant="body2">Search again to try another</Typography>
                    <Typography variant="body2">Press Keep when you're ready.</Typography>

                    <Typography variant="h6">{digits}</Typography>
                    <Typography variant="body2">{this.state.successMsg}</Typography>
                  </GridListTile>

                  <GridListTile style={{ height: 125 }}>
                  <Button
                     onClick={this.handleBtnClick}
                  >Search
                  <FontAwesomeIcon icon={faSearch} className="rainbow-m-left_medium" />
                  </Button>
                </GridListTile>

                  <GridListTile style={{ height: 100, }}>
                    <Button
                       onClick={this.handleKeepClick}
                    >Keep this one?
                    <FontAwesomeIcon icon={faCheck} className="rainbow-m-left_medium" />
                    </Button>
                  </GridListTile>
              </GridList>
          </CardContent>

          <CardActionArea>
            <LinkButton to={`/resto/enable`} variant="contained"  component={Link} disabled={this.state.btnDisabled} variant="contained" color="primary">Continue</LinkButton>
          </CardActionArea>

        </Card>
        <Card style={cardStyles} key="resultscard">
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
           >
            <Grid item xs={5}>

           </Grid>
          </Grid>
        </Card>
        </Grid>
      </Grid>
      </React.Fragment>
    }
    </>
    )
}


  componentDidMount(){
    this.setState({trytel: '(###)###-####'})
    this.getRestoDetails();
  }


}

export default withRouter(Messaging);
