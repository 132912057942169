import React, { useEffect, useState, withRouter } from 'react';
import ReactDOM from 'react-dom';
import { useForm, Controller } from 'react-hook-form';
import authentication from "../../services/authentication";
import nomtxtApi from "../../services/nomtxt";
import { Fab, Box, Typography, Paper, List, ListItem, Grid, Card, TextField } from "@material-ui/core";
import{ Select, CheckboxToggle, Button } from 'react-rainbow-components';
import { Ranger } from "./AntFieldControllers";
import moment from 'moment';
import { TimePicker } from 'antd';
import spacetime from 'spacetime'
import RestoProgress from "../RestoConfig/RestoProgress.js"

import 'antd/dist/antd.css'
import "../Forms/hookform.css";

// Load the full build.
var _ = require('lodash');
// Load the core build.
var _ = require('lodash/core');
// Load the FP build for immutable auto-curried iteratee-first data-last methods.
var fp = require('lodash/fp');
// Load method categories.
var array = require('lodash/array');
var object = require('lodash/fp/object');

const btnStyle = {
    width: 250,
    height: 50,
    marginTop: 20,
    paddingTop: 20,
    paddingBottom: 20,
    fontWeight: 600,
    backgroundColor: '#fac30c',
    marginLeft: 150,
    marginRight: 150
};

const lFaves = { width: 550, maxWidth: 555, marginLeft: 30, marginTop: 15, paddingLeft: 15, paddingRight: 15, paddingBottom: 15 };


export default function RestoConfig(props) {
  //alert(JSON.stringify(props));
  const userData = props.userData;
  const user = props.user;
  //const restoId = userData.restoId;
  const { register, handleSubmit, reset, control, errors } = useForm();


  const [count, setCount] = useState(0);
  const [restoId, setRestoId] = useState('');
  const [restoName, setRestoName] = useState('');
  const [csPhone, setcsPhone] = useState('');
  const [ccEmail, setCcEmail] = useState('');
  const [prepTime, setPrepTime] = useState('');
  const [payments, setPayments] = useState([{square: "square"}]);
  const [paymentSelected, setPaymentsSelected] = useState();
  const [restoDetails, setRestoDetails] = useState({});
  const [hoursEmpty, setHoursEmpty] = useState({});
  const [sunHours, setSunHours] = useState({});
  const [monHours, setMonHours] = useState({});
  const [tueHours, setTueHours] = useState({});
  const [wedHours, setWedHours] = useState({});
  const [thuHours, setThuHours] = useState({});
  const [friHours, setFriHours] = useState({});
  const [satHours, setSatHours] = useState({});

  const[showSun, setShowSun] = useState(false);
  const[showMon, setShowMon] = useState(false);
  const[showTue, setShowTue] = useState(false);
  const[showWed, setShowWed] = useState(false);
  const[showThu, setShowThu] = useState(false);
  const[showFri, setShowFri] = useState(false);
  const[showSat, setShowSat] = useState(false);

  //alert(userData);

  const onSumbit = async data => {
    //alert("FROM onsubmit " + JSON.stringify(data));


    await authentication
    .setRestoDetails(data)
    .then(()=>{

      })
    .catch((e) => {
      console.log("ERROR in submit RestoConfig" + e.message);
    });

    window.location = "/resto/setup/payments/square/1" ;
  };

  const paymentOptions = [
      { value: 'square', label: 'Square' },
  ];

  const containerStyles = {
      maxWidth: 500,
  };


  useEffect( () => {
    //console.log(JSON.stringify("USERDATA "+props.userData));
    //alert(JSON.stringify(userData));

    let userResto = userData.restoId;
    setRestoId({ userResto });

      authentication
        .getRestoDetails(userData.restoId)
        .then((value) => {
          setRestoDetails({ value });
          let profile_status = value.profile;
          if(value.profile === "new"){
            profile_status = "edited";
          }

          reset({
            restoId: userData.restoId,
            restoName: value.restoName,
            csPhone: value.csPhone,
            csEmail: value.csEmail,
            prepTime: value.prepTime,
            profile: profile_status,
          });
        })
        .catch((reason) => {
          console.log("ERROR getting pointer =" + reason);
        });


        authentication
          .getRestoHours(userData.restoId)
          .then((value) => {
              if(_.isEmpty(value)){
                setHoursEmpty(true);
              }
              else{
                if(value.data.sun){ setSunHours(value.data.sun); }
                if(value.data.mon){ setMonHours(value.data.mon); }
                if(value.data.tue){ setTueHours(value.data.tue); }
                if(value.data.wed){ setWedHours(value.data.wed); }
                if(value.data.thu){ setThuHours(value.data.thu); }
                if(value.data.fri){ setFriHours(value.data.fri); }
                if(value.data.sat){ setSatHours(value.data.sat); }
              }
            })
          .catch((reason) => {
            console.log("ERROR in getRestoHours getting pointer =" + reason);
          })

  }, [reset]);

  const timeRequired = { required: false }

  const handleOnChange = () => {
    return setShowSun(!showSun);
  }

  const handleOnMondayChange = () => {
    return setShowMon(!showMon);
  }

  const handleOnTuesdayChange = () => {
    return setShowTue(!showTue);
  }

  const handleOnWednesdayChange = () => {
    return setShowWed(!showWed);
  }

  const handleOnThursdayChange = () => {
    return setShowThu(!showThu);
  }

  const handleOnFridayChange = () => {
    return setShowFri(!showFri);
  }

  const handleOnSaturdayChange = () => {
    return setShowSat(!showSat);
  }


  return (
    <React.Fragment>
    <Grid
      container
      spacing={2}
      direction="column"
      alignItems="center"
     >
       <Grid item s={12}>
          <Paper style={lFaves}>
              <RestoProgress currentIndex="0" user={props.user} />
              <form onSubmit={handleSubmit(onSumbit)}>
                <Typography variant="h4">Restaurant Profile</Typography>
                <input name="restoId" type="hidden" ref={register} />
                <input name="profile" type="hidden" ref={register} />
                <Typography variant="body2">Restaurant Name</Typography>
                <input name="restoName" ref={register} />

                <Typography variant="body2">Customer Service Phone</Typography>
                <input name="csPhone" ref={register} />
                <Typography variant="body2">Customer Service Email</Typography>
                <input name="csEmail" ref={register} />

                <Typography variant="body2">Payments Provider</Typography>
                <Select
                   bottomHelpText="Only Square supported at this time."
                   options={paymentOptions}
                   style={containerStyles}
                   className="rainbow-m-vertical_x-large rainbow-p-horizontal_medium rainbow-m_auto"
                   labelAlignment="left"
                 />

                 <Typography variant="body2">Order Prep Average Time</Typography>
                 <input name="prepTime" ref={register} />

                 <Typography variant="body2">Sunday Hours: { sunHours.open } - { sunHours.close } </Typography>
                 <CheckboxToggle
                   id="checkbox-toggle-component-sunday"
                   label="Edit Sunday"
                   value={showSun}
                   onChange={handleOnChange}
                   variant="x-small"
                   />
                   { showSun &&
                     <Controller
             					as={Ranger()}
             					name='sunHours'
             					control={control}
             					defaultValue=''
                      rules={ timeRequired }
                      forwardRef={register}
             				/>
                  }

                  <br/> <br/>

                  <Typography variant="body2">Monday Hours:  { monHours.open } - { monHours.close } </Typography>
                  <CheckboxToggle
                    id="checkbox-toggle-component-monday"
                    label="Edit Monday"
                    value={showMon}
                    onChange={handleOnMondayChange}
                    variant="x-small"
                    />
                    { showMon &&
                      <Controller
                         as={Ranger()}
                         name='monHours'
                         control={control}
                         defaultValue=''
                         forwardRef={register}
                       />
                  }
                  <br/> <br/>

                   <Typography variant="body2">Tuesday Hours:  { tueHours.open } - { tueHours.close } </Typography>
                     <CheckboxToggle
                       id="checkbox-toggle-component-tuesday"
                       label="Edit Tuesday"
                       value={showTue}
                       onChange={handleOnTuesdayChange}
                       variant="x-small"
                       />


                   { showTue &&
                   <Controller
                      as={Ranger()}
                      name='tueHours'
                      control={control}
                      defaultValue=''
                      forwardRef={register}
                    />
                  }
                  <br/> <br/>

                    <Typography variant="body2">Wednesday Hours:  { wedHours.open } - { wedHours.close } </Typography>
                    <CheckboxToggle
                      id="checkbox-toggle-component-weds"
                      label="Edit Wednesday  "
                      value={showWed}
                      onChange={handleOnWednesdayChange}
                      variant="x-small"
                      />


                      { showWed &&

                      <Controller
                         as={Ranger()}
                         name='wedHours'
                         control={control}
                         defaultValue=''
                         rules={{ timeRequired }}

                       />
                   }
                   <br/> <br/>

                     <Typography variant="body2">Thursday Hours:  { thuHours.open } - { thuHours.close } </Typography>
                     <CheckboxToggle
                       id="checkbox-toggle-component-thursday"
                       label="Edit Thursday"
                       value={showThu}
                       onChange={handleOnThursdayChange}
                       variant="x-small"
                       />


                       { showThu &&


                         <Controller
                            as={Ranger()}
                            name='thuHours'
                            control={control}
                            defaultValue=''
                            rules={{ timeRequired }}

                          />
                        }

                        <br/> <br/>

                      <Typography variant="body2">Friday Hours:  { friHours.open } - { friHours.close } </Typography>
                        <CheckboxToggle
                          id="checkbox-toggle-component-friday"
                          label="Edit Friday"
                          value={showFri}
                          onChange={handleOnFridayChange}
                          variant="x-small"
                          />

                        { showFri &&
                        <Controller
                           as={Ranger()}
                           name='friHours'
                           control={control}
                           defaultValue=''
                           rules={{ timeRequired }}

                         />
                       }
                        <br/> <br/>
                       <Typography variant="body2">Saturday Hours:  { satHours.open } - { satHours.close } </Typography>

                       <CheckboxToggle
                         id="checkbox-toggle-component-saturday"
                         label="Edit Saturday"
                         value={showSat}
                         onChange={handleOnSaturdayChange}
                         variant="small"
                         />

                       { showSat &&
                         <Controller
                            as={Ranger()}
                            name='satHours'
                            control={control}
                            defaultValue=''
                            rules={{ timeRequired }}

                          />
                        }
                          <br/> <br/>
                    <Button type="submit" style={btnStyle}>Save</Button>


              </form>
          </Paper>
      </Grid>
    </Grid>
    </React.Fragment>
  );
}
