import React from 'react';
import {
    ProgressIndicator,
    ProgressStep,
    Button,
} from 'react-rainbow-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV, faPlus, faTrashAlt, faTrash } from '@fortawesome/free-solid-svg-icons';
import {Typography, Paper, Grid  } from "@material-ui/core";
import authentication from "../../services/authentication";

import nomtxtApi from "../../services/nomtxt";
// Load the core build.
var _ = require('lodash/core');

const stepNames = ['step-1', 'step-2', 'step-3', 'step-4', 'step-5'];

const steps = ['first', 'second', 'third', 'fourth', 'fifth'];

const slugs = ['profile', 'setup/payments/square/2', 'setup/messaging', 'setup/enable', 'setup/billing']

const progressStyles = { paddingBottom: 20 }
const progressText = { paddingTop: 40, paddingBottom: 20, display: 'none' }
const btnHidden = { display: 'none' }
const pgiStyle = { marginBottom: 30 }

class RestoProgress extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          currentStepIndex: parseInt(this.props.currentIndex),
        };
        this.handleNextClick = this.handleNextClick.bind(this);
        this.handleBackClick = this.handleBackClick.bind(this);
    }

    handleNextClick() {
        const { currentStepIndex } = this.state;
        if (currentStepIndex < stepNames.length - 1) {
            const nextStepIndex = currentStepIndex + 1;
            this.setState({ currentStepIndex: nextStepIndex });
        }
    }

    handleBackClick() {
        const { currentStepIndex } = this.state;
        if (currentStepIndex > 0) {
            const previewStepIndex = currentStepIndex - 1;
            this.setState({ currentStepIndex: previewStepIndex });
        }
    }

    isNextDisabled() {
        const { currentStepIndex } = this.state;
        if (currentStepIndex < stepNames.length - 1 && currentStepIndex >= 0) {
            return false;
        }
        return true;
    }

    isBackDisabled() {
        const { currentStepIndex } = this.state;
        if (currentStepIndex > 0 && currentStepIndex < stepNames.length) {
            return false;
        }
        return true;
    }

    findRestoStatus = async () => {
      let userData;
      if(!this.props.userData){
        userData = await authentication.getUserData(this.props.user.uid)
        .then((value) => {
          return value;
        })
        .catch(e=>{
        })
      }
      const restoId = userData.restoId;


      const resto = await authentication.getRestoDetails(restoId);
      this.setState({resto: resto});


      const restoHours = await authentication.getRestoHours(restoId)
      .then((val) => {
        if(_.isEmpty(val)){
          this.setState({restoHours: {}});
        }
        this.setState({restoHours: val});
      })
      .catch(e=>{
        console.log(e.message);
        this.setState({restoHours: {}});
      })

      let progress = [];
      if (resto.csPhone && resto.csEmail){
        if(!_.isEmpty(this.state.restoHours)){
          progress.push({i: 0, step: 'step-1', label: 'Profile', error: false, tip: "The profile contains info like contact information", slug: "/resto/profile"});
        }
        else{
          progress.push({i: 0, step:'step-1', label:'Profile', error:true, tip: "The profile contains info like contact information", slug: "/resto/profile"})
          this.setState({error: "hasProfileError"})
        }
      }
      else{
        progress.push({i: 0, step:'step-1', label: 'Profile', error:true, tip: "The profile contains info like contact information", slug: "/resto/profile"})
        this.setState({error: "hasProfileError"})
      }

      const tokenInfo = await authentication.getRestoSquareTokenInfo(restoId);

      const tokenIsExpired = await nomtxtApi.isTokenExpired(tokenInfo.token_expires_at);
      //alert("expiry = " + tokenInfo.token_expires_at + " is expired = " + tokenIsExpired);
      if(!tokenInfo || tokenIsExpired ){
        this.setState({hasPaymentsError: true});
        progress.push({i: 1, step:'step-2', label:'Payments', error:true, tip: "Authorize square to let NomTxt add payments to your account.", slug: "/resto/setup/payments/square/2"})
        this.setState({error: "hasPaymentsError"})
      }
      else{
        progress.push({i: 1, step:'step-2', label:'Payments', error:false, tip: "Authorize square to let NomTxt add payments to your account.", slug: "/resto/setup/payments/square/2"})
      }

      if(resto.phoneNumber && resto.phoneNumber !== ''){
        progress.push({i: 2, step:'step-3', label:'Messages', error:false, tip: "Get a NomTxt number for your customers to text.", slug: "/resto/setup/messaging"})
      }
      else{
        progress.push({i: 2, step: 'step-3', label: 'Messages', error:true, tip: "Get a NomTxt number for your customers to text.", slug: "/resto/setup/messaging"})
        this.setState({error: "hasMessagesError"})
      }

      if(resto.status && resto.status === "active"){
        progress.push({i: 3, step:'step-4', label:'Enabled', error:false, tip: "When everything is go, enable NomTxt to start using the service.", slug: "/resto/enable"})
      } else {
        progress.push({i: 3, step: 'step-4', label: 'Enabled', error:true, tip: "When everything is go, enable NomTxt to start using the service.", slug: "/resto/enable"})
        this.setState({error: "hasEnabledError"})
      }

      if(resto.nomtxtEnabled && resto.nomtxtEnabled === 1){
        progress.push({i: 4, step: 'step-5', label:'Billing', error:false, tip: "Set up your billing preferences", slug: "/resto/billing"})
      } else{
        progress.push({i: 4, step: 'step-5', label: 'Billing', error:true, tip: "Set up your billing preferences", slug: "/resto/billing"})
        this.setState({error: "hasBillingError"})

      }

      this.setState({progress: progress});

    }



    render() {
        const { currentStepIndex } = this.state;
        const pg = this.state.progress;

        console.log("state = " + JSON.stringify(this.state));

        return (
            <div style={pgiStyle} className="rainbow-m-bottom_large rainbow-m-top_xx-large rainbow-p-bottom_large">
                <ProgressIndicator currentStepName={stepNames[currentStepIndex]} >
                {this.state.progress && pg.map(
                  function(p){
                    if(currentStepIndex >= p.i){
                      return(
                        <a href={p.slug} key={`${p.step}_a`}>
                        <ProgressStep name={p.step} label={p.label} hasError={p.error} tooltip={p.tip} key={`${p.step}_key`} />
                        </a>
                      )
                    }else{
                      return(

                        <ProgressStep name={p.step} label={p.label} hasError={p.error} tooltip={p.tip} key={`${p.step}_key`} />

                      )
                    }
                  })
                }
                </ProgressIndicator>
                <div className="rainbow-m-top_xx-large rainbow-align-content_center rainbow-flex_wrap">
                  <Typography variant="body2" style={progressText}>{`This is the ${steps[currentStepIndex]} step`}</Typography>
                </div>
                <div style={btnHidden} className="rainbow-m-top_xx-large rainbow-align-content_center rainbow-flex_wrap">
                    <Button
                        label="Back"
                        onClick={this.handleBackClick}
                        variant="neutral"
                        disabled={this.isBackDisabled()}
                        className="rainbow-m-horizontal_medium"

                    />
                    <Button
                        label="Next"
                        onClick={this.handleNextClick}
                        variant="brand"
                        disabled={this.isNextDisabled()}
                        className="rainbow-m-horizontal_medium"
                    />
                </div>
            </div>
        );
    }

    componentDidMount(){
      //csPhone and csEmail (resto) for profile also resto hours set (restohours)
      //alert("component did mount ran")
      this.findRestoStatus();
    }
}


export default RestoProgress;
