import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";
import { firestore } from "../../firebase";

import {
  Dialog,
  DialogTitle,
  Typography,
  Tooltip,
  IconButton,
  Tabs,
  Tab,
  Container,
  Paper,
  Grid
} from "@material-ui/core";
import CreditCardIcon from '@material-ui/icons/CreditCard';
import {
  Close as CloseIcon,
  AccountCircle as AccountCircleIcon,
  Palette as PaletteIcon,
  Link as LinkIcon,
  Security as SecurityIcon
} from "@material-ui/icons";

import{ Button, ButtonIcon, Input, RenderIf } from 'react-rainbow-components';
import SwipeableViews from "react-swipeable-views";

import AccountTab from "../AccountTab";
// import SecurityTab from "../SecurityTab";
import SquareFormTab from "../SquareFormTab";

const styles = (theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
  },

  tabs: {
    display: "initial",
  },
});

const tabs = [
  {
    key: "account",
    icon: <AccountCircleIcon />,
    label: "Account",
  },

  {
    key: "square",
    icon: <CreditCardIcon />,
    label: "Square",
  },
];

const initialState = {
  selectedTab: 0,
};

const tabHidingStyle = {
  display: "none"
}

const settingPageStyles = { maxWidth: 555, width: 500,  marginTop: 15, paddingBottom: 25 }


class SettingsPage extends Component {
  constructor(props) {
    super(props);

    this.state = initialState;
  }

  handleExited = () => {
    this.setState(initialState);
  };

  handleTabChange = (event, value) => {
    this.setState({
      selectedTab: value,
    });
  };

  handleIndexChange = (index) => {
    this.setState({
      selectedTab: index,
    });
  };
/*  componentDidMount() {
    firestore
    .collection("users")
    .doc(this.props.user.uid)
    .onSnapshot(
      (snapshot) => {
        const foo = snapshot.data();
        //console.log("SNAPSHOT UserData: " + JSON.stringify(snapshot.data()));
        this.setState({usrSuppl: foo});
      })
        // The user doesn’t have a data point, equivalent to not signed in.

          return;
    };
*/

  setRestoAdminTabDisplay = () => {
    if(this.props.userData && this.props.userData.userType === 'resto-admin'){
      return ({ display: "none" });
    }
    else{
      return ({ display: "inherit"});
    }
  }



  render() {
    // Styling
    const { classes } = this.props;

    // Dialog Properties
    const { dialogProps } = this.props;

    // Custom Properties
    const { user, userData, theme } = this.props;
    console.log("USERDATA ON SETTINGSPAGE as props:" + this.state.userData);

    const { userDataDb } = this.props;

    // Custom Functions
    const { openSnackbar } = this.props;

    // Custom Functions
    const { onDeleteAccountClick } = this.props;

    const { selectedTab } = this.state;
    const { hideCardTab } = this.setRestoAdminTabDisplay;

    return (
      <Container {...dialogProps} >
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        style={{ minHeight: '100vh' }}
       >
       <Grid item xs={8}>
        <Paper style={settingPageStyles}>
          <Typography variant="h6">Settings</Typography>

        <Tabs
          style={{ overflow: "initial", minHeight: "initial" }}
          indicatorColor="primary"
          textColor="primary"
          value={selectedTab}
          variant="fullWidth"
          onChange={this.handleTabChange}
        >
        <RenderIf isTrue={userData.usertype === 'resto-admin'}>
          {tabs.map((tab) => {
            return <Tab key={tab.key}
            icon={tab.icon}
            label={tab.label}
            style={hideCardTab}
            />;
          })}
        </RenderIf>
        <RenderIf isTrue={userData.usertype === 'regular'}>
          {tabs.map((tab) => {
            return <Tab key={tab.key}
            icon={tab.icon}
            label={tab.label}
            style={{display: "inherit"}}
            />;
          })}
        </RenderIf>
        </Tabs>

        <SwipeableViews
          index={selectedTab}
          onChangeIndex={this.handleIndexChange}
        >
          <AccountTab
            user={user}
            userData={userData}
            openSnackbar={openSnackbar}
            onDeleteAccountClick={onDeleteAccountClick}
          />

 { userData.userType === 'regular' && (
          <SquareFormTab
            user={user}
            userData={userData}
            openSnackbar={openSnackbar}
          />
  )}
        </SwipeableViews>


        </Paper>
        </Grid>
        </Grid>
      </Container>
    );
  }
}

SettingsPage.propTypes = {
  // Styling
  //classes: PropTypes.object.isRequired,

  // Custom Properties
  theme: PropTypes.object,
  user: PropTypes.object,
  userData: PropTypes.object,

  // Custom Functions
  //openSnackbar: PropTypes.func.isRequired,

  // Custom Events
  //onDeleteAccountClick: PropTypes.func.isRequired,
};

//export default withStyles(styles)(SettingsPage);
export default withRouter(SettingsPage);
