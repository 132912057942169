import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useForm } from 'react-hook-form';
import authentication from "../../services/authentication";
import nomtxtApi from "../../services/nomtxt";
import { Fab, Box, Typography, Paper, List, ListItem, Grid, Card, TextField, Button } from "@material-ui/core";
import { Link } from 'react-router-dom';
import "./hookform.css"

const btnStyle = {
    width: 300,
    height: 50,
    marginTop: 20,
    marginBottom: 50,
    paddingTop: 20,
    paddingBottom: 20,
    fontWeight: 600,
    backgroundColor: '#179fA3',
    marginLeft: 100,
    marginRight: 150

};

const btnStyleYellow = {
    width: 300,
    height: 50,
    marginTop: 20,
    marginBottom: 50,
    paddingTop: 20,
    paddingBottom: 20,
    fontWeight: 600,
    backgroundColor: '#fac20c',
    marginLeft: 100,
    marginRight: 150

};

const verilyisaytoyou = { display: 'none' }
const verifiedStyle = { color: 'green', display:'inline' }
const verifiedNumberStyle = { color: 'green' }
export default function SmsVerify(props) {
  //alert(JSON.stringify(props));
  const userData = props.userData;
  const user = props.user;
  //const restoId = userData.restoId;
  const { register, handleSubmit, reset } = useForm();
  const onSumbit = async data => {
    //alert(JSON.stringify(data));

    let checkVerifyStatus = await nomtxtApi.checkVerify(userData.phoneNumber, data.verificationCode)
    .then((result) => {
      console.log("the verify send result = " + result)

      authentication.smsVerified(true, props.user.uid);
      setVerifiedNumber("verified");
    })

    reset()

  };

  const [count, setCount] = useState(0);
  const [restoId, setRestoId] = useState('');
  const [restoName, setRestoName] = useState('');
  const [ccPhone, setCcPhone] = useState('');
  const [ccEmail, setCcEmail] = useState('');
  const [payments, setPayments] = useState([{square: "square"}]);
  const [paymentSelected, setPaymentsSelected] = useState();
  const [restoDetails, setRestoDetails] = useState({});
  const [showMsg, setShowMsg] = useState({ display: 'none' });
  const [verifiedNumber, setVerifiedNumber] = useState('');
  const [btnDisabled, setBtnDisabled] = useState(false);
  //alert(userData);



  useEffect( () => {
    //console.log(JSON.stringify("USERDATA "+props.userData));
    //alert(JSON.stringify(userData));
    if(userData.smsVerified){
      setVerifiedNumber('verified');
      setBtnDisabled(true);
    }

    // you can do async server request and fill up form
    /*
    setTimeout(() => {
      reset({
        restoName: "gallonhat",
        restoId: restoDetails.restoName,
      });
    }, 0);
    */
  }, [reset]);


  const handleOnBtnClick = async () => {
    let endpoint = 'https://verify-9851-0dqico.twil.io' + `/start-verify`;
    let obj = { to: "%2b" + userData.phoneNumber };
    let payload = JSON.stringify(obj);
    let options = {headers:{ "Content-type": "application/json" }};


    let verifyStatus = await nomtxtApi.startVerify(userData.phoneNumber)
    .then((result) => {
      console.log("the verify send result = " + result)
      if(result === true){
        setShowMsg({ display: 'block' });
      }
    })



  }

  return (
    <form onSubmit={handleSubmit(onSumbit)}>
      <h1>Verify your Mobile Number</h1>
      <Typography>You need to verify to confirm that this phone number is yours, and that it can recieve SMS: {userData.phoneNumber} <span style={verifiedNumberStyle}>{verifiedNumber}</span></Typography>
      <p>If you need to update your number, head back to your <Link to={{pathname: `/settings`, userData: props.userData }}>User Profile</Link> to update it.</p>
      <p>Click the yellow button below when you're ready to verify.</p>
      <Typography variant="body2" style={showMsg}>Verification Sent</Typography>
      <Button variant="contained" color="primary" style={btnStyleYellow} onClick={handleOnBtnClick} disabled={btnDisabled}>Send code</Button>
      <Typography variant="body2">Verification Code</Typography>
      <input name="verificationCode" ref={register} className="verify" />

      <Button type="submit" variant="contained" color="primary" style={btnStyle} disabled={btnDisabled}>Check Code</Button>
    </form>
  );
}
