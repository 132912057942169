import React, { Component } from "react";
import { SquarePaymentForm,
  CreditCardNumberInput,
  CreditCardExpirationDateInput,
  CreditCardPostalCodeInput,
  CreditCardCVVInput,
  CreditCardSubmitButton } from 'react-square-payment-form'
import 'react-square-payment-form/lib/default.css'
import './restoconfig.css'
import nomtxtApi from "../../services/nomtxt"
import authentication from "../../services/authentication"
import PromoCode from "../Forms/PromoCode.js"
import { Typography, Paper, Grid, Button } from "@material-ui/core";
import { withRouter, Link } from 'react-router-dom';
import UserCard from "../UserCard/UserCard.js"

const linkBtnStyle = { marginTop: 20 }
const testButton = { display: 'none' }

class SqRestoBilling extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      errorMessages: [],
      zip: [],
      restoId: this.props.restoId,
      promoResultVisible: false
    }
  }

  setUserData = () => {
    authentication
    .getUserData(this.props.user.uid)
    .then((val) => {
      this.setState({userData: val});
    })

  }


  async storeCustomerCardToSquare(payload){
    ///sq/v1/customer/:sq_cust_id/card/new
    //alert(JSON.stringify(payload));
    await nomtxtApi.storeCustomerCardToSquare(payload);
  }

  applyPromoCode = async (code) => {
    await nomtxtApi.checkPromoCode();
  }

  squarePaymentFormSandboxMode = () => {
    const isSandbox = (process.env.REACT_APP_NODE_ENV === 'production') ? false : true;
    return isSandbox;
  }



  render() {

    const testFuction = () => {

      //alert(JSON.stringify(this.state.userData));
      //alert(this.props.restoId);

    }

    const userData = this.state.userData;

    let cardNonceResponseReceived = async (errors, nonce, cardData, buyerVerificationToken) => {
      if (errors) {
        this.setState({ errorMessages: errors.map(error => error.message) })
        return
      }

      this.setState({ errorMessages: [] });

      //alert("nonce created: " + nonce + ", buyerVerificationToken: " + buyerVerificationToken );
      //alert("zip: " + this.state.pcval);
      //alert(JSON.stringify(this.props));
      //alert(cardData);

      // use same idempkey for all related transactions?
      //const iki = nomtxtApi.getIdempKey();
      const iki = nomtxtApi.getIdempKey();

      //console.log("iki = "+ iki);

      //we have a few things to do now.
      // 1. Create the customer on Square as a Customer of their favorite's Resto
      // 2. Save the curstomerId from Square into the user's nomtxt data
      // 3. Create a new card for the customer in the Resto's system
      // 4. Store the restults of that operation in the user's nomtxt data
      // 5. Make sure it all goes down without a hitch.

      let sq_cust_id;
      // const restoDetails = JSON.parse(this.props.userData.restos[0].details);
      //1. Create the customer on Square as a Customer NomTxt - this will happen as part of the new card funtion at api level

      // 3. Create a new card for the customer
      // nomtxt will impersonate a resto for this part
      const store_card_payload = {
        sq_cust_id: this.state.userData.squareId && this.state.userData.squareId !== 'undefined' ? this.state.userData.squareId : '',
        userId: this.props.user.uid,
        firstname: this.state.userData.firstname,
        lastname: this.state.userData.lastname,
        companyName: this.props.restoName,
        restoId: this.props.restoId,
        loc: process.env.REACT_APP_NODE_ENV === 'production' ?  "L4K6DYTQ0153P" : "LHM9CAMWA31Z5",
        cardnonce: nonce,
        token: buyerVerificationToken,
        zip: cardData.billing_postal_code,
        nomtxtCustomer: true,
        tel: this.state.userData.phoneNumber,
        email: this.state.userData.email,


      }

      //console.log("STORE_CARD_PAYLOAD" + JSON.stringify(store_card_payload));
      let store_card_result = this.storeCustomerCardToSquare(store_card_payload);
      //console.log(store_card_result);

      if(store_card_result){
        //this.destroy();
        //this.build();
        //SquarePaymentForm.destroy();
        //SquarePaymentForm.build();
        //this does get the window refreshed but stops all processess it kicked off

        setTimeout(() => {
            //window.location.reload()
            const redirLink = "/resto/billing";
            this.props.history.push(redirLink, { restoId: this.props.restoId });
        }, 3000);

      }
    }

    /*
       * callback function: inputEventReceived
       * Triggered when: visitors interact with SqPaymentForm iframe elements.
       */
      let inputEventReceived = (inputEvent) => {
        switch (inputEvent.eventType) {
          case 'focusClassAdded':
            //$('#focus-class-added').text(inputEvent.elementId);
            break;
          case 'focusClassRemoved':
            //$('#focus-class-removed').text(inputEvent.elementId);
            break;
          case 'errorClassAdded':
            //$('#error-class-added').text(inputEvent.elementId);
            break;
          case 'errorClassRemoved':
            //$('#error-class-removed').text(inputEvent.elementId);
            break;
          case 'cardBrandChanged':
            //$('#card-brand-changed').text(inputEvent.cardBrand);
            break;
          case 'postalCodeChanged':
            //console.log("POSTALCODE CHANGE: "+ inputEvent.postalCodeValue);
            break;
        }
      }


    let createVerificationDetails = () => {
      console.log("userData from createVerificationDetails= " + JSON.stringify(this.userData));
      //console.log("STATE " + this.state)
        return {
          //amount: '100.00',
          //currencyCode: 'USD',
          //intent: 'CHARGE',
          intent: 'STORE',
          billingContact: {
            familyName: this.state.userData.lastname,
            givenName: this.state.userData.firstname,
            email: this.state.userData.emailAddress || "",
            phone: this.state.userData.phoneNumber || ""

          },
        };
      }

    let postalCode = () => {
        const postalCode = '12345'; // your logic here
        return postalCode;
      }

      const restoid = this.props.restoId;
      let loc;
      if(process.env.REACT_APP_NODE_ENV === 'dev'){
        loc = "LHM9CAMWA31Z5";
      } else{
        loc = "L4K6DYTQ0153P";
      }

    return (
      <React.Fragment>
      <Grid
        container
        spacing={2}
        direction="column"
        alignItems="center"
       >
         <Grid item s={12}>
          <div>
            <div>
              <h3>Please store a card for app subscription billing.</h3>
              <Typography variant="body1">If you have a promo code please enter it here.</Typography>
              <Typography variant="body1">Promo code can only be applied once. Valid Credit Card is required following free promotion period.</Typography>
              <PromoCode userId={this.props.user.uid} restoId={this.props.restoId} />

              <Button size="small" onClick={testFuction} style={testButton}>Test Functions</Button>

              <SquarePaymentForm sandbox={this.squarePaymentFormSandboxMode}
                applicationId={process.env.REACT_APP_NOMTXT_SQUARE_APP_ID}
                locationId={loc}
                cardNonceResponseReceived={cardNonceResponseReceived}
                createVerificationDetails={createVerificationDetails}
                userData = {this.props}
                postalCode={this.postalCode}
                inputEventReceived={this.inputEventReceived}
              >

                <fieldset className="sq-fieldset">
                  <CreditCardNumberInput />
                  <div className="sq-form-third">
                    <CreditCardExpirationDateInput />
                  </div>

                  <div className="sq-form-third">
                    <CreditCardPostalCodeInput/>
                  </div>

                  <div className="sq-form-third">
                    <CreditCardCVVInput />
                  </div>
                </fieldset>

                <CreditCardSubmitButton>
                    Save Card Info
                </CreditCardSubmitButton>

              </SquarePaymentForm>


              <div className="sq-error-message">
                {this.state.errorMessages.map(errorMessage =>
                  <li key={`sq-error-${errorMessage}`}>{errorMessage}</li>
                )}
              </div>

            </div>
          </div>
          <h3> Saved Cards </h3>
          <UserCard user={this.props.user} userData={this.state.userData}/>
          <Grid
            container
            spacing={2}
            direction="column"
            alignItems="center"
           >
          <Button to={`/resto/status`} component={Link} disabled={this.state.btnDisabled} variant="contained" color="primary" style={linkBtnStyle}>Review Status</Button>
          </Grid>

        </Grid>
      </Grid>
    </React.Fragment>
    );
  }

    componentDidMount(){
      this.setUserData();

    }

}
//SqPayForm.propTypes = {
  // Properties
  //userData: PropTypes.object,

  // Functions
  //openSnackbar: PropTypes.func.isRequired,
//};

export default withRouter(SqRestoBilling);
