import React, {Component} from "react";

import PropTypes from "prop-types";
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardHeader, CardContent, CardMedia, CardActionArea, Paper, Grid, Typography, Link, List, ListItem, ListItemAvatar, ListItemSecondaryAction, Avatar, ListItemText} from "@material-ui/core";
import authentication from "../../services/authentication";
import{ Button, ButtonIcon, Accordion, AccordionSection   } from 'react-rainbow-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV, faPlus, faTrashAlt, faTrash, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import nomtxtApi from "../../services/nomtxt"


import {
  Favorite as FavoriteIcon,
  Delete as DeleteIcon,
  CreditCard as CreditCardIcon,
  Textsms as SmsIcon
} from "@material-ui/icons";
// Load the full build.
var _ = require('lodash');
// Load the core build.
var _ = require('lodash/core');
// Load the FP build for immutable auto-curried iteratee-first data-last methods.
var fp = require('lodash/fp');
// Load method categories.
var array = require('lodash/array');
var object = require('lodash/fp/object');

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));
/*
USER = {"user":{"lastname":"Eileen","username":"pingo","phoneNumber":"14754414133","firstname":"Alice","userType":"regular"}
,"resto":{"id":"wFSSMCVOklLgtpy3y120","data":{"favorite":"Large Gagh and a small Raktajino","price":7.55,"balance":469.79999999999995}
,"card":{"card_id":"nomnompretend","data":{"billing_address":{"postal_code":"12345","country":"US"}
  ,"exp_year":"2021","exp_month":"12","card_brand":"VISA","cardholder_name":"Alice Eileen","last_4":"1234","card_id":"nomnompretend"}}}}

*/
const initialState = {
  firstname: "",
  lastname: "",
  username: "",
  emailAddress: "",
  phoneNumber: "",
  performingAction: false,
  errors: null,
};

const accordianHeaderStyle = { fontWeight: 800 }
const btnDestructiveStyle = { maxHeight: 35, height: 35, width: 170, marginBottom: 15, marginTop: 15 };
const cardStyle = { maxWidth: 555, width: 500, marginLeft: 30, marginTop: 15, paddingLeft: 15, paddingRight: 15, paddingBottom: 15 };

class UserCard extends Component{

  constructor(props) {
    super(props);
    this.state = {
      inputField: ''
    };
  }

  getUserCards = () => {
    let cardset = []
    authentication
    .getUserStoredCardsPointers(this.props.user.uid)
    .then((cards) => {
      //alert("cards = "+ JSON.stringify(cards));
      var cardset = _.values(cards);
      //alert("cardset = "+ JSON.stringify(cardset));
      this.setState({cardset: cardset});
      this.setState({cards: cards});
      return;
    })

  }

  deleteBtnClick = async (cardId) => {
    let rela = await nomtxtApi.deleteUserCard('square', this.state.cardset[0].restoId, this.state.cardset[0].sqCustId, cardId)
    .then(() => {
      console.log("did it now update the display");
      let data = this.state.cardset;
      //let newdata = data.filter( value => (value.v !== e.currentTarget.id) );
      let newdata = data.filter( i => ( i.id !== cardId) );
      this.setState({cardset: newdata });
    })
    .catch((e)=>{
      console.log(e.message);
    })

  }


  render(){


    const { userData, user } = this.props;
    const userCards = this.state.cardset;
    //alert(userCards);

    return (
      <React.Fragment>
        { userCards && userCards ?
          <Grid container spacing={1} key={userData.phoneNumber}>
            {userCards.map((cardi) => (

              <Grid item xs key={`grid_${cardi.id}`}>
                <Card key={`card_${cardi.id}`}>
                  <CardContent>
                    <Typography variant="h5">{cardi.card_brand}</Typography>
                    <Typography variant="h5">{cardi.last_4}</Typography>
                    <Typography variant="h5">{cardi.exp_month}</Typography>
                    <Typography variant="h5">{cardi.exp_year}</Typography>
                  </CardContent>

                        <Button variant="destructive" style={btnDestructiveStyle} onClick={() => { this.deleteBtnClick(`${cardi.id}`) }}>Delete Card . <FontAwesomeIcon icon={faTrash} className="rainbow-m-left_medium" />
                        </Button>

                </Card>
              </Grid>
            ))}
            </Grid>
            :
            <>
            <Typography variant="h6">When you have saved a card, it's last-4 will be listed here.</Typography>
            <Button to={`/user/payments/square`} component={Link} disabled={this.state.btnDisabled} variant="brand" color="primary">Add a Card</Button>


            </>
          }
      </React.Fragment>
    );
  }

  componentDidMount() {
    this.getUserCards();

  }


}

UserCard.propTypes = {
  //userCard: PropTypes.object.isRequired,
};

export default UserCard;
