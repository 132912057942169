import React, { Component } from "react";
import { SquarePaymentForm,
  CreditCardNumberInput,
  CreditCardExpirationDateInput,
  CreditCardPostalCodeInput,
  CreditCardCVVInput,
  CreditCardSubmitButton } from 'react-square-payment-form'
import 'react-square-payment-form/lib/default.css'
import nomtxtApi from "../../services/nomtxt"

import {
  Button,

} from "@material-ui/core";

class SqPayFormTest extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      errorMessages: [],
      zip: [],

    }
  }

  render(){

    return(
      <React.Fragment>

      </React.Fragment>

    )

  }

  componentDidMount() {
    alert(nomtxtApi.getApiVersion());

  }


}

export default SqPayFormTest;
