import React, { Component } from "react";
import { SquarePaymentForm,
  CreditCardNumberInput,
  CreditCardExpirationDateInput,
  CreditCardPostalCodeInput,
  CreditCardCVVInput,
  CreditCardSubmitButton } from 'react-square-payment-form'
import 'react-square-payment-form/lib/default.css'
import nomtxtApi from "../../services/nomtxt"
import { withRouter } from 'react-router-dom';
import authentication from "../../services/authentication";

import {
  Button,

} from "@material-ui/core";

class SqPayForm extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      errorMessages: [],
      zip: [],
      restoId: this.props.restoId
    }
  }
/*
// handled inside the create card function
  async createSquareCustomer(payload){
    //alert("payload = "+ JSON.stringify(payload));
    let newCustResult = await nomtxtApi.createSquareCustomer(payload)
    .then((cust) =>{
      //alert(JSON.stringify(cust));
      this.setState({sq_cust_id: cust.data.id})
    })
    console.log("new customer result= " + JSON.stringify(newCustResult));
    //this.setState({sq_cust_id: newCustResult.data.id})

    return newCustResult;
  }
*/
  async storeCustomerCardToSquare(payload){
    ///sq/v1/customer/:sq_cust_id/card/new
    await nomtxtApi.storeCustomerCardToSquare(payload);
  }

  getSquareCustomerId = async (restoId) => {
    //alert("this.props.user.uid =" + this.props.user.uid + " arg restoId= "+ restoId )
    authentication
    .getSquareCustomerId(this.props.user.uid, restoId)
    .then((data) => {
      this.setState({squareCustomerId: data})
    })
    .catch(e=>console.log(e.message))

  }

  squarePaymentFormSandboxMode = () => {
    const isSandbox = (process.env.REACT_APP_NODE_ENV === 'production') ? false : true;
    return isSandbox;
  }



  render() {

    const testFuction = () => {

      //alert(JSON.stringify(this.props.userdata));
      //alert(this.props.restoId);

    }

    let cardNonceResponseReceived = async (errors, nonce, cardData, buyerVerificationToken) => {
      if (errors) {
        this.setState({ errorMessages: errors.map(error => error.message) })
        return
      }

      this.setState({ errorMessages: [] });

      //alert("nonce created: " + nonce + ", buyerVerificationToken: " + buyerVerificationToken );
      //alert("zip: " + this.state.pcval);
      //alert(JSON.stringify(this.props));

      // use same idempkey for all related transactions?
      const iki = nomtxtApi.getIdempKey();

      //console.log("iki = "+ iki);

      //we have a few things to do now.
      // 1. Create the customer on Square as a Customer of their favorite's Resto
      // 2. Save the curstomerId from Square into the user's nomtxt data
      // 3. Create a new card for the customer in the Resto's system
      // 4. Store the restults of that operation in the user's nomtxt data
      // 5. Make sure it all goes down without a hitch.

      let sq_cust_id;
      // const restoDetails = JSON.parse(this.props.userdata.restos[0].details);
      //1. Create the customer on Square as a Customer of their favorite's Resto
/*      if(!this.props.userdata.squareId){

        const create_customer_payload = {
          tel: this.props.userdata.phoneNumber,
          loc: this.props.locationId,
          idempotency_key: iki,
          restoId: this.props.restoId,
          email: this.props.userdata.email,
          userId: this.props.user.uid,
          firstname: this.props.userdata.firstname,
          lastname: this.props.userdata.lastname
        };

        let sq_cust_id;
        let newCustResult = this.createSquareCustomer(create_customer_payload)
        .then((cust) => {
          //alert(JSON.stringify(cust));
          sq_cust_id = cust.id;
        })
        .then
        //let sq_cust_id = newCustResult.data.id;
      }
      else{
        let sq_cust_id = this.props.userdata.squareId;
      }
*/
      // 3. Create a new card for the customer in the Resto's system
      const store_card_payload = {
        sq_cust_id: this.state.squareCustomerId ? this.state.squareCustomerId : '',
        userId: this.props.user.uid,
        firstname: this.props.userdata.firstname,
        lastname: this.props.userdata.lastname,
        companyName: null,
        restoId: this.props.restoId,
        loc: this.props.locationId,
        cardnonce: nonce,
        token: buyerVerificationToken,
        zip: cardData.billing_postal_code,
        nomtxtCustomer: false,
        tel: this.props.userdata.phoneNumber,
        email: this.props.userdata.email


      }

      //console.log("STORE_CARD_PAYLOAD" + JSON.stringify(store_card_payload));
      let store_card_result = this.storeCustomerCardToSquare(store_card_payload);
      //console.log(store_card_result);

      if(store_card_result){
        //this.destroy();
        //this.build();
        //SquarePaymentForm.destroy();
        //SquarePaymentForm.build();
        //this does get the window refreshed but stops all processess it kicked off

        setTimeout(() => {
            //window.location.reload()
            const redirLink = "/user/payments/storedCards/?r=" + this.props.restoId + "&l=" + this.props.locationId;
            this.props.history.push(redirLink, {restoId: this.props.restoId, locationId: this.props.locationId});
        }, 3000);

      }
    }

    /*
       * callback function: inputEventReceived
       * Triggered when: visitors interact with SqPaymentForm iframe elements.
       */
      let inputEventReceived = (inputEvent) => {
        switch (inputEvent.eventType) {
          case 'focusClassAdded':
            //$('#focus-class-added').text(inputEvent.elementId);
            break;
          case 'focusClassRemoved':
            //$('#focus-class-removed').text(inputEvent.elementId);
            break;
          case 'errorClassAdded':
            //$('#error-class-added').text(inputEvent.elementId);
            break;
          case 'errorClassRemoved':
            //$('#error-class-removed').text(inputEvent.elementId);
            break;
          case 'cardBrandChanged':
            //$('#card-brand-changed').text(inputEvent.cardBrand);
            break;
          case 'postalCodeChanged':
            //console.log("POSTALCODE CHANGE: "+ inputEvent.postalCodeValue);
            break;
        }
      }


    let createVerificationDetails = () => {
      //console.log("userdata from createVerificationDetails= " + JSON.stringify(this.userData));
      //console.log("STATE " + this.state)
        return {
          //amount: '100.00',
          //currencyCode: 'USD',
          //intent: 'CHARGE',
          intent: 'STORE',
          billingContact: {
            familyName: this.props.userdata.lastname,
            givenName: this.props.userdata.firstname,
            email: this.props.userdata.emailAddress || "",
            phone: this.props.userdata.phoneNumber || ""

          },
        };
      }

    let postalCode = () => {
        const postalCode = '12345'; // your logic here
        return postalCode;
      }

      const restoid = this.props.restoId;
      const loc = this.props.locationId;


    return (
      <div>
      <div >
        <h3>Add a card to your account</h3>
        <Button size="small" onClick={testFuction}>Test Functions</Button>

        <SquarePaymentForm sandbox={this.squarePaymentFormSandboxMode}
          applicationId={process.env.REACT_APP_NOMTXT_SQUARE_APP_ID}
          locationId={loc}
          cardNonceResponseReceived={cardNonceResponseReceived}
          createVerificationDetails={createVerificationDetails}
          userData = {this.props}
          postalCode={this.postalCode}
          inputEventReceived={this.inputEventReceived}
        >

          <fieldset className="sq-fieldset">
            <CreditCardNumberInput />
            <div className="sq-form-third">
              <CreditCardExpirationDateInput />
            </div>

            <div className="sq-form-third">
              <CreditCardPostalCodeInput/>
            </div>

            <div className="sq-form-third">
              <CreditCardCVVInput />
            </div>
          </fieldset>

          <CreditCardSubmitButton>
              Save Card Info
          </CreditCardSubmitButton>

        </SquarePaymentForm>


        <div className="sq-error-message">
          {this.state.errorMessages.map(errorMessage =>
            <li key={`sq-error-${errorMessage}`}>{errorMessage}</li>
          )}
        </div>

      </div>
      </div>
    )
  }

  componentDidMount(){
    let qsParams = new URLSearchParams(this.props.location.search);
    let restoId = '';
    let locationId = '';
    if(qsParams.get("r")){
      restoId = qsParams.get("r");
      this.setState({restoId: restoId});
    }

    if(qsParams.get("l")){
      locationId = qsParams.get("l");
      this.setState({locationId: locationId});
    }

    this.getSquareCustomerId(restoId);
    //this.getLocationList();
  }


}
//SqPayForm.propTypes = {
  // Properties
  //userData: PropTypes.object,

  // Functions
  //openSnackbar: PropTypes.func.isRequired,
//};

export default withRouter(SqPayForm);
