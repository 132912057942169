import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useForm, Controller } from 'react-hook-form';
import authentication from "../../services/authentication";
import { Fab, Box, Typography, Paper, List, ListItem, Grid, TextField, Card, CardActionArea, CardContent, Button } from "@material-ui/core";

const labelStyle = {color: 'black'}
const lFaves = { width: 550, maxWidth: 555, marginLeft: 30, marginTop: 15, paddingLeft: 15, paddingRight: 15, paddingBottom: 15 };
const btnStyle = {
    width: 100,
    height: 36,
    marginTop: 20,
    paddingTop: 15,
    paddingBottom: 6,
    paddingLeft: 30,
    paddingRight: 'auto',
    paddingBottom: 16,
    fontWeight: 500,
    backgroundColor: '#00E5FF',
    marginLeft: 180,
    marginRight: 140,
    color: 'inherit'
};

export default function RestoTermsCheck(props) {
  //alert(JSON.stringify(props));
  const userData = props.userData;
  const user = props.user;
  //const restoId = userData.restoId;
  const { register, handleSubmit, reset, control } = useForm();
  const onSubmit = values => {
    //alert(JSON.stringify(checkDisabled));

    authentication.setRestoTermsEnable(userData.restoId, values, checkDisabled)
    .then((value)=>{

    })

  };

  const [count, setCount] = useState(0);
  const [restoId, setRestoId] = useState('');
  const [values, setValues] = useState([]);
  const [checkDisabled, setCheckDisabled] = useState(false);
  const [acceptedStyle, setAcceptedStyle] = useState({display: 'none'})
  //alert(userData);
  useEffect( () => {
    //console.log(JSON.stringify("USERDATA "+props.userData));
    //alert(JSON.stringify(userData));

    let userResto = userData.restoId;
    setRestoId({ userResto });

      authentication
        .getRestoDetails(userData.restoId)
        .then((value) => {
          //alert("restoDetails on terms form" + JSON.stringify(value));
          if(value.terms && value.privacy){
            setCheckDisabled(true);
            setAcceptedStyle({display: 'block'});
          }
          reset({
            privacy: value.privacy,
            terms: value.terms,
            enableNomTxt: value.nomtxtEnabled
          });
        })
        .catch((reason) => {
          console.log("ERROR getting pointer =" + reason);
        })

    // you can do async server request and fill up form
    /*
    setTimeout(() => {
      reset({
        restoName: "gallonhat",
        restoId: restoDetails.restoName,
      });
    }, 0);
    */
  }, [reset]);

  return (
    <React.Fragment>

    <form onSubmit={handleSubmit(onSubmit)}>
    <Card>
      <CardContent>
    <div>
        <label htmlFor="enableNomTxt" style={labelStyle}>Enable NomTxt</label>
        <input
          type="checkbox"
          name="enableNomTxt"
          placeholder="luo"
          value="yes"
          ref={register}
        />
      </div>
      <br/>
      <div>
          <p style={acceptedStyle}>You have already accepted the Terms and Privacy agreements.</p>
          <label htmlFor="privacy" style={labelStyle}>Privacy</label>
          <input
            type="checkbox"
            name="privacy"
            placeholder="luo"
            value="yes"
            ref={register}
            disabled={checkDisabled}
          />
        </div>

        <div>
            <label htmlFor="terms" style={labelStyle}>Terms</label>
            <input
              type="checkbox"
              name="terms"
              placeholder="luo"
              value="yes"
              ref={register}
              disabled={checkDisabled}
            />
          </div>
         </CardContent>
         <CardActionArea>
            <Button type="submit" style={btnStyle}>Save</Button>
         </CardActionArea>
    </Card>
    </form>

    </React.Fragment>
  );
}
