import axios from "axios";
import firebase, { analytics, auth, firestore, storage } from "../firebase";
import moment from "moment";
import randomBytes from "randombytes";
import spacetime from "spacetime";
import authentication from "./authentication";
import md5 from "md5";


const nomtxtApi = {};

//const nomtxtApiUrlRoot = "https://boomflex-nomtext.web.app";

nomtxtApi.getIdempKey = () => {
  console.log("nomtxtApi.getIdempKey");
    let idempkey = randomBytes(45).toString("hex");
    return idempkey;
};

nomtxtApi.addZero = (i) => {
  if (i < 10) {
    i = "0" + i;
  }
  return i;
}

nomtxtApi.getEnv = () => {
  if(process.env.REACT_APP_NODE_ENV && process.env.REACT_APP_NODE_ENV === 'production'){
    return "production";
  }
  if(process.env.REACT_APP_NODE_ENV && process.env.REACT_APP_NODE_ENV === 'staging'){
    return "staging";
  }
  else{
    return "dev";
  }
}

nomtxtApi.isTokenExpired = (date) => {
  const st = new spacetime();
  if( st.isAfter(date) ){
    return true;
  }
  return false;
}

nomtxtApi.getApiUrlRoot = () => {
  //alert(functions.config().nomtxt.environment);
  const nomtxtApiUrlRootDev = "http://localhost:5000/api";
  //const nomtxtApiUrlRootProd = "https://us-central1-boomflex-nomtext.cloudfunctions.net/api";
  const nomtxtApiUrlRootProd = "https://nomtxt.com/api";
  //const nomtxtApiUrlRootProd = "https://api.nomtxt.com";https://us-central1-boomflex-nomtext.cloudfunctions.net/
  let apiRoot;
  //if (process.env.NODE_ENV && process.env.NODE_ENV === 'production') {
  if(nomtxtApi.getEnv() == 'production'){
    apiRoot = nomtxtApiUrlRootProd;
  } else {
    apiRoot = nomtxtApiUrlRootDev;
  }
  return apiRoot;
}

nomtxtApi.getApiVersion = (v) => {
  const nomTxtApiV1 = "/v1";  //uses square api v2 release august 2020
  let apiVers;
  if (process.env.NODE_ENV && process.env.NODE_ENV === 'production') {
    (v === "1") ? apiVers = nomTxtApiV1 : apiVers = nomTxtApiV1;
  } else {
    (v === "1") ? apiVers = nomTxtApiV1 : apiVers = nomTxtApiV1;
  }
  return apiVers;
}

const nomtxtApiUrlRoot = nomtxtApi.getApiUrlRoot();
const nomtxtApiVersion = "/v1";
nomtxtApi.getApiUrl = () => {
  return nomtxtApiUrlRoot + nomtxtApiVersion;
}

// provides a generic oclock for checking open hours
nomtxtApi.getUtcHoursFromDateString = (datestr) => {
  if (!datestr || datestr === ""){
    return (false);
  }

  const makeDate = new Date(datestr);

  let mins =  nomtxtApi.addZero(makeDate.getUTCMinutes());
  if(mins.length === 1){
    mins = '0' + mins.toString();
  }

  let hours =  nomtxtApi.addZero(makeDate.getUTCHours());
  if(hours.length === 1){
    hours = '0' + hours.toString();
  }


  //let utcTime = makeDate.getUTCHours() + ":" + makeDate.getUTCMinutes();
  let utcTime = hours + ":" + mins;
  return(utcTime);

}

// provides a generic oclock for checking open hours
nomtxtApi.getHoursFromDateString = (datestr) => {
  if (!datestr || datestr === ""){
    return (false);
  }

  const makeDate = new Date(datestr);
  //alert("makedate = " + makeDate);

  let mins =  nomtxtApi.addZero(makeDate.getMinutes());
  if(mins.length === 1){
    mins = '0' + mins.toString();
  }

  let hours =  nomtxtApi.addZero(makeDate.getHours());
  if(hours.length === 1){
    hours = '0' + hours.toString();
  }


  //let utcTime = makeDate.getUTCHours() + ":" + makeDate.getUTCMinutes();
  let utcTime = hours + ":" + mins;
  return(utcTime);

}
/*
nomtxtApi.getUtcHours = () => {


}
*/
nomtxtApi.convertCentsToDollars = (cents) => {
    if (!cents) {
      return("cents is required");
    };
    if(isNaN(cents)){
      cents = parseFloat(cents);
    }
    let dollars = cents / 100;
    return dollars;
}

nomtxtApi.saltToken = async () => {
    /**
     * salt is optional, if not provided it will be set to current timestamp
     */
     const currentUser = auth.currentUser;
     if (!currentUser) {
      return ("No current user");
     }

     let tiki = await authentication
       .getUserTokenId()
       .then((token)=>{
         //alert("user token: " + token.token);
         return token;
       })
       .catch((e)=>{
         //alert(e);
       })

    const salt = new Date().getTime();
    const tasty = salt + tiki.token;
    let tokenono = md5(tasty);
    //alert(tokenono);
    let yolo = await firestore.collection('users').doc(currentUser.uid).update({tokenSalt: salt, tokenono: tokenono});

    return tokenono;
  }


nomtxtApi.startVerify = async (tel) => {

      const currentUser = auth.currentUser;
      if (!currentUser) {
        return(new Error("No current user"));
        //return;
      };

      let tiki = await authentication
      .getUserTokenId()
      .then((token)=>{
        return token;
      })
      .catch((e)=>{
        //alert(e);
      })

      const nomtxtMethod = "/msgs/numbers/verifyStart";
      const endpoint = nomtxtApi.getApiUrl() + nomtxtMethod;
      //const endpoint = 'http://localhost:5000/api/v1' + nomtxtMethod;
      let options = {headers:{ authorization: `Bearer ${tiki.token}` }};
      let payload = { tel: tel}


      let axpo = await axios.post(endpoint, payload, options)
      .then(function (value) {
        return(value);
      })
      .catch(function (value) {
        return(value);
      });
}


nomtxtApi.checkVerify = async (tel, code) => {

      const currentUser = auth.currentUser;
      if (!currentUser) {
        return(new Error("No current user"));
        //return;
      };

      let tiki = await authentication
      .getUserTokenId()
      .then((token)=>{
        return token;
      })
      .catch((e)=>{
        //alert(e);
      })

      const nomtxtMethod = "/msgs/numbers/checkVerify";
      const endpoint = nomtxtApi.getApiUrl() + nomtxtMethod;
      //const endpoint = 'http://localhost:5000/api/v1' + nomtxtMethod;
      let options = {headers:{ authorization: `Bearer ${tiki.token}` }};
      let payload = { tel: tel, code: code }


      let axpo = await axios.post(endpoint, payload, options)
      .then(function (value) {
        return(value);
      })
      .catch(function (value) {
        return(value);
      });
      return;
}


nomtxtApi.getCurrentUserToken = () => {
  return new Promise((resolve, reject) => {

  const currentUser = auth.currentUser;
  if (!currentUser) {
    reject(new Error("No current user"));
    return;
  }

  currentUser
    .getIdTokenResult()
    .then((idTokenResult) => {
      //console.log("PADUKCHA = " + idTokenResult.token);
      resolve( idTokenResult.token)
    })
    .catch((e)=>{
      reject(e)
    })
  })
}

nomtxtApi.searchPhoneByAreaCode = (areaCode) =>{
  console.log("searchPhoneByAreaCode");
  return new Promise( async (resolve, reject) => {
    const currentUser = auth.currentUser;
    if (!currentUser) {
      reject(new Error("No current user"));
      return;
    };

    let tiki = await authentication
    .getUserTokenId()
    .then((token)=>{
      //alert("i got a song " + JSON.stringify(token));
      return token;
    })
    .catch((e)=>{
      //alert(e);
    })

    const nomtxtMethod = "/msgs/numbers/search/" + areaCode;
    const endpoint = nomtxtApi.getApiUrl() + nomtxtMethod;
    let options = {headers:{ authorization: `Bearer ${tiki.token}` }};


    let axpo = axios.get(endpoint, options)
    .then(function (value) {
      resolve(value);
    })
    .catch(function (value) {
      reject(value);
    });

  })
}


nomtxtApi.registerSelectedMsgTel = (obj) => {
  console.log("registerSelectedMsgTel");
  return new Promise(async (resolve, reject) => {
    if (!obj.tel) {
      reject(new Error("Resto phone is required"));
      return;
    };

    const currentUser = auth.currentUser;
    if (!currentUser) {
      reject(new Error("No current user"));
      return;
    }

    const restoRef = firestore.collection('restos').doc(obj.restoId);
    let doc = await restoRef.get();
    console.log("restoref = " + obj.restoId)
    if (!doc.exists) {
      console.log('No such document!');
    }
    else {
      console.log('Document data:', doc.data());
      let admins = doc.data().adminIds;

      let isAdmin = false;
      for(let i = 0; i < admins.length; i++){
        if (obj.userId === admins[i]){
          isAdmin = true;
          break;
        }
      }

      //alert("isadmin = " + isAdmin);
      if(!isAdmin){
        reject("this user is not an admin of that restuarant");
        return;
      }
    }

    let tiki = await authentication
    .getUserTokenId()
    .then((token)=>{
      return token;
    })
    .catch((e)=>{
      console.log("couldn't find user token")
    })

    const nomtxtMethod = "/msgs/numbers/provision";
    const endpoint = nomtxtApi.getApiUrl() + nomtxtMethod;
    let options = {headers:{ authorization: `Bearer ${tiki.token}` }};

    let axpo = axios.post(endpoint, obj, options)
    .then(function (value) {
      resolve(value);
    })
    .catch(function (value) {
      reject(value);
    });



  });
}

nomtxtApi.DELETEcreateSquareCustomer = async (obj) =>  {
  console.log("createSquareCustomer");
  return new Promise((resolve, reject) => {
    if (!obj.tel) {
      reject(new Error("Customer phone is required"));
      return;
    };
    if (!obj.loc) {
      reject(new Error("Resto location id is required"));
      return;
    };
    if (!obj.idempotency_key) {
      reject(new Error("idempotency_key is required"));
      return;
    };
    if (!obj.restoId) {
      reject(new Error("Resto id is required"));
      return;
    };
    if (!obj.email) {
      reject(new Error("email is required"));
      return;
    };
    if (!obj.userId) {
      reject(new Error("Customer nomtxt id is required"));
      return;
    };
    if (!obj.firstname) {
      reject(new Error("firstname is required"));
      return;
    };
    if (!obj.lastname) {
      reject(new Error("lastname is required"));
      return;
    };

    const currentUser = auth.currentUser;
    if (!currentUser) {
      reject(new Error("No current user"));

      return;
    }

    let tiki = authentication
    .getUserTokenId()
    .then((token)=>{
      return token;
    })
    .catch((e)=>{
      //alert(e);
    })

    const nomtxtMethod = "/paybros/square/customer/new";
    const endpoint = nomtxtApi.getApiUrl() + nomtxtMethod;
    let options = {headers:{ authorization: `Bearer ${tiki.token}` }};
    let payload = {
      tel: obj.tel,
      loc: obj.loc,
      idempotency_key: obj.idempotency_key,
      restoId: obj.restoId,
      email: obj.email,
      userId: obj.userId,
      firstname: obj.firstname,
      lastname: obj.lastname
    };

    let axpo = axios.post(endpoint, payload, options)
    .then(function (value) {
      resolve(value);
    })
    .catch(function (value) {
      reject(value);
    });




  });
}


nomtxtApi.DELETEcreateSquareRestomer = async (obj) =>  {
  console.log("createSquareRestomer");
  return new Promise((resolve, reject) => {
    if (!obj.tel) {
      reject(new Error("Customer phone is required"));
      return;
    };
    if (!obj.idempotency_key) {
      reject(new Error("idempotency_key is required"));
      return;
    };
    if (!obj.restoId) {
      reject(new Error("Resto id is required"));
      return;
    };
    if (!obj.email) {
      reject(new Error("email is required"));
      return;
    };
    if (!obj.userId) {
      reject(new Error("Customer nomtxt id is required"));
      return;
    };
    if (!obj.companyName) {
      reject(new Error("company name is required"));
      return;
    };
    if (!obj.firstname) {
      reject(new Error("firstname is required"));
      return;
    };
    if (!obj.lastname) {
      reject(new Error("lastname is required"));
      return;
    };

    const currentUser = auth.currentUser;
    if (!currentUser) {
      reject(new Error("No current user"));

      return;
    }

    let tiki = authentication
    .getUserTokenId()
    .then((token)=>{
      return token;
    })
    .catch((e)=>{
      //alert(e);
    })

    const nomtxtMethod = "/paybros/square/restomer/new";
    const endpoint = nomtxtApi.getApiUrl() + nomtxtMethod;
    let options = {headers:{ authorization: `Bearer ${tiki.token}` }};
    let payload = {
      tel: obj.tel,
      idempotency_key: obj.idempotency_key,
      restoId: obj.restoId,
      email: obj.email,
      userId: obj.userId,
      company_name: obj.companyName,
      firstname: obj.firstname,
      lastname: obj.lastname
    };

    let axpo = axios.post(endpoint, payload, options)
    .then(function (value) {
      resolve(value);
    })
    .catch(function (value) {
      reject(value);
    });
  });
}


///sq/v1/customer/:sq_cust_id/card/new
//? '{"card":[{"cardnonce":"cnon:CBASEDwFcpYKxOHo0mgu0GIInVA","zip":"12345","token":"verf:CBASEH5zUdc1HzOWV5iZE9aAlr8"}],"resto":[{"restoId":"HvhrYPNyZRjdwj7FLfCF","loc":"LVDVWB1QBB6B6"}]}
nomtxtApi.storeCustomerCardToSquare = async (obj) =>  {
  console.log("storeCustomerCardToSquare");
  return new Promise(async (resolve, reject) => {

    const currentUser = auth.currentUser;
    if (!currentUser) {
      reject(new Error("No current user"));

      return;
    }

    if (!obj.restoId) {
      reject(new Error("restoId is required"));

      return;
    }

    //alert("payload = " + JSON.stringify(obj));

    let tiki = await authentication
    .getUserTokenId()
    .then((token)=>{
      return token;
    })
    .catch((e)=>{
      //alert(e);
    })

      let endpoint = nomtxtApi.getApiUrl() + `/paybros/square/customer/card/new`;
      let payload = obj;
      let options = {headers:{ authorization: `Bearer ${tiki.token}` }};

      let axpo = axios.post(endpoint, payload, options)
      .then(function (value) {

        resolve(value);
      })
      .catch(function (value) {
        //console.log("SAVE CUSTOMER CARD ERROR: " + value);
        reject(value);
        //deferred.reject(error);
      });

  });
}

nomtxtApi.deleteUserCard = (provider, restoId, sqCustId, cardId) => {
  console.log("deleteUserCard")
  return new Promise(async (resolve, reject) => {
    if (!sqCustId || !cardId) {
      reject(new Error("Square customerid and card id are required is required"));
      return;
    };

    const currentUser = auth.currentUser;
    if (!currentUser) {
      reject(new Error("No current user"));
      return;
    }

    let tiki = await authentication
    .getUserTokenId()
    .then((token)=>{
      return token;
    })
    .catch((e)=>{
      return(e);
    })

    const payload = {
      provider: provider,
      sqCustId: sqCustId,
      cardId: cardId,
      restoId: restoId
    }

    let endpoint = nomtxtApi.getApiUrl() + `/paybros/square/delete/card/customer`;
    //alert(endpoint);
    let options = {headers:{ authorization: `Bearer ${tiki.token}` }};

    let axpo = axios.post(endpoint, payload, options)
    .then(function (value) {

      resolve(value);
    })
    .catch(function (value) {
      //console.log("SAVE CUSTOMER CARD ERROR: " + value);
      reject(value);
      //deferred.reject(error);
    });

  }); //promise
}




// /api/v1/resto/locations/list/:restoId
nomtxtApi.getRestoLocations = (restoId) =>  {
  console.log("getRestoLocations");
  return new Promise(async (resolve, reject) => {
    if (!restoId) {
      reject(new Error("Resto Id is required"));
      return;
    };
    const currentUser = auth.currentUser;
    if (!currentUser) {
      reject(new Error("No current user"));

      return;
    }

    let tiki = await authentication
      .getUserTokenId()
      .then((token)=>{
        return token;
      })
      .catch((e)=>{
        //alert(e);
      })


    let endpoint = nomtxtApi.getApiUrl() + `/resto/locations/list/${restoId}`;
    let options = {headers:{ authorization: `Bearer ${tiki.token}` }};

    let axpo = axios.get(endpoint, options)
    .then(function (value) {

      resolve(value);
    })
    .catch(function (value) {
      console.log("QAPLA' GET RESTO ITEMS: " + JSON.stringify(value));
      reject(value);
      //deferred.reject(error);
    });

    });
}





// /api/v1/resto/locations/list/:restoId
nomtxtApi.getRestoLocationsById = async (locationId, restoId) =>  {
  console.log("getRestoLocationsById");
  return new Promise((resolve, reject) => {
    if (!locationId) {
      reject(new Error("Location Id is required"));
      return;
    };

    const currentUser = auth.currentUser;
    if (!currentUser) {
      reject(new Error("No current user"));

      return;
    }

    const token = currentUser
      .getIdTokenResult()
      .then((idTokenResult) => {
        return idTokenResult.token;
      })


    const nomtxtMethod = "/resto/locations/"
    const endpoint = nomtxtApiUrlRoot + nomtxtApiVersion + nomtxtMethod + locationId + "/" + restoId;
    // console.log("ENDPOINT: " + endpoint);
    const options = {
      headers: {
        "Content-Type": "application/json",
        "Authorization": token
      }
    };

    axios.get(endpoint, {}, options)
    .then(function (value) {
      //console.log("QAPLA' GET RESTO ITEMS: " + value);
      resolve(value);
    })
    .catch(function (value) {
      //console.log("ERROR in GET RESTO ITEMS: " + value);
      reject(value);
      //deferred.reject(error);
    });
  });
}


// /api/v1/resto/:restoId/catalog
nomtxtApi.getRestoItems = async (restoId) =>  {
  console.log("getRestoItems");
  return new Promise(async(resolve, reject) => {
    if (!restoId) {
      reject(new Error("Resto Id is required"));
      return;
    };

    const token = await nomtxtApi.getCurrentUserToken();

    let url = nomtxtApi.getApiUrl() + "/resto/"+ restoId +"/catalog";
    let lista = await axios.get(url, {headers:
      { authorization: `Bearer ${token}` }})
      .then(function (value) {
        //console.log("QAPLA' GET RESTO ITEMS: " + value);
        resolve(value);
      })
      .catch(function (value) {
        //console.log("ERROR in GET RESTO ITEMS: " + value);
        reject(value);
        //deferred.reject(error);
      });
  });
}



// step 4
// /api/v1/users/faves/save
nomtxtApi.putUserFaves = async (payload) =>  {
  console.log("putUserFaves");
  return new Promise((resolve, reject) => {
    if (!payload) {
      reject(new Error("putUserFaves-payload is required"));
      return;
    };

    const currentUser = auth.currentUser;
    if (!currentUser) {
      reject(new Error("No current user"));

      return;
    }

    const token = currentUser
      .getIdTokenResult()
      .then((idTokenResult) => {
        return idTokenResult.token;
      })

    const nomtxtMethod = "/user/faves/save";
    const endpoint = nomtxtApiUrlRoot + nomtxtApiVersion + nomtxtMethod;
    // console.log("ENDPOINT: " + endpoint);
    const options = {
      headers: {
        "Content-Type": "application/json",
        "Authorization": token
      }
    };

    axios.post(endpoint, payload, options)
    .then(function (value) {
      //console.log("QAPLA' GET RESTO ITEMS: " + value);
      resolve(value);
    })
    .catch(function (value) {
      //console.log("ERROR in GET RESTO ITEMS: " + value);
      reject(value);
      //deferred.reject(error);
    });
  });
}

nomtxtApi.getUserRestoFaves = (userId, restoId) =>  {

}

nomtxtApi.getUserFaves = (userId) =>  {
  return new Promise((resolve, reject) => {
  console.log("putUserFaves");
    if (!userId) {
      return (new Error("getUserFaves::userId is required"));
      //return;
    };

    const currentUser = auth.currentUser;
    if (!currentUser) {
      reject(new Error("No current user"));

      return;
    }

    const token = currentUser
      .getIdTokenResult()
      .then((idTokenResult) => {
        return idTokenResult.token;
      })

    const nomtxtMethod = "/user/" + userId + "/faves/get";
    const endpoint = nomtxtApiUrlRoot + nomtxtApiVersion + nomtxtMethod;
    const options = {
      headers: {
        "Content-Type": "application/json",
        "Authorization": token
      }
    };

    axios.get(endpoint, {}, options)
    .then(function (value) {
      console.log("RETURN VAL " + JSON.stringify(value.data));
      //resolve(value);
      return(resolve(value.data));
    })
    .catch(function (error) {
      return(reject(error.message));
    });
  });
}


//setNewRestoPhone
nomtxtApi.setNewRestoMessagingPhone = (restoId, areaCode) => {
  return new Promise(async (resolve, reject) => {

    if(!restoId){
      reject("can't update, restoId missing");
    }
    if(!areaCode){
      reject("can't update, areaCode missing");
    }

    const currentUser = auth.currentUser;
    if (!currentUser) {
      reject(new Error("No current user"));

      return;
    }

    const token = currentUser
      .getIdTokenResult()
      .then((idTokenResult) => {
        return idTokenResult.token;
      })

    //alert(restoId, areaCode);
    //resto = {"restoName":"The Three Broomsticks","ccPhone":"12125551212","ccEmail":"a@aol.com","payments":"square"}

    const nomtxtMethod = "/resto/create/phone";
    const endpoint = nomtxtApiUrlRoot + nomtxtApiVersion + nomtxtMethod;
    const options = {
      headers: {
        "Content-Type": "application/json",
        "Authorization": token
      }
    };
    const payload = {
      restoId: restoId,
      areaCode: areaCode
    }

    axios.post(endpoint, payload, options)
    .then(function (value) {
      //console.log("RETURN VAL " + JSON.stringify(value.data) );
      //alert(value);
      return(resolve(value));
    })
    .catch(function (error) {
      return(reject(error.message));
    });

  });
}

nomtxtApi.getSquareAuthUrl = (restoId) => {
  return new Promise(async (resolve, reject) => {

    if(!restoId){
      reject("can't update, restoId missing");
    }
    const currentUser = auth.currentUser;
    if (!currentUser) {
      reject(new Error("No current user"));

      return;
    }

    let tiki = await authentication
    .getUserTokenId()
    .then((token)=>{
      return token;
    })
    .catch((e)=>{
      //alert(e);
    })

    const nomtxtMethod = nomtxtApi.getEnv() === 'production' ? "/delegate/request_token/" : "/delegate/sandbox/request_token/";
    const endpoint = nomtxtApi.getApiUrl() + nomtxtMethod + restoId ;

    /*
    let axpo = await axios.get(endpoint, {headers:
      { authorization: `Bearer ${tiki.token}` }})
      .then((value) => {
        return(resolve(value));
      })
      .catch(function (error) {
        return(reject(error.message));
      });
      */
  });
}



export default nomtxtApi;
