import React, { Component } from "react";

import PropTypes from "prop-types";
import SqPayForm from "../SqPayForm"
import validate from "validate.js";
import moment from "moment";
import UserPage from "../UserPage";
import UserCard from "../UserCard";

import {
  DialogContent,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  Hidden,
  Typography,
  TextField,
  Tooltip,
  IconButton,
  Button,
  Card,
  CardHeader,
  Paper,
  Grid,
  Link
} from "@material-ui/core";

import { Lock as LockIcon, Edit as EditIcon } from "@material-ui/icons";
import { firestore } from "../../firebase";
import constraints from "../../data/constraints";
import authentication from "../../services/authentication";
import { withRouter } from 'react-router-dom';


const initialState = {
  showingField: "",
  password: "",
  passwordConfirmation: "",
  performingAction: false,
  errors: null,
  restoName: ''
};

class SquareFormTab extends Component {
  constructor(props) {
    super(props);

    this.state = initialState;

  }

  showField = (fieldId) => {
    if (!fieldId) {
      return;
    }

    this.setState({
      showingField: fieldId,
    });
  };

  hideFields = (callback) => {
    this.setState(
      {
        showingField: "",
        password: "",
        passwordConfirmation: "",
        errors: null,
      },
      () => {
        if (callback && typeof callback === "function") {
          callback();
        }
      }
    );
  };


  getRestoName = (restoId) => {
    authentication
    .getRestoName(restoId)
    .then((n) => {
      this.setState({restoName: n});
    })
  }

  render() {
    // Properties
    const { userData, user } = this.props;
    console.log("userData on SQUAREFORMTAB: "+ userData);

    const {
      showingField,
      password,
      passwordConfirmation,
      performingAction,
      errors,
      restoName

    } = this.state;

    //console.log("userData on SquareFormTab: " + userData);
    const locationId = this.state.locationId;
    const restoId = this.state.restoId


    return (
      <Paper>

      <Grid container spacing={1} key={userData.phoneNumber}
        direction="column"
        alignItems="center"
        >
        <Grid item xs key="squarecardinfo">
          <Card>
          <Typography variant='h3'>Save Payment Details for {restoName} </Typography>
            <Typography variant='h6'>Save payment info </Typography>
            <Typography variant='body1'>Add a card to your account to make ordering a breeze. </Typography>
            <Typography variant='body1'>Your card will be stored encrypted safely and stored on Square's servers.
              <br/> Soon you will have the option to
              &nbsp; <Link href="#" disabled>
              add money to a gift card
              </Link>
              &nbsp; to fund to your account without storing your card.
            </Typography>


        <SqPayForm userdata={userData} user={user} restoId={restoId} locationId={locationId}
        />
      </Card>
      </Grid>
      </Grid>
      </Paper>
    );
  }

  componentDidMount() {
    let qsa = this.props.location.search.substr(1).split('&');
    let restoId = '';
    let locationId = '';
    if(qsa[0].indexOf("r") >= 0){
      restoId = qsa[0].split("=")[1];
      this.setState({restoId: restoId});
    }
    if(qsa[1] && qsa[1].indexOf("l") >= 0){
      locationId = qsa[1].split("=")[1];
      this.setState({locationId: locationId});
    }

    this.getRestoName(restoId);

  }

}



SquareFormTab.propTypes = {
  // Properties
  userData: PropTypes.object,

  // Functions
  //openSnackbar: PropTypes.func.isRequired,
};

export default withRouter(SquareFormTab);
